import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Drawer, Input, Popover, Select} from "antd";
import {useSelector} from "react-redux";
import {ClientsDtpSelector} from "../store/internal_api/clients/clientsDtpReducer";
import {Centered, MarginVertical} from "./styled/Layout";
import {ProjectsDtpSelector} from "../store/internal_api/Projects/ProjectsDtpReducer";
import {UsersSelector} from "../store/internal_api/users/usersReducer";
import _ from "lodash";
import {
  AccessiblePdfStatusses,
  AudiobookStatuses,
  BookDTPStatuses,
  CoverStatuses,
  EpubDTPStatuses,
  OtherDTPStates
} from "../assets/mockup/data";

const ProjectFilterMenu = ({onSelectedTypeChange, onSorterChange, onClientChange, resetOptions, projects, onProjectWatcherChange, onMockupChange, onTypePhaseChange}) => {

  const appUsers = useSelector(UsersSelector)
  let allTypesPhases = [...AudiobookStatuses, ...BookDTPStatuses, ...CoverStatuses, ...EpubDTPStatuses, ...OtherDTPStates, ...AccessiblePdfStatusses];

  allTypesPhases = _.uniqBy(allTypesPhases, "text")

  const [visible, setVisible] = useState(false);
  const [sorterValue, setSorterValue] = useState("ID_DESCENDING")
  const [typeFilterValue, setTypeFilterValue] = useState([])
  const [clientFilterValue, setClientFilterValue] = useState(0)

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const TypeOptions = [
    {label: `Okładka (${projects.coverTypes})`, value: "cover"},
    {label: `Książka (${projects.bookTypes})`, value: "book"},
    {label: `Audiobook (${projects.audiobookTypes})`, value: "audiobook"},
    {label: `Inne (${projects.otherTypes})`, value: "other"},
    {label: `Epub (${projects.epubTypes})`, value: "epub"},
    {label: `Accessible PDF(${projects.accessiblePDFType})`, value: "apdf"}
  ]

  const clients = useSelector(ClientsDtpSelector)

  const onSorterChangeChain = (value) =>{
    onSorterChange(value)
    setSorterValue(value)
  }

  const onTypeChangeChain = (value) =>{
    onSelectedTypeChange(value)
    setTypeFilterValue(value)
  }

  const onClientChangeChain = (value) =>{
    onClientChange(value)
    setClientFilterValue(value)
  }

  const reset = () =>{
    setSorterValue(()=>"ID_DESCENDING")
    setTypeFilterValue(()=>[])
    setClientFilterValue(()=>0)
    resetOptions()
  }
  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Opcje filtrowania
      </Button>
      <Drawer width={400} title="Opcje Filtrowania" placement="right" onClose={onClose} visible={visible}>
        <div style={{marginTop: 20}}>
          <Centered>
            <Button type={"secondary"} onClick={reset}>Reset</Button>
          </Centered>
          <div>
            <MarginVertical>
              <Centered>
                <Popover content={<p>System przeszuka pole makieta projektów w celu znalezienia tego wycinka tekstu</p>}>
                  <Input onChange={_.debounce(onMockupChange, 1000)} placeholder={"Czego szukasz w makiecie?"}/>
                </Popover>
              </Centered>
            </MarginVertical>
          </div>
          <h3>Sortowanie</h3>
          <Select onChange={onSorterChangeChain} value={sorterValue} defaultValue={"ID_DESCENDING"}>
            <Select.Option key={"INTERNALID_ASCENDING"}>ID projektu - Rosnąco</Select.Option>
            <Select.Option key={"INTERNALID_DESCENDING"}>ID projektu - Malejąco</Select.Option>
            <Select.Option key={"PROJECTNAME_ASCENDING"}>Nazwa projektu - A do Z</Select.Option>
            <Select.Option key={"PROJECTNAME_DESCENDING"}>Nazwa projektu - Z do A</Select.Option>
            <Select.Option key={"BEGINDATE_ASCENDING"}>Data rozpoczęcia - od najstarszych</Select.Option>
            <Select.Option key={"BEGINDATE_DESCENDING"}>Data rozpoczęcia - od najnowszych</Select.Option>
            <Select.Option key={"ID_ASCENDING"}>Kolejność dodania - od najstarszych</Select.Option>
            <Select.Option key={"ID_DESCENDING"}>Kolejnośc dodania - od najnowszych</Select.Option>
            <Select.Option key={"CLIENT_ASCENDING"}>Klient - A do Z</Select.Option>
            <Select.Option key={"CLIENT_DESCENDING"}>Klient - Z do A</Select.Option>
          </Select>
        </div>
        <div style={{marginTop: 20, width: '50%'}}>
          <h3>Typy</h3>
          <Checkbox.Group options={TypeOptions} value={typeFilterValue} onChange={onTypeChangeChain}/>
        </div>
        <div style={{marginTop: 20}}>
          <h3>Klienci</h3>
          <Select onChange={onClientChangeChain} value={clientFilterValue} defaultValue={0}>
            <Select.Option key={0} value={0}>Wszyscy</Select.Option>
            {clients.map(element => <Select.Option value={element.id}>{element.clientName}</Select.Option>)}
          </Select>
        </div>
        <div style={{marginTop: 20}}>
          <h3>Właściciel plików</h3>
          <Select onChange={onProjectWatcherChange} defaultValue={0}>
            <Select.Option key={0} value={0}>--Wybierz osobę--</Select.Option>
            {appUsers.map(element => <Select.Option value={element.id}>{element.name}</Select.Option>)}
          </Select>
        </div>
        <div style={{marginTop: 20}}>
          <h3>Etap typu</h3>
          <Select onChange={onTypePhaseChange} defaultValue={""}>
            <Select.Option key={""} value={""}>--Wybierz etap--</Select.Option>
            {allTypesPhases.map(element => <Select.Option value={element.text}>{element.text}</Select.Option>)}
          </Select>
        </div>
      </Drawer>
    </>
  );
};

ProjectFilterMenu.propTypes = {
  onSearchFilterChange: PropTypes.func.isRequired,
  onSelectedTypeChange: PropTypes.func.isRequired
};

export default ProjectFilterMenu;
