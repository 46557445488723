import React, {Fragment} from 'react';
import {Col, Divider, Row, Input, Form} from "antd";
import DtpProjectTypeCommonData from "./DtpProjectTypeCommonData";
import NewProjectArchiveSection from "./NewProjectArchiveSection";
import {OtherDTPStates} from "../../assets/mockup/data";
import ProjectTypesStatus from "./ProjectTypesStatus";

const {TextArea} = Input;

const OtherDtpProjectType = ({ defaultValues, form, archive}) => {


  let thisInitialValues = defaultValues !== undefined && defaultValues.length !== 0 ? defaultValues.filter((element)=>{return element.config.internalType === "other"}) : null;

  return (
    <Fragment>
      <Divider orientation={"left"}>Inny</Divider>
      <Form form={form} initialValues={thisInitialValues !== null && thisInitialValues.length !== 0 ? thisInitialValues[0].config : null}>
      <DtpProjectTypeCommonData archive={archive}/>
      <NewProjectArchiveSection/>
        <ProjectTypesStatus archive={archive} typesVariants={OtherDTPStates}/>
      <Divider>Dane</Divider>
    
        <Row gutter={[24, 24]}>
          <Col span={24}>
              <Form.Item name={"comment"}>
                  <TextArea disabled={archive} placeholder={"Wpisz komentarz do typu zadania"} rows={4}/>
              </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

OtherDtpProjectType.propTypes = {

};

export default OtherDtpProjectType;
