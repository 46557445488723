import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Table} from "antd";
import {useDispatch} from "react-redux";
import {GetAllUsersAction} from "../actions/internal_api/users/GetAllUsers";
import { EditUserActiveAction } from '../actions/internal_api/users/EditUserActive';

const UserManagementTable = ({data, onChangeGroupPermissionClick}) => {

  const dispatch = useDispatch();

  const onChange = (e, row) => {
    row.isActive = e.target.checked;
    let response = {
      isActive: e.target.checked
    }
    dispatch(EditUserActiveAction(row.id, response)).then(()=>{
      dispatch(GetAllUsersAction());
    })
  };

  const columns = [
    {
      title: "Imię i nazwisko",
      dataIndex:'name',
      key: "name",
      sorter: (a,b) => ('' + a.name).localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex:'email',
      key: "email",
    },
    {
      title: "Grupa",
      dataIndex:'group',
      key: "group",
      render: (text) => text.name
    },
    {
      title: "Uprawnienia",
      dataIndex:'permission',
      key: "permission",
      render: (text) => text.name
    },
    {
      title: "Konto aktywne",
      dataIndex:'active',
      key: "active",
      render: (value, row) => <Checkbox onChange={(e) =>onChange(e, row)} checked={value}/>
    },
    {
      title: "Akcje",
      key: "actions",
      render: (text, data) =>{ return(
        <Fragment>
          <a href="#none" onClick={()=>onChangeGroupPermissionClick(data)}>Zmień grupe/uprawnienia</a>&nbsp;
          <a href="#none">Przypomnij hasło</a>
        </Fragment>
      )
      }
    },
  ]

  return (
    <Table
      size="small"
      rowKey={"email"}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{
        //y: "57vh",
        x: "86vw"
      }}/>


  );
};

UserManagementTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default UserManagementTable;
