import React from 'react';
import {Checkbox, Col, Form, InputNumber, Row, Select} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Centered} from "./styled/Layout";
import { currencyList } from '../assets/data/appData';
import { useSelector } from 'react-redux';
import {PrintinghouseSelector} from "../store/internal_api/printingHouses/printingHouseReducer"
import { DeleteProjectValuationDrukAction } from '../actions/internal_api/Projects/DeleteProjectValuationDrukAction';
import {useDispatch} from "react-redux"

const {Option} = Select;

const PrintOfferForm = ({field, onDeleteClick, isArchive, valuations, onPrintingHouseChange}) => {

  const dispatch = useDispatch();

  const printingHouses = useSelector(PrintinghouseSelector);
  const printingHousesOptions = printingHouses.map(element =>{
    return <Option key={element.id} value={element.id}>{element.name}</Option>
  })
  const currencyOptions = currencyList.map(element =>{
    return <Option key={element} value={element}>{element}</Option>
  })

  return (
    <Row gutter={[24,24]}>
      <Form.Item  name={[field.name,"valuationType"]} fieldKey={[field.fieldKey, 'valuationType']} hidden={true}/>
      <Col span={2}>
          <Form.Item  name={[field.name, "circulation"]}>
        <InputNumber disabled={isArchive} />
          </Form.Item>
      </Col>
      <Col span={3}>
          <Form.Item  name={[field.name,"currency"]} fieldKey={[field.fieldKey, 'currency']}>
            <Select disabled={isArchive}>
              {currencyOptions}
            </Select>
          </Form.Item>
      </Col>
      <Col span={3}>
          <Form.Item noStyle name={[field.name,"pricePerItemPrinter"]} fieldKey={[field.fieldKey, 'pricePerItemPrinter']}>
        <InputNumber style={{width: "100%"}} disabled={isArchive}/>
          </Form.Item>
      </Col>
      <Col span={3}>
          <Form.Item noStyle name={[field.name,"pricePerItemClient"]} fieldKey={[field.fieldKey, 'pricePerItemClient']}>
        <InputNumber style={{width: "100%"}} disabled={isArchive}/>
          </Form.Item>
      </Col>
      <Col span={2}>
          <Form.Item  name={[field.name,"transportValue"]} fieldKey={[field.fieldKey, 'transportValue']}>
        <InputNumber disabled={isArchive}/>
          </Form.Item>
      </Col>
      <Col span={2}>
          <Form.Item name={[field.name,"otherCosts"]} fieldKey={[field.fieldKey, 'otherCosts']}>
        <InputNumber disabled={isArchive}/>
          </Form.Item>
      </Col>
      <Col span={2}>
          <Form.Item  name={[field.name,"isChoosed"]} fieldKey={[field.fieldKey, 'isChoosed']} valuePropName="checked">
        <Checkbox disabled={isArchive}/>
          </Form.Item>
      </Col>
      <Col span={5}>
          <Form.Item  name={[field.name,"printingHouse"]} rules={[{ required: true, message: 'Proszę wybrać drukarnię!' }]} fieldKey={[field.fieldKey, 'printingHouse']}>
        <Select disabled={isArchive} onChange={event => onPrintingHouseChange(event, field.key)}>
          {printingHousesOptions}
        </Select>
          </Form.Item>
      </Col>
      <Col span={2}>
        <Centered>
          <a href={"#none"} style={{color: "red"}} onClick={()=>{
            if(valuations[field.name] !== undefined){
            dispatch(DeleteProjectValuationDrukAction(valuations[field.name].id))
            }
            onDeleteClick(field.name)
            }}>
            <DeleteOutlined/>
          </a>
        </Centered>
      </Col>
    </Row>
  );
};

PrintOfferForm.propTypes = {

};

export default PrintOfferForm;
