import React, {Fragment} from 'react';
import {Centered, RightToLeft} from "./styled/Layout";
import {Col, Row} from "antd";

const ProjectViewOptions = ({leftSide, children , rightSide}) => {

  


  return (
    <Fragment>
      <Row>
          <Col span={8}>
            {leftSide}
          </Col>
        <Col span={8}>
          <Centered>
            {children}
          </Centered>
        </Col>
        <Col span={8}>
          <RightToLeft>
            {rightSide}
          </RightToLeft>
        </Col>
      </Row>
    </Fragment>
  );
};


export default ProjectViewOptions;
