/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, TimePicker} from "antd";
import {
    AccessiblePdfStatusses,
    AudiobookStatuses,
    BookDTPStatuses,
    CoverStatuses,
    EpubDTPStatuses, OtherDTPStates
} from "../assets/mockup/data";
import {ScrollableY} from "./styled/Layout";
import AddonBefore from "./Layout/AddonBefore";
import ProjectTypesStatus from "./ProjectTypes/ProjectTypesStatus";
import * as moment from "moment";
import {useDispatch} from "react-redux";
import {ModifyTaskDtpAction} from "../actions/internal_api/Projects/EditTaskDtpAction"
import {GetAllProjectsDtpAction} from "../actions/internal_api/Projects/GetAllProjectsDtpAction";
import {ModifyProjectWatcherAndPhaseAction} from "../actions/internal_api/Projects/ModifyProjectWatcherAndPhaseDtpAction"
import {TaskNames} from "../assets/data/taskNames";

const {TextArea} = Input;
const {Option} = Select;

const EditTaskModal = ({visibility, onCancel, fullData, typeIndex, taskId, typeId}) => {
    const [taskForm] = Form.useForm();
    const [projectStatusForm] = Form.useForm();

    const dispatch = useDispatch();
    const getDataFromForm = () =>{

        Promise.all([taskForm.validateFields(), projectStatusForm.validateFields()]).then((values)=>{

            let config = fullData.types[typeIndex].config

            config.projectTypePhase = values[1].projectTypePhase

            let phaseResponse = {
                projectTypePhase: values[1].projectTypePhase,
                config: config,
            }

            dispatch(ModifyTaskDtpAction(taskId, values[0])).then(()=>{
                dispatch(ModifyProjectWatcherAndPhaseAction(typeId, phaseResponse)).then(()=>{
                    dispatch(GetAllProjectsDtpAction());
                    onCancel();
                  })
            });

        
        })
    };

    const chooseProperTypeStatuses = () =>{
        switch(fullData.types[typeIndex].config.internalType){
            case "accessiblepdf" :
                return AccessiblePdfStatusses;
            case "audiobook":
                return AudiobookStatuses;
            case "book":
                return BookDTPStatuses;
            case "cover":
                return CoverStatuses;
            case "epub":
                return EpubDTPStatuses;
            case "other":
                return OtherDTPStates;
            default:
                return OtherDTPStates;
        }
    };


    const mapTaskNamesToOptions = (data) =>{
        return data.map((element)=>{
            return <Option key={element} value={element}>{element}</Option>
        })
    }

    let data = fullData.types[typeIndex].tasks.find((element) => element.id === taskId)

    if(data != undefined) {
        data.taskDate = moment(data.taskDate)
        data.hoursOfWork = moment(data.hoursOfWork)
    }

    return (
        <Modal
            title="Edytuj zadanie"
            visible={visibility}
            maskClosable={false}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Anuluj
                </Button>,
                <Button key="submit" type="primary"  onClick={getDataFromForm}>
                    Zapisz
                </Button>,
            ]}
            width={"90%"}
            style={{top: 10}}
        >
            <ScrollableY maxHeight={"80vh"}>
                <Form form={taskForm} initialValues={data}>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Nazwa zadania"}>
                                <Form.Item name={"taskName"}>
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Wybierz nazwę zadania"
                                    >
                                        {mapTaskNamesToOptions(TaskNames)}
                                    </Select>
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Data"}>
                                <Form.Item name={"taskDate"}>
                                    <DatePicker style={{width:"80%"}}/>
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Opis zadania"}>
                                <Form.Item name={"taskDescription"} noStyle>
                                    <TextArea rows={4}/>
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Suma godzin"}>
                                <Form.Item name={"hoursOfWork"}>
                                    <TimePicker format={'HH:mm'} minuteStep={15} />
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                </Form>
                <Form form={projectStatusForm} initialValues={fullData.types[typeIndex]}>
                    <ProjectTypesStatus typesVariants={chooseProperTypeStatuses()}/>
                </Form>
            </ScrollableY>
        </Modal>
    );
};

EditTaskModal.propTypes = {
    visibility: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EditTaskModal;
