/* eslint-disable eqeqeq */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Select} from "antd";
import BookDtpProjectType from "./ProjectTypes/BookDTPProjectType";
import CoverProjectType from "./ProjectTypes/CoverProjectType";
import EpubDtpProjectType from "./ProjectTypes/EpubDTPProjectType";
import OtherDtpProjectType from "./ProjectTypes/OtherDTPProjectType";
import AddonBefore from "./Layout/AddonBefore";
import {Centered, ColoredBackground, ScrollableY} from "./styled/Layout";
import AccessiblePdfType from "./ProjectTypes/AccessiblePDFType";
import AudiobookDtpProjectType from "./ProjectTypes/AudiobookDTPProjectType";
import { useSelector } from 'react-redux';
import { ClientsDtpSelector } from '../store/internal_api/clients/clientsDtpReducer';
import {GetMomentDateOrElseUndefined} from "../scripts/momentHelper"

const {Option} = Select;

const EditProjectModal = ({visibility, onOk, onCancel, setData, defaultValues, archive}) => {

  const [mainForm] = Form.useForm();
  const [accessiblePDFForm] = Form.useForm();
  const [audiobookForm] = Form.useForm();
  const [bookForm] = Form.useForm();
  const [coverForm] = Form.useForm();
  const [epubForm] = Form.useForm();
  const [otherForm] = Form.useForm();

  React.useEffect(()=>{
    mainForm.validateFields();
  });

  let [coverCheckboxState, setCoverCheckboxState] = useState(defaultValues.isCoverType);
  let [bookCheckboxState, setBookCheckboxState] = useState(defaultValues.isBookType);
  let [audiobookCheckboxState, setAudiobookCheckboxState] = useState(defaultValues.isAudiobookType);
  let [otherCheckboxState, setOtherCheckboxState] = useState(defaultValues.isOtherType);
  let [epubCheckboxState, setEpubCheckboxState] = useState(defaultValues.isEpubType);
  let [accessibleCheckboxState, setAccessibleCheckboxState] = useState(defaultValues.isAccessiblePdfType);
  let [selectedClient] = useState("null");
  let [selectedClientLastId] = useState("");

  const isOneOfTypesTrue = () =>{
    return coverCheckboxState || bookCheckboxState || audiobookCheckboxState || otherCheckboxState || epubCheckboxState || accessibleCheckboxState;
  }


  const clientsList = useSelector(ClientsDtpSelector).map(element =>{
    return <Option key={element.id} value={element.id}>{element.clientName}</Option>
  })


  const contactPerson = selectedClient !== "null" 
    ?
      clientsList.find(element => element.id === selectedClient).contactPersons.map(contact =>{
      return <Option key={contact.id} value={contact.id}>{contact.nameSurname}</Option>
    })
    :
    defaultValues.client.contactPersons.map(element => {
      return <Option key={element.id} value={element.id}>{element.nameSurname}</Option>
    })

  const clientsID = {
    Kolofon: "KFN",
    HaveABook: "HAB",
    Microsoft: "MS",
    Gyldendal: "GL",
  };


  const getSelectedClientId = () =>{
    return clientsID[selectedClient];
  };




  const tempOnOk = () =>{
    let newProjectData = {
      types: [],
      dzial: "dtp/it",
      status:"W trakcie",
        
    };

    Promise.all([
      mainForm.validateFields(),
      accessiblePDFForm.validateFields(),
      audiobookForm.validateFields(),
      bookForm.validateFields(),
      coverForm.validateFields(),
      epubForm.validateFields(),
      otherForm.validateFields()
    ]).then(values =>{

      newProjectData = {
        ...newProjectData,
        ...values[0],
        id: defaultValues.id
      };


      if (values[0].isAccessiblePdfType) {
        if(defaultValues.types.filter((element)=> element.config.internalType === "accessiblepdf")[0] !== undefined){
          values[1].id = defaultValues.types.filter((element)=> element.config.internalType === "accessiblepdf")[0].id;
        }

        values[1].internalType = "accessiblepdf";
        newProjectData.types.push(values[1]);
      }
      if (values[0].isAudiobookType) {
        if(defaultValues.types.filter((element)=> element.config.internalType === "audiobook")[0] !== undefined){
          values[2].id = defaultValues.types.filter((element)=> element.config.internalType === "audiobook")[0].id;
        }
        

        values[2].internalType = "audiobook";
        newProjectData.types.push(values[2]);
      }

      if (values[0].isBookType) {
        if(defaultValues.types.filter((element)=> element.config.internalType === "book")[0] !== undefined){
          values[3].id = defaultValues.types.filter((element)=> element.config.internalType === "book")[0].id;
        }
        

        values[3].internalType = "book";
        newProjectData.types.push(values[3]);
      }

      if (values[0].isCoverType) {
        if(defaultValues.types.filter((element)=> element.config.internalType === "cover")[0] !== undefined){
          values[4].id = defaultValues.types.filter((element)=> element.config.internalType === "cover")[0].id;
        }
        

        values[4].internalType = "cover";
        newProjectData.types.push(values[4]);
      }

      if (values[0].isEpubType) {
        if(defaultValues.types.filter((element)=> element.config.internalType === "epub")[0] !== undefined){
          values[5].id = defaultValues.types.filter((element)=> element.config.internalType === "epub")[0].id;
        }
        

        values[5].internalType = "epub";
        newProjectData.types.push(values[5]);
      }

      if (values[0].isOtherType) {
        if(defaultValues.types.filter((element)=> element.config.internalType === "other")[0] !== undefined){
          values[6].id = defaultValues.types.filter((element)=> element.config.internalType === "other")[0].id;
        }
        

        values[6].internalType = "other";
        newProjectData.types.push(values[6]);
      }
    }).then(()=>{
      setData(newProjectData)
    }).catch((error)=>{
    })
  };


  return (
    <Modal
      title="Nowy projekt"
      visible={visibility}
      maskClosable={false}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={tempOnOk}>
          Zapisz
        </Button>,
      ]}
      width={"90%"}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={mainForm}  /*initialValues={datesToMoment(defaultValues)}*/>
          <ColoredBackground>
            <Row gutter={[24,24]}>
              <Col span={24}>
                <Form.Item name={"projectName"} initialValue={defaultValues.projectName} rules={[{ required: true, message: 'Proszę wpisać nazwę projektu!' }]}>
                  <Input disabled={archive} addonBefore={"Nazwa projektu:"}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={2}>
                <Form.Item name={"isCoverType"} initialValue={defaultValues.isCoverType} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox  onChange={(e)=> setCoverCheckboxState(e.target.checked)} disabled={epubCheckboxState || accessibleCheckboxState || archive} >Okładka</Checkbox>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={"isBookType"} initialValue={defaultValues.isBookType} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setBookCheckboxState(e.target.checked)} disabled={epubCheckboxState || accessibleCheckboxState || archive}>Książka</Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={"isAudiobookType"} initialValue={defaultValues.isAudiobookType} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setAudiobookCheckboxState(e.target.checked)} disabled={epubCheckboxState || accessibleCheckboxState || archive}>Audiobook</Checkbox>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={"isOtherType"} initialValue={defaultValues.isOtherType} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox disabled={archive} onChange={(e)=> setOtherCheckboxState(e.target.checked)}>Inne</Checkbox>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={"isEpubType"} initialValue={defaultValues.isEpubType} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setEpubCheckboxState(e.target.checked)} disabled={coverCheckboxState || bookCheckboxState || audiobookCheckboxState || archive}>Epub</Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={"isAccessiblePdfType"} initialValue={defaultValues.isAccessiblePdfType} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setAccessibleCheckboxState(e.target.checked)} disabled={coverCheckboxState || bookCheckboxState || audiobookCheckboxState || archive}>Accessible pdf</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </ColoredBackground>
          <Divider>Klient</Divider>
          <ColoredBackground>
            <Row gutter={24}>
              <Col span={12}>
                <AddonBefore additionText={"Wybierz klienta:"}>
                  <Form.Item name={"client"} initialValue={defaultValues.client.id}>
                    <Select
                        disabled={archive}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Wybierz klienta"
                      optionFilterProp="children"

                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {clientsList}
                    </Select>
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={12}>
                <AddonBefore additionText={"Wybierz osobę kontaktową:"}>
                  <Form.Item name={"contactPerson"} initialValue={defaultValues.contactPerson.id}>
                    <Select
                      disabled={archive}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Wybierz osobę kontaktową"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {contactPerson}
                    </Select>
                  </Form.Item>
                </AddonBefore>
              </Col>
            </Row>
          </ColoredBackground>
          <Divider>Ogólne dane</Divider>
          <ColoredBackground color={"#a3f7bf"}>
            <Row gutter={24}>
              <Col span={8}>
                <AddonBefore additionText={"ID projektu:"}>
                  <Form.Item name={"internalId"} initialValue={defaultValues.internalId}>
                    <Input disabled={archive} addonBefore={getSelectedClientId() !== undefined ? `${getSelectedClientId()}/` : undefined} defaultValue={selectedClientLastId}/>
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={8}>
                <Form.Item name={"orderNumber"} initialValue={defaultValues.orderNumber}>
                  <Input disabled={archive} addonBefore={"Nr zamówienia:"}/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={"isbn"} initialValue={defaultValues.isbn}>
                  <Input disabled={archive} addonBefore={"ISBN:"}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <AddonBefore additionText={"Data rozpoczęcia:"}>
                  <Form.Item nostyle name={"beginDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.beginDate)}>
                    <DatePicker disabled={archive} style={{width:"100%"}}  />
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={8}>
                <AddonBefore additionText={"Data zakończenia:"}>
                  <Form.Item nostyle name={"endDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.endDate)}>
                    <DatePicker disabled={archive}  style={{width:"100%"}} />
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={8}>
                <Form.Item name={"author"} initialValue={defaultValues.author}>
                  <Input disabled={archive} addonBefore={"Autor:"}/>
                </Form.Item>
              </Col>
            </Row>
          </ColoredBackground>
        </Form>
          <Divider>Typy</Divider>
          { bookCheckboxState
            ?
            <ColoredBackground color={"#e0fffb"}>
              <BookDtpProjectType archive={archive} form={bookForm} defaultValues={defaultValues.types}/>
            </ColoredBackground>
            :
            null
          }
          { coverCheckboxState
            ?
            <ColoredBackground color={"#e4e5db"}>
              <CoverProjectType archive={archive} form={coverForm} defaultValues={defaultValues.types}/>
            </ColoredBackground>
            :
            null
          }
          {audiobookCheckboxState
          ?
            <ColoredBackground color={"#e0cffb"}>
              <AudiobookDtpProjectType archive={archive} form={audiobookForm} defaultValues={defaultValues.types}/>
            </ColoredBackground>
          :
          null}
          { epubCheckboxState
            ?
            <ColoredBackground color={"#fff6f6"}>
              <EpubDtpProjectType archive={archive} form={epubForm} defaultValues={defaultValues.types}/>
            </ColoredBackground>
            :
            null
          }
          { otherCheckboxState
            ?
            <ColoredBackground color={"#c3f584"}>
              <OtherDtpProjectType archive={archive} form={otherForm} defaultValues={defaultValues.types}/>
            </ColoredBackground>
            :
            null
          }
          { accessibleCheckboxState
            ?
            <ColoredBackground color={"#f3d1f4"}>
              <AccessiblePdfType archive={archive} form={accessiblePDFForm} defaultValues={defaultValues.types}/>
            </ColoredBackground>
            :
            null
          }
          {!accessibleCheckboxState && !otherCheckboxState && !audiobookCheckboxState && !epubCheckboxState && !coverCheckboxState && !bookCheckboxState
            ?
            <Row gutter={[24,24]}>
              <Col span={24}>
                <Centered>
                  Nie wybrano żadnego typu
                </Centered>
              </Col>
            </Row>
            :
            null
          }

      </ScrollableY>
    </Modal>
  );
};

EditProjectModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditProjectModal;
