import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal} from "antd";
import PrintProjectOffersView from "./PrintProjectOffersView";
import {useDispatch, useSelector} from "react-redux";
import {ModifyProjectDrukAction} from "../actions/internal_api/Projects/ModifyProjectDrukAction";
import {GetAllProjectsDrukAction} from "../actions/internal_api/Projects/GetAllProjectDrukAction";
import {PrintinghouseSelector} from "../store/internal_api/printingHouses/printingHouseReducer";

const AddAdditionalValuationModal = ({visibility, onCancel, defaultValues, record}) => {

  const printingHouses = useSelector(PrintinghouseSelector)

  let [valuationForm] = Form.useForm();
  const dispatch = useDispatch();

  const OnOk = () =>{
  valuationForm.validateFields().then((values)=>{
    let newRecordData = {
      ...record
    }
    newRecordData.valuations = values.valuations;
    newRecordData.client = newRecordData.client.id;


    dispatch(ModifyProjectDrukAction(newRecordData.id, newRecordData)).then(()=>{
      dispatch(GetAllProjectsDrukAction());
    });

    onCancel()
  })
  };

  const onPrintingHouseChange = (value, key) =>{
    const fields = valuationForm.getFieldsValue();
    const {valuations} = fields;

    Object.assign(valuations[key], {valuationType: printingHouses.find(element => element.id === value).type})
    valuationForm.setFieldsValue({valuations})
  }


  return (
      <Modal
          title={"Dodaj dodatkowe wyceny"}
          visible={visibility}
          maskClosable={false}
          onCancel={onCancel}
          footer={[
            <Button key="back" onClick={onCancel}>
              Anuluj
            </Button>,
            <Button key="submit" type="primary"  onClick={OnOk}>
              Aktualizuj
            </Button>,
          ]}
          width={"90%"}
          style={{top: 10}}
      >
        <Form form={ valuationForm } /*initialValues={ {valuations: defaultValues} }*/>
          <PrintProjectOffersView valuations={defaultValues} onPrintingHouseChange={onPrintingHouseChange}/>
        </Form>

      </Modal>
  );
};

AddAdditionalValuationModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddAdditionalValuationModal;