import {GET_PERMISSIONS_SUCCESS} from "../../../consts/actions";

const initialState = {
    permissions: []
};

export const PermissionsReducer = (state = initialState, action) =>{
    switch(action.type){
        case GET_PERMISSIONS_SUCCESS:
            state = {
                ...state,
                permissions: action.data
            };
            return state;
        default:
            return state;
    }
};

export const PermissionsSelector = (state) => state.PermissionsReducer.permissions;
