import styled from "styled-components"

export const Block = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100vw;
height: 100vh;
`;

export const RightToLeft = styled.div`
direction: rtl;
`;

export const Centered = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

export const MarginVertical = styled.div`
margin: 10px 0 10px 0`

export const ScrollableY = styled.div`
overflow-y: auto;
overflow-x: hidden;
max-height: ${(props) =>props.maxHeight ? props.maxHeight : "100%"};
`;

export const MaxHeight = styled.div`
max-height: ${(props) =>props.maxHeight ? props.maxHeight : "100%"};
`;

export const ColoredBackground = styled.div`
background-color: ${(props) => props.color ? props.color: "#fafb97"};
padding: 5px;
`;
