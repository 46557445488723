import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  EDIT_CLIENT_DRUK,
  EDIT_CLIENT_DRUK_SUCCESS,
  EDIT_CLIENT_DRUK_FAIL,
} from "../../../consts/actions"

export function EditClientDrukAction(clientId, clientData){
  return (dispatch) =>{
    dispatch({
      type: EDIT_CLIENT_DRUK
    });
    return axios({
      method: "POST",
      data: clientData,
      url: `${mockupBackend}clientdruk/modify/${clientId}`
    }).then(response =>{
      dispatch({
        type: EDIT_CLIENT_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: EDIT_CLIENT_DRUK_FAIL,
        error
      })
    })
  }
}