import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "antd";

const ContactPersonTable = ({data}) => {

  const columns = [
    {
      title:'Imię i nazwisko',
      dataIndex:'nameSurname',
      key:'nameSurname',
    },
    {
      title:'Stanowisko',
      dataIndex:'position',
      key:'position',
    },
    {
      title:'Tel kom',
      dataIndex:'cellPhoneNumber',
      key:'cellPhoneNumber',
    },
    {
      title:'Tel stacjonarny',
      dataIndex:'phoneNumber',
      key:'phoneNumber',
    },
    {
      title:'Notatki',
      dataIndex:'notes',
      key:'notes',
    },
    {
      title:'e-mail',
      dataIndex:'email',
      key:'email',
    },
  ];
  return (
    <Table size="small" columns={columns} dataSource={data} pagination={false}/>
  );
};

ContactPersonTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default ContactPersonTable;
