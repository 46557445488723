import {
  GET_PAGED_ARCHIVE_PROJECTS_DTP,
  GET_PAGED_ARCHIVE_PROJECTS_DTP_FAIL,
  GET_PAGED_ARCHIVE_PROJECTS_DTP_SUCCESS
} from "../../../consts/actions"
import {toast} from "react-toastify";

const initialState = {
  projects: {
    projects: [],
    loading: true
  }
};

export const ArchiveProjectsDtpReducer = (state = initialState, action) =>{
  switch(action.type){

    case GET_PAGED_ARCHIVE_PROJECTS_DTP:
      state = {
        ...state,
        projects: {
          ...state.projects,
          loading: true
        }
      }
      return state;
    case GET_PAGED_ARCHIVE_PROJECTS_DTP_SUCCESS:
      state = {
        ...state,
        projects: {
          ...action.data,
          loading: false
        }
      }
      return state;
    case GET_PAGED_ARCHIVE_PROJECTS_DTP_FAIL:
      state = {
        ...state,
        projects: {
          ...state.projects,
          loading: false
        }
      }
      return state;
    case "PROJECT_DTP_WAS_EDITED":
      return onProjectDtpWasEdited(state, action)
    default:
      return state;
  }
};


export const ArchiveProjectsDtpSelector = (state) => state.ArchiveProjectsDtpReducer.projects;

//if project exist in store, update it
function onProjectDtpWasEdited(state, action){
  //check if project exist in current project store
  let projectIndexInStore = state.projects.projects.findIndex((project) => project.id === action.data.id)
  if (projectIndexInStore !== -1){
    //if exist get tasks from it and ascribe to new data
    let newProjectData = action.data
    for (let typeIndex = 0; typeIndex < newProjectData.types.length; typeIndex++){
      newProjectData.types[typeIndex].tasks = state.projects.projects[projectIndexInStore].types[typeIndex].tasks
    }
    //set value of project to new project
    let newProjects = {...state.projects}
    newProjects.projects[projectIndexInStore] = newProjectData
    state = {
      ...state,
      projects: newProjects
    }
    //show notification
    toast(`Projekt "${newProjectData.projectName}" został zaktualizowany`, {

    })
  }
  // if not do nothing
  return state;
}
