import React, {Fragment} from 'react';
import {Col, Divider, Form, InputNumber, Row} from "antd";
import DtpProjectTypeCommonData from "./DtpProjectTypeCommonData";
import NewProjectArchiveSection from "./NewProjectArchiveSection";
import ProjectTypesStatus from "./ProjectTypesStatus";
import AddonBefore from "../Layout/AddonBefore";
import {AccessiblePdfStatusses} from "../../assets/mockup/data";

const AccessiblePdfType = ({defaultValues, form, archive}) => {


    let thisInitialValues = defaultValues !== undefined && defaultValues.length !== 0 ? defaultValues.filter((element)=>{return element.config.internalType === "accessiblepdf"}) : null;

  return (
    <Fragment>
      <Divider orientation={"left"}>Accessible PDF</Divider>
      <Form form={form} initialValues={ thisInitialValues !== null && thisInitialValues.length !== 0 ? thisInitialValues[0].config : null}>
      <DtpProjectTypeCommonData archive={archive}/>
      <NewProjectArchiveSection/>
      <ProjectTypesStatus archive={archive} typesVariants={AccessiblePdfStatusses}/>
      <Divider>Dane</Divider>
        <Row gutter={[24,24]}>
          <Col>
          <AddonBefore additionText="Liczba stron:">
            <Form.Item name={"numberOfPages"}>
                <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

AccessiblePdfType.propTypes = {

};

export default AccessiblePdfType;
