import React, {Component} from 'react';
import {Form, Input, Button, Space} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {Block} from "../../components/styled/Layout";
import {LoggedInUserSelector} from "../../store/internal_api/LoginUser";
import {LoginAction} from "../../actions/internal_api/users/LoginAction";
import {connect} from "react-redux";
import {GetAllUsersAction} from "../../actions/internal_api/users/GetAllUsers";

function mapStateToProps(state){
  return {
    user: LoggedInUserSelector(state)
  }
}

function mapDispatchToProps(dispatch){
  return{
    loginUser: (login, password) => dispatch(LoginAction(login, password)),
    getAllUsers: () => dispatch(GetAllUsersAction())
  }
}

class LoginPage extends Component {

  constructor() {
    super();
    this.state = {
      error: -1
    }
  }

  onFinish = (values) => {
    this.props.loginUser(values.login, values.password).then(()=>{
      if(this.props.user.error === 0){
        if(this.props.user.user.active) {
          this.setState({error: -1});
          if(this.props.user.user.group.showDtpResources) {
            this.props.history.push("/app/projects");
          }
          else{
            this.props.history.push("/app/projectsprint");
          }
          this.props.getAllUsers();
        }
        else{
          this.setState({error: 2});
        }
      }
      else{
        this.setState({error: this.props.user.error})
      }
    })
  };


  errorMessage = () =>{
    switch(this.state.error){
      case 1:
        return (
            <p style={{color: "red"}}>Błedny email lub hasło</p>
        )
      case 2:
        return(
            <p style = {{color: "red"}}>Użytkownik jest nieaktywny</p>
        )
      default:
        return null
    }
  }

  render() {
    return (
      <Block>
        <Space align={"center"}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
          >
            <div>
              {this.errorMessage()}
            </div>
            <Form.Item
              name="login"
              rules={[
                {
                  required: true,
                  message: 'Wpisz swój email!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email użytkownika" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Wpisz swoje hasło!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Hasło"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Zaloguj
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Block>
    );
  }
}

LoginPage.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (LoginPage);
