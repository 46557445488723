import axios from "axios";
import {GET_USERS, GET_USERS_FAIL, GET_USERS_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";



export function GetAllUsersAction(){
    return (dispatch) =>{
        dispatch({
            type: GET_USERS
        });
        return axios({
            method: 'get',
            url: `${mockupBackend}user/all`,
        }).then(response =>{
            dispatch({
                type: GET_USERS_SUCCESS,
                data: response.data
            })
        }).catch(error =>{
            dispatch({
                type: GET_USERS_FAIL,
                error
            })
        })
    }
}