import {SET_EXPANDED_PROJECTS_IDS} from "../../consts/actions";


export function SetProjectIdsToExpandAction(data){
  return (dispatch) => {
    dispatch({
      type: SET_EXPANDED_PROJECTS_IDS,
      data: data
    });
  }
}
