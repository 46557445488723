import {GET_ALL_PROJECTS_DTP_SUCCESS} from "../../../consts/actions"

const initialState = {
  projects: []
};

export const AllProjectsDtpReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_ALL_PROJECTS_DTP_SUCCESS:
      state = {
        ...state,
        projects: action.data
      }
      return state;
    default:
      return state;
  }
};


export const AllProjectsDtpSelector = (state) => state.AllProjectsDtpReducer.projects;
