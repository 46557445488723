import React, {Fragment} from 'react';
import ContactPersonTable from "./ContactPersonTable";

const ClientExpandableTableElement = ({recordData}) => {
  return (
    <Fragment>
      <ContactPersonTable data={recordData}/>
    </Fragment>
  );
};

ClientExpandableTableElement.propTypes = {

};

export default ClientExpandableTableElement;