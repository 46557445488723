import {GET_CLIENTS_DRUK_SUCCESS} from "../../../consts/actions"

const initialState = {
  clients: []
};

export const ClientsDrukReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_CLIENTS_DRUK_SUCCESS:
      state = {
        ...state,
        clients: action.data
      };
      return state;
    default:
      return state;
  }
};


export const ClientsDrukSelector = (state) => state.ClientsDrukReducer.clients;