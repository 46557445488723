import React from 'react';
import PropTypes from 'prop-types';
import {Table, Tag} from "antd";
import ProjectTypeTable from "./styled/ProjectTypeTable";
import '../styles/tableStyles.css'
import * as moment from "moment";
import ModalWithInputVerification from "./ModalWithInputVerification";

const ProjectsTable = ({data, editActions, isLoading, expandRows, currentItems}) => {


  const getMockupOfProject = (record) =>{
    let bookTypeIndex = record.types.findIndex((element) => element.config.internalType === "book" );
    if(bookTypeIndex !== -1){
      return record.types[bookTypeIndex].config.mockup
    }
    else{
      return "-"
    }

  };


  let columns = [
    {
      title: 'Akcje',
      key: 'actions',
      render: (_,row,index) => <div>
        <a href="#none" onClick={()=>editActions.onClick(row.id)}>Edytuj</a>
        <ModalWithInputVerification blackList={["pracownik"]} onAccept={()=>editActions.deleteProject(row.id)} onCancel={()=> {}} trueValue={row.projectName}>
          <a href="#none">Usuń</a>
        </ModalWithInputVerification>
      </div>,
      width: "100px"
    },
    {
      title: "ID Projektu",
      dataIndex: "internalId",
      key: "internalId",
      //filters: getTableFilterData(data, "internalId"),
      //onFilter: (value, record) => record.internalId.indexOf(value) === 0,
      //sorter: (a,b) => ('' + a.internalId).localeCompare(b.internalId),
      width: "100px"
    },
    {
      title: `Nazwa projektu (${currentItems})`,
      dataIndex: "projectName",
      key: "projectName",
      //filters: getTableFilterData(data, "projectName"),
      //onFilter: (value, record) => record.projectName.indexOf(value) === 0,
      //sorter: (a,b) => ('' + a.projectName).localeCompare(b.projectName),
      width: "250px"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: () => <Tag color={"orange"}>W trakcie</Tag>
    },
    {
      title: "Klient",
      dataIndex: "client",
      key: "client",
      //filters: getTableFilterDataClient(data, "client"),
      //onFilter: (value, record) => record.client.clientName === value,
      //sorter: (a,b) => ('' + a.client.clientName).localeCompare(b.client.clientName),
      render: (element) => element.clientName
    },
    {
      title: "ISBN",
      dataIndex:'isbn',
      key: "isbn",
      //sorter: (a,b) => ('' + a.isbn).localeCompare(b.isbn),
    },
    {
      title: "Data rozpoczęcia",
      dataIndex: "beginDate",
      key: "beginDate",
      render: (text)=>  text !== undefined || text !== null ? moment(text).format("DD-MM-YYYY"): undefined,
     // sorter: (a,b) => ('' + a.beginDate).localeCompare(b.beginDate),
    },

    {
      title: "Makieta",
      dataIndex: "mockup",
      key: "mockup",
      //filters: getTableFilterData(data, "mockup"),
      //onFilter: (value, record) => record.mockup.indexOf(value) === 0,
      render: (text, record, index) => getMockupOfProject(record)
    },

  ];


  return (
    <div>
      <Table
          //Force rerender to switch expand all work
          key={String(expandRows)}
        //rowClassName={paintRow}

        expandable={{
          expandedRowRender: (record, index, indent, expanded) => <ProjectTypeTable
              deleteTask={editActions.deleteTask}
              deleteType={editActions.deleteType}
              refreshProjects={editActions.refreshProjects}
              fullData={record}
              contactPerson={record.contactPerson}
              projectId={record.id}
              projectIndex={index}
              onAddTaskClick={editActions.addTaskClick}
              recordData={record.types}/>,
          rowExpandable: record => true,
          defaultExpandedRowKeys: expandRows
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        scroll={{
          x: "86vw"
        }}
        rowKey={(record) => record.id}
        loading={isLoading}
      />
    </div>
  );
};

ProjectsTable.propTypes = {
  data: PropTypes.array.isRequired,
  editActions: PropTypes.object.isRequired
};

export default ProjectsTable;
