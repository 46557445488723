import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import TaskTable from "./TaskTable";
import {Button, Divider} from "antd";
import {Centered} from "./styled/Layout";

const ProjectExpandableTableElement = ({deleteTask, typeId, recordData, onAddTaskClick, projectIndex, projectTaskType, projectId, typeIndex, fullData, refreshProjects}) => {


  let dataForTask = {
    projectIndex: projectIndex,
    projectType: projectTaskType,
    projectId: projectId,
    typeId: typeId
  };

  return (
    <Fragment>
      <Centered>
        {onAddTaskClick !== undefined || onAddTaskClick !== null
        ?
          <Button type={"primary"} onClick={()=>onAddTaskClick(dataForTask)}>Nowe zadanie</Button>
        :
        null
        }
      </Centered>
      <Divider/>
      <div style={{padding: "10px"}}>
        <TaskTable deleteTask={deleteTask} data={recordData} typeId={typeId} typeIndex={typeIndex} fullData={fullData} refreshProjects={refreshProjects} />
      </div>
    </Fragment>
  );
};

ProjectExpandableTableElement.propTypes = {
  recordData: PropTypes.object.isRequired
};

export default ProjectExpandableTableElement;
