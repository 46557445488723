import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  GET_DAYS_OFF_PLAN,
  GET_DAYS_OFF_PLAN_SUCCESS,
  GET_DAYS_OFF_PLAN_FAIL,
} from "../../../consts/actions"

export function GetAllDaysOffPlanAction(){
  return (dispatch) =>{
    dispatch({
      type: GET_DAYS_OFF_PLAN
    });
    return axios({
      method: "GET",
      url: `${mockupBackend}dayoffplan/all`
    }).then(response =>{
      dispatch({
        type: GET_DAYS_OFF_PLAN_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: GET_DAYS_OFF_PLAN_FAIL,
        error
      })
    })
  }
}