import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  NEW_PRINTINGHOUSE,
  NEW_PRINTINGHOUSE_SUCCESS,
  NEW_PRINTINGHOUSE_FAIL,
} from "../../../consts/actions"

export function AddNewPrintingHouseAction(printingHouseData){
  return (dispatch) =>{
    dispatch({
      type: NEW_PRINTINGHOUSE
    });
    return axios({
      method: "PUT",
      data: printingHouseData,
      url: `${mockupBackend}printinghouse/new`
    }).then(response =>{
      dispatch({
        type: NEW_PRINTINGHOUSE_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: NEW_PRINTINGHOUSE_FAIL,
        error
      })
    })
  }
}