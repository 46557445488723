import React from 'react';
import {Err404Block} from "./styled/Errors";

const Goodnight = () => {
  return (
    <Err404Block>
      <iframe title="GIF" src="https://giphy.com/embed/3ofQi9Rk9cnrAzpHsB" width="480" height="480" frameBorder="0"
              className="giphy-embed" allowFullScreen></iframe>
      <h1>Hmm, trafiłeś do miejsca gdzie diabeł mówi dobranoc ;)</h1>
      <p>404: Not found</p>
    </Err404Block>
  );
};

Goodnight.propTypes = {
  
};

export default Goodnight;