import {PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_SUCCESS, PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_RESET} from "../../../consts/actions";

const initialState = {
  isExists: false
};

export const ProjectInternalIdIndividualityCheckReducer = (state = initialState, action) =>{
  switch(action.type){
    case PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_SUCCESS:
      state = {
        ...state,
        isExists: action.data
      };
      return state;
    case PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_RESET:
      state = {
        ...state,
        isExists: false
      };
      return state;
    default:
      return state;
  }
};


export const ProjectInternalIdIndividualityCheckSelector = (state) => state.ProjectInternalIdIndividualityCheckReducer.isExists;
