import React, {Component} from 'react';
import UserInfo from "../../../components/UserInfo";
import {LoggedInUserSelector} from "../../../store/internal_api/LoginUser";
import {connect} from "react-redux";
import { GetAllUsersAction } from '../../../actions/internal_api/users/GetAllUsers';
import { UsersSelector } from '../../../store/internal_api/users/usersReducer';
import UserTasks from "../../../components/UserTasks";
import {GetTasksOfUserAction} from "../../../actions/internal_api/tasks/GetTasksOfUserAction";
import {ProjectsDtpSelector} from "../../../store/internal_api/Tasks/UserProjectTasksReducer";

function mapStateToProps(state){
  return{
    loggedInUser: LoggedInUserSelector(state),
    users: UsersSelector(state),
    userTasks: ProjectsDtpSelector(state)
  };
}

function mapDispatchToProps(dispatch){
  return{
      getUsers: () => dispatch(GetAllUsersAction()),
      getUserTasks: (userId) => dispatch(GetTasksOfUserAction(userId))
  }
}


class YourDataView extends Component {
  constructor() {
    super();
    this.state = {
    }
  }

  componentDidMount(){
    this.props.getUsers();
    this.props.getUserTasks(this.props.loggedInUser.user.id)
  }

  getDataOfLoggedInUser(loggedInUser){
    const currentUser = this.props.users.find(element => {return element.id === loggedInUser.id})
    return currentUser;
  }
  

  render() {
    return (
      <div>
        <UserInfo userData={this.getDataOfLoggedInUser(this.props.loggedInUser.user)}/>
        <UserTasks taskData={this.props.userTasks}/>

      </div>
    );
  }
}

YourDataView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (YourDataView);
