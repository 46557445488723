import axios from "axios"
import {MODIFY_PROJECT_DRUK, MODIFY_PROJECT_DRUK_SUCCESS, MODIFY_PROJECT_DRUK_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function ModifyProjectDrukAction(internalId, data){
  return (dispatch) =>{
    dispatch({
      type: MODIFY_PROJECT_DRUK
    });
    return axios({
      method: "POST",
      data: data,
      url: `${mockupBackend}projectdruk/modify/${internalId}`
    }).then(response =>{
      dispatch({
        type: MODIFY_PROJECT_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: MODIFY_PROJECT_DRUK_FAIL,
        error
      })
    })
  }
}