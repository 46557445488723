import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
    EDIT_DAYS_OFF,
    EDIT_DAYS_OFF_SUCCESS,
    EDIT_DAYS_OFF_FAIL,
} from "../../../consts/actions"

export function EditDaysOffAction(daysOffId, daysOffData){
    return (dispatch) =>{
        dispatch({
            type: EDIT_DAYS_OFF
        });
        return axios({
            method: "POST",
            data: daysOffData,
            url: `${mockupBackend}daysoff/modify/${daysOffId}`
        }).then(response =>{
            dispatch({
                type: EDIT_DAYS_OFF_SUCCESS,
                data: response.data,
            })
        }).catch(error =>{
            dispatch({
                type: EDIT_DAYS_OFF_FAIL,
                error
            })
        })
    }
}