/* eslint-disable eqeqeq */

//REFACTOR!!!!

import React from 'react';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, TimePicker} from "antd";
import {ScrollableY} from "./styled/Layout";
import AddonBefore from "./Layout/AddonBefore";
import {TaskNames} from "../assets/data/taskNames";
import {ModifyTaskDtpAction} from "../actions/internal_api/Projects/EditTaskDtpAction";
import {useDispatch} from "react-redux";
import * as moment from "moment";

const {TextArea} = Input;
const {Option} = Select;

const EditTaskModalSecond = ({visibility, onCancel, taskId, data}) => {
    const [taskForm] = Form.useForm();
    const dispatch = useDispatch();



    const mapTaskNamesToOptions = (data) =>{
        return data.map((element)=>{
            return <Option key={element} value={element}>{element}</Option>
        })
    }

    const getDataFromForm = () =>{
        Promise.all([taskForm.validateFields()]).then((values)=>{

            dispatch(ModifyTaskDtpAction(taskId, values[0])).then(()=>{
                    onCancel();
            });


        })
    };

    if(data != undefined) {
        data.taskDate = moment(data.taskDate)
        data.hoursOfWork = moment(data.hoursOfWork)
    }

    return (
        <Modal
            title="Edytuj zadanie"
            visible={visibility}
            maskClosable={false}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Anuluj
                </Button>,
                <Button key="submit" type="primary"  onClick={getDataFromForm}>
                    Zapisz
                </Button>,
            ]}
            width={"90%"}
            style={{top: 10}}
        >
            <ScrollableY maxHeight={"80vh"}>
                <Form form={taskForm} initialValues={data}>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Nazwa zadania"}>
                                <Form.Item name={"taskName"}>
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Wybierz nazwę zadania"
                                    >
                                        {mapTaskNamesToOptions(TaskNames)}
                                    </Select>
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Data"}>
                                <Form.Item name={"taskDate"}>
                                    <DatePicker style={{width:"80%"}}/>
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Opis zadania"}>
                                <Form.Item name={"taskDescription"} noStyle>
                                    <TextArea rows={4}/>
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>
                        <Col span={16}>
                            <AddonBefore additionText={"Suma godzin"}>
                                <Form.Item name={"hoursOfWork"}>
                                    <TimePicker format={'HH:mm'} minuteStep={15} />
                                </Form.Item>
                            </AddonBefore>
                        </Col>
                    </Row>
                </Form>
            </ScrollableY>
        </Modal>
    );
};

EditTaskModalSecond.propTypes = {

};

export default EditTaskModalSecond;
