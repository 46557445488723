import React from 'react';
import {Table} from "antd";
import ClientExpandableTableElement from "./ClientExpandableTableElement";

const ClientTable = ({data, onEditClick}) => {

  let columns = [
    {
      title:'Akcje',
      render: (_, row) => <a href={"#none"} onClick={()=> onEditClick(row)}>Edytuj</a>
    },
    {
    title:'Nazwa',
    dataIndex:'clientName',
    key:'clientName',
    sorter: (a,b) => ('' + a.clientName).localeCompare(b.clientName),
  },
    {
      title: "Państwo",
      dataIndex: 'country',
      key:"country",
      sorter: (a,b) => ('' + a.country).localeCompare(b.country),
    },
    {
      title: 'e-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: "NIP",
      dataIndex: 'nip',
      key: 'nip',
    },
    {
      title: "Kod pocztowy",
      dataIndex: 'postCode',
      key: 'postCode',
    },
    {
      title: 'Miasto',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Telefon',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Nazwa do Fatury',
      dataIndex: 'invoiceName',
      key: 'invoiceName',
    },
    {
      title: 'Identyfikator',
      dataIndex: 'clientInternalId',
      key: 'clientInternalId',
    },
  ];

  return (
    <div>
      <Table
        size="small"
        rowKey={"clientInternalId"}
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandedRowRender: record => <ClientExpandableTableElement recordData={record.contactPersons}/>,
          rowExpandable: () => true,
        }}
        scroll={{
          //y: "60vh",
          x: "86vw"
        }}
      />
    </div>
  );
};

ClientTable.propTypes = {

};

export default ClientTable;
