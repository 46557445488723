import React from 'react';
import {Col, Form, Input, Popconfirm, Row} from "antd";
import {Centered} from "./styled/Layout";
import {DeleteOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import { DeleteContactPersonDtpAction } from '../actions/internal_api/clients/DeleteContactPersonDtpAction';
import { DeleteContactPersonDrukAction } from '../actions/internal_api/clients/DeleteContactPersonDrukAction';
import { GetAllClientsDtpAction } from '../actions/internal_api/clients/GetAllClientsDtpAction';
import { GetAllClientsDrukAction } from '../actions/internal_api/clients/GetAllClientsDrukAction';

const {TextArea} = Input;


const ContacPersonForm = ({onDeleteClick, field, values, companySection}) => {

  const dispatch = useDispatch()

  return (
    <Row gutter={[26,24]}>
      <Col span={3}>
        <Form.Item name={[field.name, "nameSurname"]} fieldKey={[field.fieldKey, "nameSurname"]}>
        <Input/>
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item name={[field.name, "position"]} fieldKey={[field.fieldKey, "position"]}>
        <Input/>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={[field.name, "country"]} fieldKey={[field.fieldKey, "country"]}>
        <Input/>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={[field.name, "cellPhoneNumber"]} fieldKey={[field.fieldKey, "cellPhoneNumber"]}>
        <Input/>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={[field.name, "phoneNumber"]} fieldKey={[field.fieldKey, "phoneNumber"]}>
        <Input/>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={[field.name, "notes"]} fieldKey={[field.fieldKey, "notes"]}>
        <TextArea />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name={[field.name, "email"]} fieldKey={[field.fieldKey, "email"]}>
        <Input/>
        </Form.Item>
      </Col>
      <Col span={2}>
        <Centered>
          <Popconfirm title={"Czy na pewno usunąć?"} okText="Tak" cancelText="Nie"  onConfirm={()=>{

            if(values.contactPersons[field.name] !== undefined){
              if(companySection === "dtp"){
                dispatch(DeleteContactPersonDtpAction(values.contactPersons[field.name].id)).then(()=>{
                  dispatch(GetAllClientsDtpAction())
                })
              }
              else{
                dispatch(DeleteContactPersonDrukAction(values.contactPersons[field.name].id)).then(()=>{
                  dispatch(GetAllClientsDrukAction())
                })
              }
            }

            onDeleteClick(field.name)}}>
          <a href={"#none"} style={{color: "red"}}>
            <DeleteOutlined/>
          </a>
          </Popconfirm>
        </Centered>
      </Col>
    </Row>
  );
};

ContacPersonForm.propTypes = {

};

export default ContacPersonForm;