import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Modal, Row} from "antd";
import NewContactPersonSection from "./NewContactPersonSection";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {useDispatch} from "react-redux";
import {AddNewClientDtpAction} from "../actions/internal_api/clients/AddNewClientDtpAction";
import {GetAllClientsDtpAction} from "../actions/internal_api/clients/GetAllClientsDtpAction";
import {AddNewClientDrukAction} from "../actions/internal_api/clients/AddNewClientDrukAction";
import {GetAllClientsDrukAction} from "../actions/internal_api/clients/GetAllClientsDrukAction";
import {ScrollableY} from "./styled/Layout";

const NewClientModal = ({visibility, onCancel, clientType, companySection}) => {

  const [clientForm] = Form.useForm();

  const dispatch = useDispatch();


  const tempOnOk = () =>{
    Promise.all([clientForm.validateFields()]).then((values)=>{
      let newClient = {
        ...values[0],
        clientType: clientType,
      };
      

      if(companySection === "dtp"){
        dispatch(AddNewClientDtpAction(newClient)).then(()=>{
          dispatch(GetAllClientsDtpAction());
          onCancel()
        })
      }
      if(companySection === "druk"){
        dispatch(AddNewClientDrukAction(newClient)).then(()=>{
          dispatch(GetAllClientsDrukAction());
          onCancel()
        })
      }
    })
  };
  return (
      <Modal
          title={"Nowy klient"}
          visible={visibility}
          maskClosable={false}
          onCancel={onCancel}
          width={"90%"}
          footer={[
            <Button key="back" onClick={onCancel}>
              Anuluj
            </Button>,
            <Button key="submit" type="primary"  onClick={tempOnOk}>
              Utwórz
            </Button>,
          ]}
          style={{top: 10}}
      >
        <ScrollableY maxHeight={"80vh"}>
          <Form form={clientForm}>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Nazwa klienta
              </Col>
              <Col span={15}>
                <Form.Item name={"clientName"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                ID klienta
              </Col>
              <Col span={15}>
                <Form.Item name={"clientInternalId"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Państwo
              </Col>
              <Col span={15}>
                <Form.Item name={"country"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                NIP
              </Col>
              <Col span={15}>
                <Form.Item name={"nip"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Kod pocztowy
              </Col>
              <Col span={15}>
                <Form.Item name={"postCode"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Miasto
              </Col>
              <Col span={15}>
                <Form.Item name={"city"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Adres
              </Col>
              <Col span={15}>
                <Form.Item name={"address"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Telefon
              </Col>
              <Col span={15}>
                <Form.Item name={"phoneNumber"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Email
              </Col>
              <Col span={15}>
                <Form.Item name={"email"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={6}>
                Nazwa do faktury
              </Col>
              <Col span={15}>
                <Form.Item name={"invoiceName"}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="contactPersons" >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <NewContactPersonSection key={field.key} remove={remove} field={field}/>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Dodaj osobę kontaktową
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </ScrollableY>
      </Modal>
  )
};

NewClientModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewClientModal;