import {GET_PRINTING_HOUSES_SUCCESS} from "../../../consts/actions"

const initialState = {
  printingHouses: []
};

export const PrintinghouseReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_PRINTING_HOUSES_SUCCESS:
      state = {
        ...state,
        printingHouses: action.data
      };
      return state;
    default:
      return state;
  }
};


export const PrintinghouseSelector = (state) => state.PrintinghouseReducer.printingHouses;