import axios from "axios";
import {EDIT_USERS, EDIT_USERS_FAIL, EDIT_USERS_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";



export function EditUserActiveAction(userId, data){
    return (dispatch) =>{
        dispatch({
            type: EDIT_USERS
        });
        return axios({
            method: 'post',
            url: `${mockupBackend}user/modify/isactive/${userId}`,
            data: data,
        }).then(response =>{
            dispatch({
                type: EDIT_USERS_SUCCESS,
                data: response.data
            })
        }).catch(error =>{
            dispatch({
                type: EDIT_USERS_FAIL,
                error
            })
        })
    }
}