import axios from "axios";
import {GET_PERMISSIONS, GET_PERMISSIONS_FAIL, GET_PERMISSIONS_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function GetAllPermissionsAction(){
    return (dispatch) =>{
        dispatch({
            type: GET_PERMISSIONS
        });
        return axios({
            method: 'get',
            url: `${mockupBackend}permission/all`,
        }).then(response =>{
            dispatch({
                type: GET_PERMISSIONS_SUCCESS,
                data: response.data
            })
        }).catch(error =>{
            dispatch({
                type: GET_PERMISSIONS_FAIL,
                error
            })
        })
    }
}