import React, {Component} from 'react';
import ClientTable from "../../../components/ClientTable";
import {Button, Card, Col, Divider, Input, Row} from "antd";
import NewClientModal from "../../../components/NewClientModal";
import {RightToLeft} from "../../../components/styled/Layout";
import {ClientsDtpSelector} from "../../../store/internal_api/clients/clientsDtpReducer";
import {GetAllClientsDtpAction} from "../../../actions/internal_api/clients/GetAllClientsDtpAction";
import {connect} from "react-redux";
import EditClientModal from "../../../components/EditClientModal";

const DZIAL = "dtp";

function mapStateToProps(state) {
  return {
    clients: ClientsDtpSelector(state)
  }
}

function mapDispatchToProps(dispatch){
  return {
    getClients: ()=> dispatch(GetAllClientsDtpAction())
  }
}

class ClientListView extends Component {
  constructor() {
    super();
    this.state = {
      newClientVisibility: false,
      editClientVisibility: false,
      editedRecord: null
    }
  }

  componentDidMount() {
    this.props.getClients()
  }

  changeNewClientVisibility = (value) =>{
    this.setState({newClientVisibility: value})
  };

  changeEditClientVisibility = (rowData) =>{
    this.setState({editClientVisibility: true, editedRecord: rowData})
  };

  closeEditClient = () =>{
    this.setState({editClientVisibility: false, editedRecord: null})
  }

  onClick = () =>{
    alert("edit")
  };

  getOnlyOwnClients = (data) =>{
    return data.filter((element)=> element.clientType === DZIAL)
  };

  render() {
    return (
      <div>
        <Card>
          <Row gutter={[24,24]}>
            <Col span={8}>

            </Col>
            <Col span={8}>
              <Input placeholder={"Wpisz nazwę klienta"}/>
            </Col>
            <Col span={8}>
              <RightToLeft>
                <Button type={"primary"} onClick={()=> this.changeNewClientVisibility(true)}>Nowy klient</Button>
              </RightToLeft>
            </Col>
          </Row>
          <Divider/>
        <ClientTable data={this.props.clients} onEditClick={this.changeEditClientVisibility}/>
        </Card>
        { this.state.newClientVisibility
          ?
          <NewClientModal clientType={ DZIAL } companySection="dtp" visibility={ this.state.newClientVisibility }
                          onCancel={ () => this.changeNewClientVisibility(false) }/>
          :
          null
        }
        { this.state.editClientVisibility
          ?
          <EditClientModal clientType={ DZIAL } companySection="dtp" visibility={ this.state.editClientVisibility }
                          onCancel={ this.closeEditClient} defaultValues={this.state.editedRecord}/>
          :
          null
        }
      </div>
    );
  }
}

ClientListView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (ClientListView);
