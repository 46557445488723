import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
    ADD_NEW_DAY_OFF,
    ADD_NEW_DAY_OFF_SUCCESS,
    ADD_NEW_DAY_OFF_FAIL,
} from "../../../consts/actions"

export function AddNewDaysOffAction(daysOffData){
    return (dispatch) =>{
        dispatch({
            type: ADD_NEW_DAY_OFF
        });
        return axios({
            method: "PUT",
            data: daysOffData,
            url: `${mockupBackend}daysoff/new`
        }).then(response =>{
            dispatch({
                type: ADD_NEW_DAY_OFF_SUCCESS,
                data: response.data,
            })
        }).catch(error =>{
            dispatch({
                type: ADD_NEW_DAY_OFF_FAIL,
                error
            })
        })
    }
}