import axios from "axios"
import {DELETE_CONTACT_PERSON_DRUK, DELETE_CONTACT_PERSON_DRUK_SUCCESS, DELETE_CONTACT_PERSON_DRUK_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function DeleteContactPersonDrukAction(valuationId){
  return (dispatch) =>{
    dispatch({
      type: DELETE_CONTACT_PERSON_DRUK
    });
    return axios({
      method: "DELETE",
      url: `${mockupBackend}clientdruk/contactperson/delete/${valuationId}`
    }).then(response =>{
      dispatch({
        type: DELETE_CONTACT_PERSON_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: DELETE_CONTACT_PERSON_DRUK_FAIL,
        error
      })
    })
  }
}