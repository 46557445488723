import React, {Fragment} from 'react';
import {Checkbox, Col, Divider, Form, Row} from "antd";

const PrintNewProjectArchiveSection = ({defaultValues}) => {
  return (
    <Fragment>
      <Divider>Archiwum</Divider>
      <Row gutter={[24,24]}>
        <Col span={6}>
          <Form.Item name={"isArchived"} initialValue={defaultValues !== undefined?defaultValues[0].config.isArchived: null} valuePropName="checked">
            <Checkbox>Projekt zakończony</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

PrintNewProjectArchiveSection.propTypes = {

};

export default PrintNewProjectArchiveSection;
