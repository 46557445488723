import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  EDIT_DAYS_OFF_PLAN,
  EDIT_DAYS_OFF_PLAN_SUCCESS,
  EDIT_DAYS_OFF_PLAN_FAIL,
} from "../../../consts/actions"

export function EditDaysOffPlanAction(userId, daysOffPlanData){
  return (dispatch) =>{
    dispatch({
      type: EDIT_DAYS_OFF_PLAN
    });
    return axios({
      method: "POST",
      data: daysOffPlanData,
      url: `${mockupBackend}user/modify/yearlydata/${userId}`
    }).then(response =>{
      dispatch({
        type: EDIT_DAYS_OFF_PLAN_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: EDIT_DAYS_OFF_PLAN_FAIL,
        error
      })
    })
  }
}