import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  EDIT_CLIENT_DTP,
  EDIT_CLIENT_DTP_SUCCESS,
  EDIT_CLIENT_DTP_FAIL,
} from "../../../consts/actions"

export function EditClientDtpAction(clientId, clientData){
  return (dispatch) =>{
    dispatch({
      type: EDIT_CLIENT_DTP
    });
    return axios({
      method: "POST",
      data: clientData,
      url: `${mockupBackend}clientdtp/modify/${clientId}`
    }).then(response =>{
      dispatch({
        type: EDIT_CLIENT_DTP_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: EDIT_CLIENT_DTP_FAIL,
        error
      })
    })
  }
}