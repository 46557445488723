import React, {Component} from 'react';
import {Card} from "antd";
import WorkersWorkTable from "../../../components/WorkersWorkTable";
import {DaysOffPlanSelector} from "../../../store/internal_api/daysOffPlan/DaysOffPlanReducer";
import {GetAllDaysOffPlanAction} from "../../../actions/internal_api/daysOffPlan/GetAllDaysOffPlans";
import {connect} from "react-redux";
import { GetAllUsersAction } from '../../../actions/internal_api/users/GetAllUsers';
import { UsersSelector } from '../../../store/internal_api/users/usersReducer';


function mapStateToProps(state) {
  return {
    daysOffPlan: DaysOffPlanSelector(state),
    users: UsersSelector(state)
  }
}

function mapDispatchToProps(dispatch){
  return {
    getDaysOffPlan: () => dispatch(GetAllDaysOffPlanAction()),
    getUsers: () => dispatch(GetAllUsersAction())
  }
}


class YearWorkSettingView extends Component {

  constructor() {
    super();
    this.state = {
      newProjectVisibility: false
    }
  }

  componentDidMount() {
    //this.props.getDaysOffPlan()
    this.props.getUsers()
  }

  changeProjectVisibility = (value) =>{
    this.setState({newYearVisibility: value})
  };


  onClick = () =>{
    alert("edit")
  };

  dataWithKey = (data) =>{
    let dataWithKey = [...data];
    for(let i = 0; i < dataWithKey.length; i++){
      dataWithKey[i].key = dataWithKey[i].id
    }
    return dataWithKey;
  };

  render() {
    return (
        <div>
          <Card>
            <WorkersWorkTable originData={this.dataWithKey(this.props.users)}/>
          </Card>
        </div>
    );
  }
}

YearWorkSettingView.propTypes = {

};

export default connect(mapStateToProps, mapDispatchToProps) (YearWorkSettingView);
