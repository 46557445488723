import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  NEW_CLIENT_DRUK,
  NEW_CLIENT_DRUK_SUCCESS,
  NEW_CLIENT_DRUK_FAIL,
} from "../../../consts/actions"

export function AddNewClientDrukAction(clientData){
  return (dispatch) =>{
    dispatch({
      type: NEW_CLIENT_DRUK
    });
    return axios({
      method: "PUT",
      data: clientData,
      url: `${mockupBackend}clientdruk/new`
    }).then(response =>{
      dispatch({
        type: NEW_CLIENT_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: NEW_CLIENT_DRUK_FAIL,
        error
      })
    })
  }
}