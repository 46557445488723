import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "antd";

const ExpandAllRowsButton = ({state, changeStateFunc}) => {
    console.log("STATE", state)
  return (
    <Button type={"secondary"} onClick={changeStateFunc}>{state ? "Ukryj wszystko" : "Pokaż wszystko"}</Button>
  );
};

ExpandAllRowsButton.propTypes = {
 state: PropTypes.bool.isRequired,
 changeStateFunc: PropTypes.func.isRequired,
};

export default ExpandAllRowsButton;
