import {PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK, PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_SUCCESS, PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_FAIL} from "../../../consts/actions";
import axios from "axios";
import {mockupBackend} from "../../../consts/urls";


export function CheckIfProjectWithGivenClientAndInternalIdExist(client, id){
  return (dispatch) =>{
    dispatch({
      type: PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK
    });
    return axios({
      method: "GET",
      url: `${mockupBackend}projectdtp/check/${client}/${id}`
    }).then(response =>{
      dispatch({
        type: PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_FAIL,
        error
      })
    })
  }
}
