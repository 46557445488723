import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {WS_CONNECTED} from "../consts/actions";
import {mockupBackend} from "../consts/urls";

class WebsocketConnection extends Component {

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch({type: WS_CONNECTED, data: `${mockupBackend}gs-guide-websocket`})
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

WebsocketConnection.propTypes = {
  host: PropTypes.string.isRequired,
};

export default connect()(WebsocketConnection);
