import React, {Component} from 'react';
import {Button, Card, Input, Pagination, Popover} from "antd";
import DTPArchiveTable from "../../../components/DTPArchiveTable";
import {connect} from "react-redux";
import EditProjectModal from "../../../components/EditProjectModal";
import {ModifyProjectDtpAction} from "../../../actions/internal_api/Projects/ModifyProjectDtpAction";
import { GetAllProjectsDtpAction } from '../../../actions/internal_api/Projects/GetAllProjectsDtpAction';
import {AddNewProjectDtpAction} from "../../../actions/internal_api/Projects/AddNewProjectDtpAction";
import {AddTaskAction} from "../../../actions/internal_api/Projects/AddTaskDtpAction";
import {ModifyProjectWatcherAndPhaseAction} from "../../../actions/internal_api/Projects/ModifyProjectWatcherAndPhaseDtpAction";
import {GetAllClientsDtpAction} from "../../../actions/internal_api/clients/GetAllClientsDtpAction";
import {LoggedInUserSelector} from "../../../store/internal_api/LoginUser";
import {ArchiveProjectsDtpSelector} from "../../../store/internal_api/Projects/ProjectsArchiveDtpReducer";
import {GetPagedArchiveProjectDtpAction} from "../../../actions/internal_api/Projects/GetPagedArchiveProjectDtpAction"
import ProjectFilterMenu from "../../../components/ProjectFilterMenu";
import ProjectViewOptions from "../../../components/ProjectViewOptions";
import {ID_DESCENDING, INTERNALID_DESCENDING} from "../../../consts/apiConsts";
import {Centered, MarginVertical} from "../../../components/styled/Layout";
import _ from "lodash";
import ExpandAllRowsButton from "../../../components/ExpandAllRowsButton";
import {SetProjectViewDataExpandRowsAction} from "../../../actions/local/SetProjectViewDataExpandRowsAction";
import {SetProjectIdsToExpandAction} from "../../../actions/local/SetProjectIdsToExpandArray";
import {
  ProjectViewDataExpandedIds,
  ProjectViewDataExpandRowsSelector
} from "../../../store/local/ProjectViewDataReducer";

function mapStateToProps(state){
  return({
    projects: Object.assign({},ArchiveProjectsDtpSelector(state)),
    loggedInUser: LoggedInUserSelector(state),
    expandRows: ProjectViewDataExpandRowsSelector(state),
    idsToExpand: ProjectViewDataExpandedIds(state)
  })
}

function mapDispatchToProps(dispatch){
  return ({
    getProjects: (page, size, filterValue = "",
                  containingBook = false,
                  containingCover = false,
                  containingOther = false,
                  containingAudiobook = false,
                  containingEpub = false,
                  containingAPDF = false,
                  sorter = ID_DESCENDING,
                  clientFilter = 0,
                  projectWatcher = 0,
                  mockupFilter= "",
                  typePhase= ""
    ) => dispatch(GetPagedArchiveProjectDtpAction(page,
        size,
        filterValue,
        containingBook,
        containingCover,
        containingOther,
        containingAudiobook,
        containingEpub,
        containingAPDF,
        sorter,
        clientFilter,
        projectWatcher,
        mockupFilter,
        typePhase)),
    saveNewProject: (projectData) => dispatch(AddNewProjectDtpAction(projectData)),
    editProject: (internalId, projectData) => dispatch(ModifyProjectDtpAction(internalId, projectData)),
    addTask: (typeId, data) => dispatch(AddTaskAction(typeId, data)),
    modifyProjectWatcherAndPhaseAction: (typeId, data) => dispatch(ModifyProjectWatcherAndPhaseAction(typeId, data)),
    getClients: () => dispatch(GetAllClientsDtpAction()),
    getAllProjects: () => dispatch(GetAllProjectsDtpAction()),
    setExpandRows: () => dispatch(SetProjectViewDataExpandRowsAction()),
    setIdsToExpand:(data) => dispatch(SetProjectIdsToExpandAction(data)),
  })
}


class DTPArchive extends Component {

  constructor() {
    super();
    this.state = {
      page: 1,
      pageSize: 10,
      filterInputValue: "",
      isNewProjectModalVisible: false,
      isEditProjectModalVisible: false,
      isAddTaskModalVisible: false,
      filterInput: "",
      editedIndex: 0,
      addTaskSettings:{
        projectIndex: 0,
        projectType: "",
        projectId: ""
      },
      selectedTypes: [],
      sorter: INTERNALID_DESCENDING,
      //expandRows: false,
      clientFilterValue: 0,
      projectWatcher: 0,
      mockupFilter: "",
      typePhase: ""
    }
  }

  componentDidMount() {
    this.props.getProjects(this.state.page - 1, this.state.pageSize, this.state.filterInputValue);
    this.props.getClients();
  }


  loggedInUserIsNotWorker = () =>{
    return this.props.loggedInUser.user.permission.name.toLowerCase() !== "pracownik"
  }

  onFilterTaskExecutorChange = (value) =>{
    this.setState({filterByExecutor: value})
  }

  onAddTaskClick = (addTaskSettings) =>{
    this.setState({addTaskSettings: addTaskSettings, isAddTaskModalVisible: true})
  };

  onCancelAddTask = () =>{
    this.setState({isAddTaskModalVisible: false})
  };

  getDataFromAddTaskModal = (typeId, taskData, phaseData ) =>{
    //taskData.executor = projectData.taskWatcher;
    this.props.addTask(typeId, taskData).then(()=>{
      this.props.modifyProjectWatcherAndPhaseAction(typeId, phaseData).then(()=>{
        this.refreshProjects()
      })
    })

  };

  newProjectAddData = (data) =>{
    this.props.saveNewProject(data).then(()=>{
      this.refreshProjects()


    })
    this.setState({isNewProjectModalVisible: false});
  };

  hookTypesData = (type, data)=>{
    let newTypeData = {...this.state.typesData};
    newTypeData[type] = data;
    this.setState({typesData: newTypeData})
  };

  editProjectAddData = (data) =>{
    this.props.editProject(data.id, data).then(()=>{
      //this.setState({isEditProjectModalVisible: false,});
      this.refreshProjects();
    });
    this.setState({isEditProjectModalVisible: false,});
  };

  editHookTypesData = (type, data)=>{
    let newTypeData = {...this.state.typesData};
    newTypeData[type] = data;
    this.setState({editTypesData: newTypeData})
  };

  showModal = () => {
    this.setState({isNewProjectModalVisible: true, newProjectData:{}});
  };



  handleCancel = () => {
    this.setState({isNewProjectModalVisible: false});
  };

  showEditModal = (id) => {
    this.setState({
      editedIndex: this.props.projects.projects.findIndex(element => element.id === id),
      isEditProjectModalVisible: true,
      editProjectData:this.props.projects.projects[this.state.editedIndex]});
  };


  handleCancelEdit = () => {
    this.setState({isEditProjectModalVisible: false});
  };

  onNewProjectClick = () =>{
    this.showModal()
  };



  onFilterChange = (e) =>{
    const {value} = e.target;
    this.setState({filterInput: value})
  };

  addTaskDefaultTypeData = () =>{
    return this.props.projects.projects[this.state.addTaskSettings.projectIndex]
      .types.filter((element)=> element.config.internalType === this.state.addTaskSettings.projectType)[0]
  };

  getValueForFilterByType = (value) =>{
    this.setState({valueForFilterByType: value})
  }

  updatePageAndPageSize = (page, pageSize) =>{

  }

  onPaginationChange = (page, size) =>{
    this.setState({page: page, pageSize: size}, () =>{
      this.refreshProjects();
    })
  }

  onFilterSearchChange = (event) =>{
    this.setState({filterInputValue: event.target.value, page: 1}, () =>{
      this.refreshProjects();
    })
  }

  updateType = (value) => {
    this.setState({selectedTypes: value,  page: 1}, () =>{
      this.refreshProjects()
    })

  }

  refreshProjects = () =>{
    this.props.getProjects(this.state.page - 1, this.state.pageSize, this.state.filterInputValue, this.state.selectedTypes.includes("book"),
        this.state.selectedTypes.includes("cover"), this.state.selectedTypes.includes("other"), this.state.selectedTypes.includes("audiobook"),
        this.state.selectedTypes.includes("epub"), this.state.selectedTypes.includes("apdf"), this.state.sorter, this.state.clientFilterValue,
        this.state.projectWatcher, this.state.mockupFilter, this.state.typePhase);
    this.props.getAllProjects().then(()=>{
      this.updateExpandedIds()
    })
  }

  switchExpandAllRows = () =>{
    this.props.setExpandRows()

    this.props.setIdsToExpand(this.getCurrentProjectsIds())


  }
  updateExpandedIds = () =>{
    this.props.setIdsToExpand(this.getCurrentProjectsIds())
  }

  getCurrentProjectsIds = () =>{
    let ids = []

    this.props.projects.projects.forEach(element =>{
      ids.push(element.id)
    })

    return ids
  }

  onSorterChoosed = (value) =>{
    this.setState({sorter: value}, ()=>{
      this.refreshProjects()
    })
  }

  onClientFilterChange = (value) =>{
    this.setState({clientFilterValue: value}, ()=>{
      this.refreshProjects();
    })

  }

  onFilterProjectWatcherChange = (value) =>{
    this.setState({projectWatcher: value}, ()=>{
      this.refreshProjects();
    })
  }

  onFilterMockupChange = (event) =>{
    this.setState({mockupFilter: event.target.value}, ()=>{
      this.refreshProjects()
    })
  }

  onFilterTypePhaseChange = (value) =>{
    this.setState({typePhase: value}, ()=>{
      this.refreshProjects();
    })
  }

  deleteProject = (projectId) =>{
    this.props.deleteProject(projectId).then(()=> this.refreshProjects())
  }

  deleteTask = (taskId) =>{
    this.props.deleteTask(taskId).then(()=> this.refreshProjects())
  }

  deleteType = (typeId) =>{
    this.props.deleteType(typeId).then(() => this.refreshProjects())
  }

  resetFilteringAndSorting = () =>{
    this.setState({sorter: INTERNALID_DESCENDING, projectWatcher: 0, mockupFilter: "", typePhase: ""}, ()=>{
      this.setState({selectedTypes: [],  page: 1}, ()=>{
        this.setState({clientFilterValue: 0, page:1}, ()=>{
          this.refreshProjects()
        })
      })
    })
  }

  

  render() {
    return (
      <div>
        <Card>
          <MarginVertical>
            <Centered>
              <Popover content={<p>Możesz wpisać nazwę projektu lub wewnętrzny identyfikator</p>}>
                <Input allowClear onChange={_.debounce(this.onFilterSearchChange, 1000)} placeholder={"Czego szukasz?"}/>
              </Popover>
            </Centered>
          </MarginVertical>
          <MarginVertical>
            <ProjectViewOptions
                leftSide={
                  <>
                    <ProjectFilterMenu
                        projects={this.props.projects}
                        resetOptions={this.resetFilteringAndSorting}
                        onClientChange={this.onClientFilterChange}
                        onSearchFilterChange={this.onFilterSearchChange}
                        onSorterChange={this.onSorterChoosed}
                        onSelectedTypeChange={this.updateType}
                        onProjectWatcherChange={this.onFilterProjectWatcherChange}
                        onMockupChange={this.onFilterMockupChange}
                        onTypePhaseChange={this.onFilterTypePhaseChange}
                    />
                    <ExpandAllRowsButton changeStateFunc={()=> this.switchExpandAllRows() } state={this.props.expandRows}/>
                  </>
                }

            >

            </ProjectViewOptions>
          </MarginVertical>
          <Pagination onChange={this.onPaginationChange} size="small" current={this.state.page} total={this.props.projects.totalItems} pageSize={this.state.pageSize} />
          <DTPArchiveTable
            data={[...this.props.projects.projects]}
            isLoading={this.props.projects.loading}
            currentItems={this.props.projects.totalItems}
            editActions={{
              onClick: this.showEditModal,
            }}
            expandRows={this.props.expandRows ? this.props.idsToExpand: []}
          />
          <Pagination onChange={this.onPaginationChange} size="small" current={this.state.page} total={this.props.projects.totalItems} pageSize={this.state.pageSize} />
        </Card>
        {this.state.isEditProjectModalVisible
          ?
          <EditProjectModal
            visibility={ this.state.isEditProjectModalVisible }
            onCancel={ this.handleCancelEdit }
            setData={ this.editProjectAddData }
            defaultValues={this.props.projects.projects[this.state.editedIndex]  }
            archive
          />
          :
          null
        }
      </div>
    );
  }
}

DTPArchive.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (DTPArchive);
