import axios from "axios"
import {DELETE_TYPE_DTP, DELETE_TYPE_DTP_SUCCESS, DELETE_TYPE_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function DeleteTypeDtpAction(typeId){
    return (dispatch) =>{
        dispatch({
            type: DELETE_TYPE_DTP
        });
        return axios({
            method: "DELETE",
            url: `${mockupBackend}projectdtp/type/delete/${typeId}`
        }).then(response =>{
            dispatch({
                type: DELETE_TYPE_DTP_SUCCESS,
                data: response.data,
            })
        }).catch(error =>{
            dispatch({
                type: DELETE_TYPE_DTP_FAIL,
                error
            })
        })
    }
}