import React from 'react';
import PropTypes from 'prop-types';
import {AdditionText, AdditionTextBox} from "../styled/Text";

const AddonBefore = ({children, additionText}) => {
  return (
    <AdditionTextBox>
      <AdditionText>{additionText}</AdditionText>
      {children}
    </AdditionTextBox>
  );
};

AddonBefore.propTypes = {
  children: PropTypes.node,
  additionText: PropTypes.string.isRequired
};

export default AddonBefore;