import {PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_RESET} from "../../consts/actions";


export function ResetProjectInternalIdChecker(){
  return (dispatch) => {
    dispatch({
      type: PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_RESET
    });
  }
}
