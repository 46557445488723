import React from 'react';
import {Col, Form, Row, Select} from "antd";
import AddonBefore from "../Layout/AddonBefore";
import {UsersSelector} from "../../store/internal_api/users/usersReducer";
import {useSelector} from "react-redux";

const {Option} = Select;

const DtpProjectTypeCommonData = ({archive}) => {

  const users = useSelector(UsersSelector)


  const workers = users.map((element)=>{

    return <Option key={element.id} value={element.id}>{element.name}</Option>
  })


  

  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <AddonBefore additionText={"Właściciel plików:"}>
          <Form.Item name={"typeWatcher"} /*rules={[{ required: true, message: 'Proszę wybrać opiekuna plików!' }]}*/>
            <Select
                disabled={archive}
              showSearch
              style={{ width: '100%' }}
              placeholder="Wybierz osobę odpowiedzialną"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {workers}
            </Select>
          </Form.Item>
        </AddonBefore>
      </Col>
    </Row>
  );
};

DtpProjectTypeCommonData.propTypes = {
};

export default DtpProjectTypeCommonData;
