import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Divider, Form, Row, Select} from "antd";
import AddonBefore from "../Layout/AddonBefore";

const {Option} = Select;

const ProjectTypesStatus = ({typesVariants, archive}) => {


    const renderOptions = () =>{
        return typesVariants.map((element) =>{
            return(
              <Option key={element.text} value={element.text}>{element.text}</Option>
            )
        })
    };

    return (
      <Fragment>
          <Divider>Status</Divider>
        <Row gutter={[24,24]}>
           <Col span={9}>
               <AddonBefore additionText={"Etap"}>
                   <Form.Item name={"projectTypePhase"} rules={[{ required: true, message: 'Proszę wybrać status typu!' }]}>
                       <Select disabled={archive} placeholder="Wybierz etap" style={{ width: '100%' }}>
                           {renderOptions()}
                       </Select>
                   </Form.Item>
               </AddonBefore>
           </Col>
        </Row>
      </Fragment>
    );
};

ProjectTypesStatus.propTypes = {
    typesVariants: PropTypes.array.isRequired
};

export default ProjectTypesStatus;