import axios from "axios";
import {LOGIN_USER, LOGIN_USER_FAIL, LOGIN_USER_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";



export function LoginAction(userLogin, userPassword){
    return (dispatch) =>{
        dispatch({
            type: LOGIN_USER
        });
        return axios({
            method: 'post',
            url: `${mockupBackend}user/login`,
            data: {
                login: userLogin,
                password: userPassword,
            },
        }).then(response =>{
            dispatch({
                type: LOGIN_USER_SUCCESS,
                data: response.data
            })
        }).catch(error =>{
            dispatch({
                type: LOGIN_USER_FAIL,
                error
            })
        })
    }
}