import React, {Fragment} from 'react';
import {Table, Card} from "antd";
import * as moment from "moment";
import {useState} from "react";
import EditTaskModal from "./EditTaskModal";
import { nestedTableBackgroundColors } from '../assets/scripts/colors';
import ModalWithInputVerification from "./ModalWithInputVerification";

const TaskTable = ({deleteTask, data, fullData, typeIndex, typeId, refreshProjects}) => {

    let [editModal, setEditModal] = useState(false);
    let [taskIndex, setTaskIndex] = useState(-1);
    let [taskId, setTaskId] = useState(-1);


    const openModal = (index, id) =>{
        setTaskIndex(index);
        setTaskId(id);
        setEditModal(true)
    };

    const closeMOdal = () =>{
       setTaskIndex(-1);
       setTaskId(-1);
       setEditModal(false);
        refreshProjects()
    }


    let columns = [
        {
            title:'Akcje',
            render: (text, record, index) => <div><a href="#none" onClick={()=> openModal(index, record.id)}>Edytuj</a> <ModalWithInputVerification onAccept={()=> deleteTask(record.id)} onCancel={()=> {}} trueValue={record.taskName + " " + record.taskWatcher.name}>
                <a href="#none">Usuń</a>
            </ModalWithInputVerification>
            </div>
        },
      {
        title:'Data zadania',
        dataIndex:'taskDate',
        key:'taskDate',
        sorter: (a,b) => ('' + a.taskDate).localeCompare(b.taskDate),
        render: (text) => moment(text).format("DD-MM-YYYY")
    },
        {
            title:'Nazwa Zadania',
            dataIndex:'taskName',
            key:'taskName',
            sorter: (a,b) => ('' + a.taskName).localeCompare(b.taskName),
        },
        {
            title:'Opis zadania',
            dataIndex:'taskDescription',
            key:'taskDescription',
        },
        {
            title: "Suma Godzin",
            dataIndex: 'hoursOfWork',
            key: 'hoursOfWork',
            sorter: (a,b) => ('' + a.hoursOfWork).localeCompare(b.hoursOfWork),
            render: (text) => moment(text).format("HH:mm")
        },
        {
            title: "Osoba realizująca",
            dataIndex: "taskWatcher",
            key: "taskWatcher",
            render: (element) => element != null ? element.name : "ERROR"
        }
    ];


    return (
        <Fragment>
            <Card bodyStyle={{backgroundColor: nestedTableBackgroundColors.third}}>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  size="small"
                  rowKey="id"
                />
            </Card>
            {editModal
                ?
                <EditTaskModal visibility={editModal} onCancel={closeMOdal} fullData={fullData} typeId={typeId} typeIndex={typeIndex}
                               taskIndex={taskIndex} taskId={taskId}/>
                :
                null
            }
        </Fragment>
    );
};

TaskTable.propTypes = {

};

export default TaskTable;
