import axios from "axios"
import {GET_ALL_PROJECTS_DTP, GET_ALL_PROJECTS_DTP_SUCCESS, GET_ALL_PROJECTS_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function GetAllProjectsDtpAction(){
  return (dispatch) =>{
    dispatch({
      type: GET_ALL_PROJECTS_DTP
    });
    return axios({
      method: "GET",
      url: `${mockupBackend}projectdtp/all`
    }).then(response =>{
      dispatch({
        type: GET_ALL_PROJECTS_DTP_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: GET_ALL_PROJECTS_DTP_FAIL,
        error
      })
    })
  }
}