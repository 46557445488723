import React, {Fragment} from 'react';
import {Button, Col, Input, Row, Select} from "antd";
import {RightToLeft} from "./styled/Layout";
import AddonBefore from "./Layout/AddonBefore";

const {Option} = Select;

const PrintProjectsSettings = ({onNewProjectClick, onFilterChange, getMonoColorValue, userIsNotWorker}) => {



    return (
      <Fragment>
        <Row gutter={[24,24]}>
          <Col span={8}>

          </Col>
          <Col span={8}>
            <Input
              onChange={onFilterChange}
              placeholder={"Wpisz nawzwę projektu aby wyszukać projekty"}
            />
          </Col>
          <Col span={8}>
            <RightToLeft>
              {userIsNotWorker
                ?
                <Button type={ "primary" } onClick={ onNewProjectClick }>Nowy projekt</Button>
                :
                null
              }
            </RightToLeft>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={5}>
            <AddonBefore additionText={"Papier"}>
            <Select onChange={(value)=> getMonoColorValue(value)} defaultValue={0} style={{ width: '100%' }} >
              <Option key={0} value={0}>Brak filtrowania</Option>
              <Option key={1} value={1}>Mono</Option>
              <Option key={2} value={2}>Kolor</Option>
            </Select>
            </AddonBefore>
          </Col>
        </Row>
      </Fragment>
    );
};

PrintProjectsSettings.propTypes = {
    
};

export default PrintProjectsSettings;
