import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {ScrollableY} from "./styled/Layout";
import {useDispatch} from "react-redux";
import {AddNewPrintingHouseAction} from "../actions/internal_api/printingHouse/AddNewPrintingHouseAction";
import {GetAllPrintingHouseAction} from "../actions/internal_api/printingHouse/GetAllPrintingHouseAction";

const {Option} = Select;

const NewPrintingHouseModal = ({visibility, onCancel}) => {
  const [mainForm] = Form.useForm();

  const dispatch = useDispatch();

  const onOk = () =>{
    Promise.all([mainForm.validateFields()]).then((values)=>{
      dispatch(AddNewPrintingHouseAction(values[0])).then(()=>{
        dispatch(GetAllPrintingHouseAction()).then(()=>{
          onCancel()
        })
      })

    })
  };

  return (
    <Modal
      title={"Nowa drukarnia"}
      visible={visibility}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={onOk}>
          Utwórz
        </Button>,
      ]}
      onCancel={onCancel}
      width={"90%"}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={mainForm}>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Nazwa drukarni
            </Col>
            <Col span={15}>
              <Form.Item name={"name"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Typ drukarni
            </Col>
            <Col span={15}>
              <Form.Item name={"type"}>
              <Select
                style={{ width: '100%' }}
                placeholder="Wybierz typ"
                optionFilterProp="children"
              >
                <Option value="Offset">Offset</Option>
                <Option value="Cyfra">Cyfra</Option>
              </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              NIP
            </Col>
            <Col span={15}>
              <Form.Item name={"nip"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Nazwa Faktura
            </Col>
            <Col span={15}>
              <Form.Item name={"invoiceName"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Kod pocztowy
            </Col>
            <Col span={15}>
              <Form.Item name={"postCode"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Miasto
            </Col>
            <Col span={15}>
              <Form.Item name={"city"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Adres
            </Col>
            <Col span={15}>
              <Form.Item name={"address"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Telefon
            </Col>
            <Col span={15}>
              <Form.Item name={"phone"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Email
            </Col>
            <Col span={15}>
              <Form.Item name={"email"}>
              <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ScrollableY>
    </Modal>
  );
};

NewPrintingHouseModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewPrintingHouseModal;