import axios from "axios";
import {LOGOUT_USER, LOGOUT_USER_FAIL, LOGOUT_USER_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";



export function LogoutAction(token){
  return (dispatch) =>{
    dispatch({
      type: LOGOUT_USER
    });
    return axios({
      method: 'post',
      url: `${mockupBackend}user/logout`,
      headers: {authorization: token}
    }).then(response =>{
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        data: response.data
      })
    }).catch(error =>{
      dispatch({
        type: LOGOUT_USER_FAIL,
        error
      })
    })
  }
}
