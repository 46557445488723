import React from 'react';
import {Button, Col, Row} from "antd";

const UserManagementOptions = ({newUserCLick}) => {
  return (
    <div>
      <Row gutter={[24,24]}>
        <Col >
          <Button type={"primary"} onClick={newUserCLick}>Nowy użytkownik</Button>
        </Col>
      </Row>
    </div>
  );
};

UserManagementOptions.propTypes = {
  
};

export default UserManagementOptions;
