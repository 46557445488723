import React from 'react';
import PropTypes from 'prop-types';
import {Space, Table} from "antd";
import moment from "moment";
import {UsersSelector} from "../store/internal_api/users/usersReducer";
import {useSelector} from "react-redux";
import ModalWithInputVerification from "./ModalWithInputVerification";

const WorkingHoursViewDataOfDay = ({data, onEditCLick, userIsNotWorker, onDeleteClick}) => {



  let users = useSelector(UsersSelector)

  const listOfUsersForFilter = (data) =>{
    let list = []

    data.forEach((element)=>{
      list.push({text: element.name, value: element.name})
    })
    return list
  }

  let columns = [{
    title: "Rodzaj",
    dataIndex: "typeOfDayOff",
    key: "typeOfDayOff",
    sorter: (a, b) => a.typeOfDayOff.localeCompare(b.typeOfDayOff),
  },
    {
      title: "Początek",
      dataIndex: "dayStart",
      key: "dayStart",
      render: text => { return moment(text).format("DD-MM-YYYY")}
    },
    {
      title: "Koniec",
      dataIndex: "dayEnd",
      key: "dayEnd",
      render: text => { return moment(text).format("DD-MM-YYYY")}
    },
    {
      title: "Pracownik",
      dataIndex: "worker",
      key: "worker",
      sorter: (a, b) => {
        return a.worker.name.localeCompare(b.worker.name)},
      filters: listOfUsersForFilter(users),
      onFilter: (value, record) =>record.worker.name === value, //record.worker.indexOf(value) === 0,
      render: (element) => element.name
    },
    {
      title: "Opis",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ile dni",
      dataIndex: "howMuchDays",
      key: "howMuchDays",
    },
    {
      title: "Akcje",
      key: "actions",
      render: (text, record, index) =>(
        <Space size="middle">
          {userIsNotWorker
            ?
              <>
                <a href="#none" onClick={ () => onEditCLick(record.id) }>Edytuj</a>
                <ModalWithInputVerification blackList={["pracownik"]} onAccept={()=>onDeleteClick(record.id)} onCancel={()=> {}} trueValue={"USUŃ"}>
                  <a href="#none">Usuń</a>
                </ModalWithInputVerification>
            </>
            :
            null
          }
        </Space>
      )
    }
  ];

  return (
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          //y: "57vh",
          x: "86vw"
        }}
      />
  );
};

WorkingHoursViewDataOfDay.propTypes = {
  data: PropTypes.array.isRequired
};

export default WorkingHoursViewDataOfDay;
