import React, {Component} from 'react';
import {Card, Divider} from "antd";
import PrintArchiveProjectSettings from "../../../components/PrintArchiveProjectSettings";
import PrintArchiveProjectTable from "../../../components/PrintArchiveProjectTable";
import {ProjectsDrukSelector} from "../../../store/internal_api/Projects/ProjectsDrukReducer";
import {connect} from "react-redux";
import PrintEditProjectModal from "../../../components/PrintEditProjectModal";
import {ModifyProjectDrukAction} from "../../../actions/internal_api/Projects/ModifyProjectDrukAction";
import { GetAllProjectsDrukAction } from '../../../actions/internal_api/Projects/GetAllProjectDrukAction';

function mapStateToProps(state){
  return({
    projects: ProjectsDrukSelector(state)
  })
}

function mapDispatchToProps(dispatch){
  return ({
    getProjects: () => dispatch(GetAllProjectsDrukAction()),
    editProject: (internalId, projectData) => dispatch(ModifyProjectDrukAction(internalId, projectData))
  })
}

class PrintArchive extends Component {

  constructor() {
    super();
    this.state = {
      newProjectVisibility: false,
      editProjectVisibility: false,
      filterInput: "",
      newProjectData: null,
      typesData: {
        printBook: null,
        printOther: null,
      },
      editProjectData: null,
      editTypesData:{
        printBook: null,
        printOther: null,
      },
      editedIndex: 0,
      monoColorFilterOption: 0
    }
  }

  componentDidMount() {
    this.props.getProjects();
  }


  dataProvider = (unfilteredData) =>{

    unfilteredData = unfilteredData.filter(element => {
      let notArchived = false
      for (let i = 0; i < element.types.length; i++){
        if(element.types[i].isArchived === false){
          notArchived = true;
        }
      }
      return !notArchived
    })

    let {filterInput} = this.state;
    let filtered;
    unfilteredData = this.monoColorFilter(unfilteredData);
    if (filterInput !== ""){
      filtered = unfilteredData.filter((data) =>{return data.projectName.toLowerCase().includes(filterInput.toLowerCase())});
      return filtered
    }
    else{
      return unfilteredData
    }
  };

  monoColorFilter = (data) =>{
    switch(this.state.monoColorFilterOption) {
      case 0:
        return data;
      case 1:
        let newDataMono = data.filter((element) => element.isPrintBookEnabled === true);
        newDataMono = newDataMono.filter((element) => element.types.some((type) => type.config.monoNumberOf || type.config.monoPaper || type.config.monoOverprint || type.config.monoFinish || type.config.monoDescription));
        return newDataMono;
      case 2:
        let newDataColor = data.filter((element) => element.isPrintBookEnabled === true);
        newDataColor = newDataColor.filter((element) => element.types.some((type) => type.config.colorNumberOf || type.config.colorPaper || type.config.colorOverprint || type.config.colorFinish || type.config.colorDescription));
        return newDataColor;
      default:
        return data;
    }
  }

  editProject = (id) =>{
    this.setState(
      {
        editedIndex: this.dataProvider(this.props.projects).findIndex(element => element.id === id),
        editProjectData: this.dataProvider(this.props.projects)[this.state.editedIndex],
        editProjectVisibility: true})
  };

  onEditCancel = () =>{
    this.setState({editProjectVisibility: false, editedIndex: 0})
  };

  editProjectAddData = (data) =>{
    this.props.editProject(data.id, data).then(()=>{
      this.props.getProjects();
      this.setState({editProjectVisibility: false, editProjectData: null});
    });
  };

  onFilterChange = (e) =>{
    const {value} = e.target;
    this.setState({filterInput: value})
  };

  setMOnoColorFilterType = (value) =>{
    this.setState({monoColorFilterOption: value})
  }

  render() {
    return (
        <div>
          <Card>
            <PrintArchiveProjectSettings onFilterChange={this.onFilterChange} getMonoColorValue={this.setMOnoColorFilterType} />
            <Divider/>
            <PrintArchiveProjectTable data={this.dataProvider(this.props.projects)} editActions={{onClick: this.editProject}}/>
          </Card>
          { this.state.editProjectVisibility
            ?
            <PrintEditProjectModal
              onCancel={ this.onEditCancel }
              defaultValues={ this.dataProvider(this.props.projects)[this.state.editedIndex] }
              visibility={ this.state.editProjectVisibility }
              setData={ this.editProjectAddData }
              isArchive={true}
            />
            :
            null
          }
        </div>
    );
  }
}

PrintArchive.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (PrintArchive);
