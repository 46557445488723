import React, {Component} from 'react';
import UserManagementTable from "../../../components/UserManagementTable";
import UserManagementOptions from "../../../components/UserManagementOptions";
import NewUserModal from "../../../components/NewUserModal";
import {Card} from "antd";
import {UsersSelector} from "../../../store/internal_api/users/usersReducer";
import {GetAllUsersAction} from "../../../actions/internal_api/users/GetAllUsers";
import {connect} from "react-redux";
import EditUserModal from "../../../components/EditUserModal";
import {GetAllGroupsAction} from "../../../actions/internal_api/groups/GetAllGroups"
import {GetAllPermissionsAction} from "../../../actions/internal_api/permissions/GetAllPermissions"

function mapStateToProps(state){
    return {
        users: UsersSelector(state),
        
    }
}

function mapDispatchToProps(dispatch){
    return {
        getUsers: () => dispatch(GetAllUsersAction()),
        getGroups: () => dispatch(GetAllGroupsAction()),
        getPermissions: () => dispatch(GetAllPermissionsAction())
    }
}

class UserManagementView extends Component {
    constructor() {
        super();
        this.state = {
            newUserModalVisible: false,
            editGroupPermissionVisible: false,
            editedData: null,
        }
    }

    componentDidMount() {
        this.props.getUsers()
        this.props.getGroups();
        this.props.getPermissions();
    }

    showNewUserModal = () =>{
        this.setState({newUserModalVisible: true})
    }

    closeNewUSerModal = () =>{
        this.setState({newUserModalVisible: false})
    }

    showGroupPermissionEdit = (data) =>{
        this.setState({editGroupPermissionVisible: true, editedData: data})
    }

    closeGroupPermissionEdit = () =>{
        this.setState({editGroupPermissionVisible: false, editedData: null})
    }

  render() {
    return (
      <div>
        <Card>
          <UserManagementOptions newUserCLick={this.showNewUserModal}/>
          <UserManagementTable data={this.props.users} onChangeGroupPermissionClick={this.showGroupPermissionEdit}/>
        </Card>
          {this.state.newUserModalVisible
          ?
          <NewUserModal visibility={this.state.newUserModalVisible} onCancel={this.closeNewUSerModal}/>
          :
          null}
          {this.state.editGroupPermissionVisible
              ?
              <EditUserModal
                  visibility={this.state.editGroupPermissionVisible}
                  onCancel={this.closeGroupPermissionEdit}
                  defaultValues={this.state.editedData}/>
              :
              null
          }
      </div>
    );
  }
}

UserManagementView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (UserManagementView);
