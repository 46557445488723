import React, {useState} from 'react';
import {Card, Table} from "antd";
import * as moment from "moment";
import EditTaskModalSecond from "./EditTaskModalSecond";
import {useDispatch, useSelector} from "react-redux";
import {GetTasksOfUserAction} from "../actions/internal_api/tasks/GetTasksOfUserAction";
import {LoggedInUserSelector} from "../store/internal_api/LoginUser";

const UserTasks = ({taskData}) => {
    const [editModal, setEditModal] = useState(false)
    const [fullData, setFullData] = useState(null)

    const dispatch = useDispatch()

    const loggedInUser = useSelector(LoggedInUserSelector)

    const closeModal = () =>{
        setEditModal(()=> false)
        setFullData(()=> null)
        dispatch(GetTasksOfUserAction(loggedInUser.id))
    }

    const prepareEdit = (taskData) =>{
        setFullData(()=> taskData)
        setEditModal(true)
    }



    const columns = [
        {
            title: 'Akcje',
            render: (text, record, index) => <a href="#none" onClick={() => prepareEdit(record)}>Edytuj</a>
        },
        {
            title: 'Nazwa Projektu',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: 'Nazwa Zadania',
            dataIndex: 'taskName',
            key: 'taskName',
        },
        {
            title: 'Data zadania',
            dataIndex: 'taskDate',
            key: 'taskDate',
        },
        {
            title: 'Opis zadania',
            dataIndex: 'taskDescription',
            key: 'taskDescription',
        },
        {
            title: 'Suma Godzin',
            dataIndex: 'hoursOfWork',
            key: 'hoursOfWork',
            render: (text) => moment(text).format("HH:mm")
        },
    ];


    return (
        <Card title={"Twoje zadania"}>
            <Table size="small" dataSource={taskData} columns={columns}/>
            {editModal
                ?
                <EditTaskModalSecond visibility={editModal} onCancel={closeModal} data={fullData} taskId={fullData.id}/>
                :
                null
            }
        </Card>
    );
};

UserTasks.propTypes = {

};

export default UserTasks;
