import {GET_DAYS_OFF_SUCCESS} from "../../../consts/actions"

const initialState = {
    daysOff: []
};

export const DaysOffReducer = (state = initialState, action) =>{
    switch(action.type){
        case GET_DAYS_OFF_SUCCESS:
            state = {
                ...state,
                daysOff: action.data
            };
            return state;
        default:
            return state;
    }
};


export const DaysOffSelector = (state) => state.DaysOffReducer.daysOff;