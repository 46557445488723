import React from 'react';
import PropTypes from 'prop-types';
import {Table, Card} from "antd";
import ProjectExpandableTableElement from "../ProjectExpandableTableElement";
import '../../styles/tableStyles.css'
import {
  AccessiblePdfStatusses,
  AudiobookStatuses,
  BookDTPStatuses,
  CoverStatuses,
  EpubDTPStatuses, OtherDTPStates
} from "../../assets/mockup/data";
import { nestedTableBackgroundColors } from '../../assets/scripts/colors';
import ModalWithInputVerification from "../ModalWithInputVerification";

const ProjectTypeTable = ({deleteTask, deleteType, recordData, onAddTaskClick, projectIndex, projectId, contactPerson, fullData, refreshProjects}) => {


  const typeToNormalName = (internalType) =>{
    switch(internalType){
      case "other":
        return "Inny"
      case "book":
        return "Książka";
      case "accessiblepdf":
        return "PDF dostępny";
      case "cover":
        return "Okładka";
      case "audiobook":
        return "Audiobook";
      case "epub":
        return "EPUB";
      default:
        return "Bliżej niezidentyfikowany obiekt"
    }
  }

  const columns = [

    {
      title: "Akcje",
      render: (text, row, index) => <ModalWithInputVerification onCancel={()=> {}} blackList={["pracownik"]} onAccept={() => deleteType(row.id)} trueValue={row.config.internalType + " " + row.projectTypePhase}>
        <a href="#null">Usuń</a>
      </ModalWithInputVerification>
    },
    {
      title: "Typ",
      dataIndex: "internalType",
      key: "internalType",
      render: (text, row, index) => typeToNormalName(row.config.internalType)
    },
    {
      title: "Etap",
      dataIndex: "projectTypePhase",
      key: "projectTypePhase",
      //render: (text) => <Tag color={text.color}>{text.text}</Tag>
    },
    {
      title: "Osoba kontaktowa",
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: () => contactPerson.nameSurname
    },
    {
      title: "Ostatnie zadanie",
      dataIndex: "currentTaskExecutor",
      key: "currentTaskExecutor",
      render: (text, record, index) => {
        return record.tasks !== undefined && record.tasks.length > 0 ? record.tasks[record.tasks.length-1].taskName : "-"
      }
    },
    {
      title: "Właściciel plików",
      dataIndex: "typeWatcher",
      key: "typeWatcher",
      sorter: (a,b) => ('' + a.projectWatcher).localeCompare(b.projectWatcher),
      render: (element) => element != null ? element.name : "Nie wybrano"
    },
  ];

  const getColorOfSpecificType = (type, text) =>{
    switch(type){
      case "accessiblepdf":
        return AccessiblePdfStatusses.filter((element)=> {return element.text === text})[0].color;
      case "audiobook":
        return AudiobookStatuses.filter((element)=> {return element.text === text})[0].color;
      case "book":
        return BookDTPStatuses.filter((element)=> {return element.text === text})[0].color;
      case "cover":
        return CoverStatuses.filter((element)=> {return element.text === text})[0].color;
      case "epub":
        return EpubDTPStatuses.filter((element)=> {return element.text === text})[0].color;
      case "other":
        return OtherDTPStates.filter((element)=> {return element.text === text})[0].color;
      default:
        return "red"
    }
  };

  const paintRow = (record, index) =>{

    return `row-color-${getColorOfSpecificType(record.config.internalType, record.projectTypePhase)}`;
  };

  return (
    <Card bodyStyle={{backgroundColor: nestedTableBackgroundColors.second}}>
      <Table
        rowClassName={paintRow}
        expandable={{
          expandedRowRender: (record, index) => <ProjectExpandableTableElement
              deleteTask={deleteTask}
              refreshProjects={refreshProjects}
              typeId={record.id}
              fullData={fullData}
              typeIndex={index}
              projectId={projectId}
              projectIndex={projectIndex}
              projectTaskType={record.config.internalType}
              onAddTaskClick={onAddTaskClick}
              recordData={record.tasks}/>,
          rowExpandable: record => true,
        }}
        columns={columns}
        dataSource={recordData}
        pagination={false}
        size="small"
        rowKey="id"
      />
    </Card>
  );
};

ProjectTypeTable.propTypes = {
  recordData: PropTypes.array.isRequired
};

export default ProjectTypeTable;
