import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Row} from "antd";
import AddonBefore from "./Layout/AddonBefore";
import {RightToLeft} from "./styled/Layout";

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';

const WorkingHoursOptions = ({onNewWorkingHourClick, setFilterData, showAddVacationDay}) => {
  return (
    <Row gutter={[24,24]}>
      <Col span={8}>
        <AddonBefore additionText={"Zakres"}>
          <RangePicker
            onChange={(momentValue, stringValue)=> setFilterData(stringValue)}
            format={dateFormat}
          />
        </AddonBefore>
      </Col>
      <Col span={8}></Col>
      <Col span={8}>
        <RightToLeft>
          {showAddVacationDay 
            ?
              <Button onClick={onNewWorkingHourClick} type={"primary"}>Dodaj urlop</Button>
            :
            null
          }
        </RightToLeft>
      </Col>
    </Row>
  );
};

WorkingHoursOptions.propTypes = {
  onNewWorkingHourClick: PropTypes.func.isRequired
};

export default WorkingHoursOptions;
