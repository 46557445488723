import {GET_TASKS_OF_USER_SUCCESS} from "../../../consts/actions"

const initialState = {
    userTasks: []
};

export const UserProjectTaskReducer = (state = initialState, action) =>{
    switch(action.type){
        case GET_TASKS_OF_USER_SUCCESS:
            state = {
                ...state,
                userTasks: action.data
            };
            return state;
        default:
            return state;
    }
};


export const ProjectsDtpSelector = (state) => state.UserProjectTaskReducer.userTasks;
