import React, {Fragment} from 'react';
import {Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select} from "antd";
import DtpProjectTypeCommonData from "./DtpProjectTypeCommonData";
import AddonBefore from "../Layout/AddonBefore";
import NewProjectArchiveSection from "./NewProjectArchiveSection";
import {EpubDTPStatuses} from "../../assets/mockup/data";
import ProjectTypesStatus from "./ProjectTypesStatus";

const {Option} = Select;
const {TextArea} = Input;

const EpubDtpProjectType = ({defaultValues, form, archive}) => {


  const epubType = [
    <Option key={"reflowable"} value={"reflowable"}>reflowable</Option>,
    <Option key={"fixed"} value={"fixed"}>fixed</Option>,
    <Option key={"reflowable ze stałym layoutem"} value={"reflowable ze stałym layoutem"}>reflowable ze stałym layoutem</Option>
  ];

  const difficultyTypes= [
    <Option key={"proste"} value={"proste"}>proste</Option>,
    <Option key={"skomplikowane"} value={"skomplikowane"}>skomplikowane</Option>,
  ];

  let thisInitialValues = defaultValues !== undefined && defaultValues.length !== 0 ? defaultValues.filter((element)=>{return element.config.internalType === "epub"}) : null;

  return (
    <Fragment>
      <Divider orientation={"left"}>EPUB</Divider>
      <Form form={form}  initialValues={thisInitialValues !== null && thisInitialValues.length !== 0 ? thisInitialValues[0].config : null}>
      <DtpProjectTypeCommonData archive={archive}/>
      <NewProjectArchiveSection/>
        <ProjectTypesStatus archive={archive} typesVariants={EpubDTPStatuses}/>
      <Divider>Dane</Divider>
      <Row gutter={[24,24]}>
        <Col span={8}>
          <AddonBefore additionText={"Rodzaj:"}>
            <Form.Item name={"type"}>
              <Select
                  disabled={archive}
                allowClear
                style={{ width: '90%' }}
                placeholder="Wybierz rodzaj"
              >
                {epubType}
              </Select>
            </Form.Item>
          </AddonBefore>
        </Col>
      </Row>
      <Row gutter={[24,24]}>
        <Col span={8}>
          <AddonBefore additionText={"Stopień trudności:"}>
            <Form.Item name={"difficulty"}>
              <Select
                  disabled={archive}
                allowClear
                style={{ width: '90%' }}
                placeholder="Wybierz stopień"
              >
                {difficultyTypes}
              </Select>
            </Form.Item>
          </AddonBefore>
       </Col>
        <Col span={8}>
          <AddonBefore additionText={"Ilość elementów graficznych:"}>
            <Form.Item name={"numberOfGraphicElements"}>
              <InputNumber disabled={archive} />
            </Form.Item>
          </AddonBefore>
        </Col>
        <Col span={8}>
          <AddonBefore additionText={"Ilość stron w książce:"}>
            <Form.Item name={"numberOfPages"}>
              <InputNumber disabled={archive} />
            </Form.Item>
          </AddonBefore>
        </Col>
      </Row>
      <Row gutter={[24,24]}>
        <Col span={12}>
          <Form.Item name={"coverFormat"}>
            <Input disabled={archive} addonBefore={"Format książki:"}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"comment"}>
            <TextArea disabled={archive} placeholder={"Wpisz komentarz"} rows={4}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24,24]}>
        <Col span={8}>
          <AddonBefore additionText={"Przypisy:"}>
            <Form.Item name={"footnotes"}>
              <InputNumber  disabled={archive}/>
            </Form.Item>
          </AddonBefore>
        </Col>
        <Col span={8}>
          <Form.Item name={"isAccessibleChecked"} valuePropName="checked">
            <Checkbox disabled={archive}>Dostępność</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={"isChapterOpeningChecked"} valuePropName="checked">
            <Checkbox disabled={archive}>Chapter opening</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      </Form>
    </Fragment>
  );
};

EpubDtpProjectType.propTypes = {

};

export default EpubDtpProjectType;
