import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  GET_CLIENTS_DRUK,
  GET_CLIENTS_DRUK_SUCCESS,
  GET_CLIENTS_DRUK_FAIL,
} from "../../../consts/actions"

export function GetAllClientsDrukAction(){
  return (dispatch) =>{
    dispatch({
      type: GET_CLIENTS_DRUK
    });
    return axios({
      method: "GET",
      url: `${mockupBackend}clientdruk/all`
    }).then(response =>{
      dispatch({
        type: GET_CLIENTS_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: GET_CLIENTS_DRUK_FAIL,
        error
      })
    })
  }
}