import styled from "styled-components"

export const Err404Block = styled.div`
display:flex;
justify-content: center;
align-items: center;
flex-direction: column;
h1{
  font-size: 32px;
}
p{
  color: gray;
}

`