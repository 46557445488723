import {WS_CONNECTED, WS_DISCONNECTED} from '../consts/actions'
import SockJS from "sockjs-client"
import Stomp from "stomp-websocket"

const socketMiddleware = () =>{
  let socket = null;

  return store => next => action =>{
    switch(action.type){
      case WS_CONNECTED:
        if(socket !== null){
          socket.disconnect();
        }
        socket = Stomp.over(new SockJS(action.data))
        socket.connect({}, (frame)=>{

          socket.subscribe('/wsreciver/editeddtpproject', (message)=>{

            store.dispatch({type: "PROJECT_DTP_WAS_EDITED", data: JSON.parse(message.body)})
          })
          socket.subscribe('/wsreciver/editeddrukproject', (message)=>{

            store.dispatch({type: "PROJECT_DRUK_WAS_EDITED", data: JSON.parse(message.body)})
          })
        })

        break;
      case WS_DISCONNECTED:
        if(socket !== null){
          socket.disconnect();
        }
        socket = null;
        break;
      case 'NEW_MESSAGE':
        socket.send('/wssender/hello', {}, JSON.stringify({name: action.data}))
        break;
      default:
        return next(action)
    }
  }
}

export default socketMiddleware();
