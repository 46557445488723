import React, {Fragment} from 'react';
import {Col, Divider, Form, Input, Row} from "antd";
import DtpProjectTypeCommonData from "./DtpProjectTypeCommonData";
import NewProjectArchiveSection from "./NewProjectArchiveSection";
import ProjectTypesStatus from "./ProjectTypesStatus";
import {AudiobookStatuses} from "../../assets/mockup/data";

const {TextArea} = Input;

const AudiobookDtpProjectType = ({ defaultValues, form, archive}) => {


  let thisInitialValues = defaultValues !== undefined && defaultValues.length !== 0 ? defaultValues.filter((element)=>{return element.config.internalType === "audiobook"}) : null;
  return (
    <Fragment>
      <Divider orientation={"left"}>Audiobook</Divider>
      <Form form={form} initialValues={thisInitialValues != null && thisInitialValues.length !== 0? thisInitialValues[0].config : null}>
      <DtpProjectTypeCommonData archive={archive}/>
      <NewProjectArchiveSection/>
      <ProjectTypesStatus archive={archive} typesVariants={AudiobookStatuses}/>
      <Divider>Dane</Divider>
        <Row gutter={[24,24]}>
          <Col span={20}>
            <Form.Item name={"format"}>
              <TextArea disabled={archive} placeholder={"Wpisz format"} rows={2}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

AudiobookDtpProjectType.propTypes = {

};

export default AudiobookDtpProjectType;
