import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Modal, Row} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {useDispatch} from "react-redux";
import {GetAllClientsDtpAction} from "../actions/internal_api/clients/GetAllClientsDtpAction";
import {EditClientDtpAction} from "../actions/internal_api/clients/EditClientDtpAction";
import {GetAllClientsDrukAction} from "../actions/internal_api/clients/GetAllClientsDrukAction";
import {EditClientDrukAction} from "../actions/internal_api/clients/EditClientDrukAction";
import {ScrollableY} from "./styled/Layout";
import PrintNewContactPersonSection from "./PrintNewContactPersonSection";

const {TextArea} = Input

const PrintEditClientModal = ({visibility, onCancel, clientType, defaultValues, companySection}) => {

  const [clientForm] = Form.useForm();

  const dispatch = useDispatch();


  const tempOnOk = () =>{
    Promise.all([clientForm.validateFields()]).then((values)=>{

      if(companySection === "dtp"){
        dispatch(EditClientDtpAction(defaultValues.id, values[0])).then(()=>{
          dispatch(GetAllClientsDtpAction());
          onCancel()
        })
      }
      if(companySection === "druk" ){
        dispatch(EditClientDrukAction(defaultValues.id, values[0])).then(()=>{
          dispatch(GetAllClientsDrukAction());
          onCancel()
        })
      }
    })

  };
  return (
    <Modal
      title={"Edytuj klienta"}
      visible={visibility}
      maskClosable={false}
      onCancel={onCancel}
      width={"90%"}
      footer={[
        <Button key="back" onClick={onCancel}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={tempOnOk}>
          Zapisz
        </Button>,
      ]}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={clientForm} initialValues={defaultValues}>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Nazwa klienta
            </Col>
            <Col span={15}>
              <Form.Item name={"clientName"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Państwo
            </Col>
            <Col span={15}>
              <Form.Item name={"country"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              NIP
            </Col>
            <Col span={15}>
              <Form.Item name={"nip"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Kod pocztowy
            </Col>
            <Col span={15}>
              <Form.Item name={"postCode"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Miasto
            </Col>
            <Col span={15}>
              <Form.Item name={"city"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Adres
            </Col>
            <Col span={15}>
              <Form.Item name={"address"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Telefon
            </Col>
            <Col span={15}>
              <Form.Item name={"phoneNumber"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Email
            </Col>
            <Col span={15}>
              <Form.Item name={"email"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Nazwa do faktury
            </Col>
            <Col span={15}>
              <Form.Item name={"invoiceName"}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={6}>
              Komentarz
            </Col>
            <Col span={15}>
              <Form.Item name={"comment"}>
                <TextArea/>
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="contactPersons">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <PrintNewContactPersonSection key={field.key} remove={remove} field={field} values={defaultValues} companySection={companySection} isEditing/>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Dodaj osobę kontaktową
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </ScrollableY>
    </Modal>
  )
};

PrintEditClientModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PrintEditClientModal;
