
//export const mockupBackend = "http://localhost:8080/";
//export const mockupBackend = "http://46.101.199.155:8080/"; // TEST
//export const mockupBackend = "http://165.227.147.209:8080/";

//export const excelBackend = "http://localhost:8090/"
//export const excelBackend = "http://46.101.199.155:8090/" // TEST
//export const excelBackend = "http://165.227.147.209:8090/"

//export const mockupBackend = "http://api.haveabook.eu:9000/";
//export const excelBackend = "http://api.haveabook.eu:9000/"

export const mockupBackend = "https://api.haveabook.eu/";//PROD
export const excelBackend = "https://api.haveabook.eu/"//PROD