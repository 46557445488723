import styled from 'styled-components'


export const Text = styled.span`
color: ${props => props.color ? props.color : "black"};
`;

export const AdditionText = styled.span`
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    white-space: nowrap;
    line-height: 30px;
    height:100%;
    
`;

export const AdditionTextBox = styled.span`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-flex;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
`