import React from 'react';
import {RightToLeft} from "./styled/Layout";
import {Button, Col, Row} from "antd";
import {Text} from "./styled/Text";
import TodayHoursCounterDtp from "./TodayHoursCounterDtp";

const HeaderPanel = ({userName, onLogoutClick}) => {


  return (
    <Row>
        <Col span={12}>
            <TodayHoursCounterDtp/>
        </Col>
        <Col span={12}>
            <RightToLeft>
              <Button type="primary" shape="round" onClick={onLogoutClick}>
                Wyloguj się
              </Button>
              <Text style={{padding: 5}} color={"white"}>{userName}</Text>
            </RightToLeft>
        </Col>

    </Row>
  );
};

HeaderPanel.propTypes = {

};

export default HeaderPanel;
