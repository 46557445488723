import axios from "axios"
import {NEW_PROJECT_DRUK, NEW_PROJECT_DRUK_SUCCESS, NEW_PROJECT_DRUK_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function AddNewProjectDrukAction(projectData){
  return (dispatch) =>{
    dispatch({
      type: NEW_PROJECT_DRUK
    });
    return axios({
      method: "PUT",
      data: projectData,
      url: `${mockupBackend}projectdruk/new`
    }).then(response =>{
      dispatch({
        type: NEW_PROJECT_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: NEW_PROJECT_DRUK_FAIL,
        error
      })
    })
  }
}