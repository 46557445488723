import {SET_EXTEND_ALL_PROJECTS} from "../../consts/actions";


export function SetProjectViewDataExpandRowsAction(){
  return (dispatch) => {
    dispatch({
      type: SET_EXTEND_ALL_PROJECTS
    });
  }
}
