

export const getTableFilterDataFromStatusObject = (data, dataKey) =>{
  let filterData = [];

  if (data !== undefined || data !== null) {
    data.forEach((element) => {
      filterData.push({
        text: element[dataKey],
        value: element[dataKey]
      })
    });
  }
  return getUniqueListBy(filterData, 'text')
 //return[]
};

export const getTableFilterData = (data, dataKey) =>{
  let filterData = [];
  if (data !== undefined || data !== null) {
    data.forEach((element) => {
      filterData.push({
        text: element[dataKey] === "" ? "Nie wprowadzono" : element[dataKey],
        value: element[dataKey]
      })
    });
  }
  return getUniqueListBy(filterData, 'text')
  //return[]
};

export const getTableFilterDataClient = (data, dataKey) =>{
  let filterData = [];
  if (data !== undefined || data !== null) {
    data.forEach((element) => {
      filterData.push({
        text: element[dataKey] === "" ? "Nie wprowadzono" : element[dataKey].clientName,
        value: element[dataKey].clientName
      })
    });
  }
  return getUniqueListBy(filterData, 'text')
  //return[]
};

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}