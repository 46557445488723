import axios from "axios";
import {NEW_USER, NEW_USER_FAIL, NEW_USER_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";



export function AddNewUserAction(userData){
    return (dispatch) =>{
        dispatch({
            type: NEW_USER
        });
        return axios({
            method: 'put',
            url: `${mockupBackend}user/new`,
            data: userData,
        }).then(response =>{
            dispatch({
                type: NEW_USER_SUCCESS,
                data: response.data
            })
        }).catch(error =>{
            dispatch({
                type: NEW_USER_FAIL,
                error
            })
        })
    }
}