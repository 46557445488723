import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  DISABLE_PRINTINGHOUSE,
  DISABLE_PRINTINGHOUSE_SUCCESS,
  DISABLE_PRINTINGHOUSE_FAIL,
} from "../../../consts/actions"

export function DisablePrintingHouseAction(printingHouseId){
  return (dispatch) =>{
    dispatch({
      type: DISABLE_PRINTINGHOUSE
    });
    return axios({
      method: "POST",
      url: `${mockupBackend}printinghouse/disable/${printingHouseId}`
    }).then(response =>{
      dispatch({
        type: DISABLE_PRINTINGHOUSE_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: DISABLE_PRINTINGHOUSE_FAIL,
        error
      })
    })
  }
}