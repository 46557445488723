import axios from "axios"
import {GET_TASKS_OF_USER, GET_TASKS_OF_USER_SUCCESS, GET_TASKS_OF_USER_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function GetTasksOfUserAction(userId){
    return (dispatch) =>{
        dispatch({
            type: GET_TASKS_OF_USER
        });
        return axios({
            method: "GET",
            url: `${mockupBackend}tasks/user/${userId}`
        }).then(response =>{
            dispatch({
                type: GET_TASKS_OF_USER_SUCCESS,
                data: response.data,
            })
        }).catch(error =>{
            dispatch({
                type: GET_TASKS_OF_USER_FAIL,
                error
            })
        })
    }
}