import {SET_EXTEND_ALL_PROJECTS, SET_EXPANDED_PROJECTS_IDS} from "../../consts/actions";

const initialValues = {
  expandRows: false,
  expandedIds: []
}

export const ProjectViewDataReducer = (state = initialValues, action) =>{
  switch (action.type){
    case SET_EXTEND_ALL_PROJECTS:
      state = {
        ...state,
        expandRows: !state.expandRows
      };
      return state;
    case SET_EXPANDED_PROJECTS_IDS:
      state = {
        ...state,
        expandedIds: action.data
      }
      return state;
    default:
      return state;
  }
}


export const ProjectViewDataExpandRowsSelector = (state) => state.ProjectViewDataReducer.expandRows;
export const ProjectViewDataExpandedIds = (state) => state.ProjectViewDataReducer.expandedIds;
