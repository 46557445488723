import React from 'react';
import PropTypes from 'prop-types';
import {Card, Descriptions} from "antd";

const UserInfo = ({userData}) => {

  return (
    <Card>
    <Descriptions title={"Informacje o uzytkowniku"}>
      <Descriptions.Item label={"Imię i nazwisko"}>{userData.name}</Descriptions.Item>
      <Descriptions.Item label={"Email"}>{userData.email}</Descriptions.Item>
      <Descriptions.Item label={"Grupa"}>{userData.group.name}</Descriptions.Item>
      <Descriptions.Item label={"Grupy uprawnień"}>{userData.permission.name}</Descriptions.Item>
      <Descriptions.Item label={"Pozostała ilość dni urlopowych w tym roku"}>{userData.freeHoursInYear}</Descriptions.Item>
      <Descriptions.Item label={"Pozostała ilość dni urlopowych w poprzednim roku"}>{userData.freeHoursInLastYear}</Descriptions.Item>
    </Descriptions>
    </Card>
  );
};

UserInfo.propTypes = {
  userData: PropTypes.object.isRequired
};

export default UserInfo;
