import React, {Fragment} from 'react';
import {Checkbox, Col, Divider, InputNumber, Input, Row, Select, Form} from "antd";
import DtpProjectTypeCommonData from "./DtpProjectTypeCommonData";
import AddonBefore from "../Layout/AddonBefore";
import NewProjectArchiveSection from "./NewProjectArchiveSection";
import {CoverStatuses} from "../../assets/mockup/data";
import ProjectTypesStatus from "./ProjectTypesStatus";

const {Option} = Select;
const {TextArea} = Input

const CoverProjectType = ({ defaultValues, form, archive}) => {



  const coverType= [
    <Option key={"softcover"} value={"softcover"}>softcover</Option>,
    <Option key={"hardcover"} value={"hardcover"}>hardcover</Option>
  ];



  let thisInitialValues = defaultValues !== undefined && defaultValues.length !== 0 ? defaultValues.filter((element)=>{return element.config.internalType === "cover"}) : null;
  return (
    <Fragment>
      <Divider orientation={"left"}>Okładka</Divider>
      <Form form={form}  initialValues={thisInitialValues !== null && thisInitialValues.length !== 0 ? thisInitialValues[0].config : null} >
      <DtpProjectTypeCommonData archive={archive}/>
      <NewProjectArchiveSection/>
        <ProjectTypesStatus archive={archive} typesVariants={CoverStatuses}/>
      <Divider>Dane</Divider>
        <Row gutter={[24,24]}>
          <Col span={12}>
            <AddonBefore additionText={"Kolor:"}>
              <Form.Item noStyle name={"color"}>
                <Input disabled={archive}/>
              </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={12}>
            <AddonBefore additionText={"Typ okładki:"}>
              <Form.Item noStyle name={"coverType"}>
                <Select
                    disabled={archive}
                  allowClear
                  style={{ width: '90%' }}
                  placeholder="Wybierz typ okładki"
                >
                  {coverType}
                </Select>
              </Form.Item>
            </AddonBefore>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={8}>
            <Form.Item name={"isWrapperChecked"} valuePropName="checked">
              <Checkbox disabled={archive} >Obwoluta</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={"isEmbossingChecked"} valuePropName="checked">
              <Checkbox disabled={archive} >Wytłoczenia</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={15}>
            <Form.Item name={"wings"}>
              <Input disabled={archive} addonBefore={"Skrzydełka"} placeholder={"Podaj rozmiary"}/>
            </Form.Item>
            
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item name={"comment"}>
              <TextArea disabled={archive} placeholder={"Wpisz komentarz do typu zadania"} rows={4}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={10}>
            <Form.Item name={"coverFormat"}>
              <Input disabled={archive} addonBefore={"Format okładki:"}/>
            </Form.Item>
          </Col>
          <Col span={8}>
          <AddonBefore additionText="Zakupione grafiki:">
            <Form.Item name={"purchasedGraphics"}>
              <InputNumber disabled={archive}/>
            </Form.Item>
            </AddonBefore>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={24}>
            <Form.Item name={"copyrights"}>
              <TextArea disabled={archive} placeholder={"Copyrights"} rows={2}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

CoverProjectType.propTypes = {
};

export default CoverProjectType;
