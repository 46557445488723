import {GET_ALL_PROJECTS_DRUK_SUCCESS} from "../../../consts/actions"
import {toast} from 'react-toastify'


const initialState = {
  projects: []
};

export const ProjectsDrukReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_ALL_PROJECTS_DRUK_SUCCESS:
      state = {
        ...state,
        projects: action.data
      };
      return state;
    case "PROJECT_DRUK_WAS_EDITED":
      return onProjectDrukWasEdited(state, action)
    default:
      return state;
  }
};


export const ProjectsDrukSelector = (state) => state.ProjectsDrukReducer.projects;


//If project exist in store, update it
function onProjectDrukWasEdited(state, action){
  //check if project exist in current project store
  let projectIndexInStore = state.projects.findIndex((project) => project.id === action.data.id)
  if (projectIndexInStore !== -1){
    //if exist get tasks from it and ascribe to new data
    let newProjectData = action.data
    for (let typeIndex = 0; typeIndex < newProjectData.types.length; typeIndex++){
      newProjectData.types[typeIndex].tasks = state.projects[projectIndexInStore].types[typeIndex].tasks
    }

    //set value of project to new project

    let newProjects = [...state.projects]
    newProjects[projectIndexInStore] = newProjectData
    state = {
      ...state,
      projects: newProjects
    }
    //show notification
    toast(`Projekt "${newProjectData.projectName}" został zaktualizowany`)
  }
  // if not, do nothing
  return state;
}
