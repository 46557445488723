import React, {Fragment} from 'react';
import {Checkbox, Col, Divider, Form, Input, Row} from "antd";

const NewProjectArchiveSection = ({defaultValues}) => {
  return (
      <Fragment>
        <Divider>Archiwum</Divider>
        <Row gutter={[24,24]}>
          <Col span={6}>
            <Form.Item name={"isArchived"} initialValue={defaultValues !== undefined?defaultValues[0].config.isArchived: null} valuePropName="checked">
              <Checkbox>Projekt zakończony</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"pathToArchivedFiles"} initialValue={defaultValues !== undefined?defaultValues[0].config.pathToArchivedFiles: null}>
              <Input addonBefore={"Ścieżka do plików"}/>
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
  );
};

NewProjectArchiveSection.propTypes = {

};

export default NewProjectArchiveSection;
