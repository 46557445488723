import {combineReducers} from 'redux';
import {LoggedInUserReducer} from "./internal_api/LoginUser";
import {ProjectsDtpReducer} from "./internal_api/Projects/ProjectsDtpReducer";
import {ProjectsDrukReducer} from "./internal_api/Projects/ProjectsDrukReducer"
import {ClientsDtpReducer} from "./internal_api/clients/clientsDtpReducer";
import {ClientsDrukReducer} from "./internal_api/clients/clientsDrukReducer";
import {PrintinghouseReducer} from "./internal_api/printingHouses/printingHouseReducer";
import {DaysOffPlanReducer} from "./internal_api/daysOffPlan/DaysOffPlanReducer";
import {DaysOffReducer} from "./internal_api/daysOff/DaysOffReducer";
import {UsersReducer} from "./internal_api/users/usersReducer";
import {GroupsReducer} from './internal_api/groups/groupsReducer'
import {PermissionsReducer} from "./internal_api/permissions/permissionsReducer"
import {UserProjectTaskReducer} from "./internal_api/Tasks/UserProjectTasksReducer";
import {ProjectInternalIdIndividualityCheckReducer} from "./internal_api/Projects/ProjectInternalIdIndividualityCheckReducer";
import {ArchiveProjectsDtpReducer} from "./internal_api/Projects/ProjectsArchiveDtpReducer";
import {AllProjectsDtpReducer} from "./internal_api/Projects/AllDtpProjectsReducer";
import {ProjectViewDataReducer} from "./local/ProjectViewDataReducer";

export default combineReducers({
 LoggedInUserReducer,
 ProjectsDtpReducer,
 ProjectsDrukReducer,
 ClientsDtpReducer,
 ClientsDrukReducer,
 PrintinghouseReducer,
 DaysOffPlanReducer,
 DaysOffReducer,
 UsersReducer,
 GroupsReducer,
 PermissionsReducer,
 UserProjectTaskReducer,
 //websocketReducer,
 ProjectInternalIdIndividualityCheckReducer,
 ArchiveProjectsDtpReducer,
 AllProjectsDtpReducer,
 //local
 ProjectViewDataReducer
})
