import React, {Component} from 'react';
import PrintingHouseTable from "../../../components/PrintingHouseTable";
import {Card} from "antd";
import PrintingHouseOptions from "../../../components/PrintingHouseOptions";
import NewPrintingHouseModal from "../../../components/NewPrintingHouseModal";
import {PrintinghouseSelector} from "../../../store/internal_api/printingHouses/printingHouseReducer";
import {GetAllPrintingHouseAction} from "../../../actions/internal_api/printingHouse/GetAllPrintingHouseAction";
import {connect} from "react-redux";
import EditPrintingHouseModal from "../../../components/EditPrintingHouseModal";

function mapStateToProps(state){
  return{
    printingHouses: PrintinghouseSelector(state),
  }
}

function mapDispatchToProps(dispatch){
  return{
    getPrintingHouses: () => dispatch(GetAllPrintingHouseAction())
  }
}

class PrintingHouseView extends Component {
  constructor() {
    super();
    this.state = {
      newPrintingHouseVisibility: false,
      editPrintingHouseVisibility: false,
      editedIndex: -1,
      nameSearchValue: ""
    }
  }

  componentDidMount() {
    this.props.getPrintingHouses()
  }

  changeProjectVisibility = (value) =>{
    this.setState({newPrintingHouseVisibility: value})
  };

  onSearchChange = (event) =>{
    this.setState({nameSearchValue: event.target.value})
  }

  onClick = () =>{
    alert("edit")
  };

  onEditClick = (index) =>{
    this.setState({editPrintingHouseVisibility: true, editedIndex: index})
  }

  onEditClose = () =>{
    this.setState({editPrintingHouseVisibility: false, editedIndex: -1})
  }

  filterDisabledPrintingHouses = (printingHouses) =>{
    return printingHouses.filter((element)=> element.active === true)
  }

  prepareData = (printingHouses) =>{
    printingHouses = this.filterDisabledPrintingHouses(printingHouses);

    if(this.state.nameSearchValue !== "" || this.state.nameSearchValue !== undefined){
      printingHouses = printingHouses.filter( element => element.name.includes(this.state.nameSearchValue))
    }

    return printingHouses;
  }

  render() {
    return (
      <div>
        <Card>
          <PrintingHouseOptions onNewPrintingHouseClick={this.changeProjectVisibility} onFilterChange={this.onSearchChange}/>
          <PrintingHouseTable data={this.prepareData(this.props.printingHouses)} editRow={this.onEditClick}/>
        </Card>
        { this.state.newPrintingHouseVisibility
          ?
          <NewPrintingHouseModal
            onOk={ () => this.changeProjectVisibility(false) }
            visibility={ this.state.newPrintingHouseVisibility }
            onCancel={ () => this.changeProjectVisibility(false) }
          />
          :
          null
        }
        { this.state.editPrintingHouseVisibility
          ?
          <EditPrintingHouseModal
            visibility={ this.state.editPrintingHouseVisibility }
            onCancel={ this.onEditClose }
            defaultData={ this.prepareData(this.props.printingHouses)[this.state.editedIndex] }
          />
          :
          null
        }
      </div>
    );
  }
}

PrintingHouseView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (PrintingHouseView);
