import React from 'react';
import {Button, Col, Form, Row} from "antd";
import PrintOfferForm from "./PrintOfferForm";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";

const PrintProjectOffersView = ({isArchive, valuations, onPrintingHouseChange}) => {



  const modifyDefaultValuationStructure = (val) =>{
    let newVal = []
    for (let i=0; i< val.length; i++){
      let tmpVal = val[i]
      tmpVal = {
        ...tmpVal,
        printingHouse: tmpVal.printingHouse.id
      }
      newVal.push(tmpVal);
    }

    return newVal
  }


  return (
    <div>
      <Row gutter={[24,24]}>
        <Col span={2}>
          Nakład
        </Col>
        <Col span={3}>
          Waluta wyceny
        </Col>
        <Col span={3}>
          Cena sztuka DRUKARNIA
        </Col>
        <Col span={3}>
          Cena sztuka Klient
        </Col>
        <Col span={2}>
          Transport wartość
        </Col>
        <Col span={2}>
          Inne koszty wartości
        </Col>
        <Col span={2}>
          Wycena do zamówienia
        </Col>
        <Col span={5}>
          Drukarnia
        </Col>
        <Col span={2}>
          Usuń pozycję
        </Col>
      </Row>

      <Form.List name="valuations" initialValue={valuations !=null ? modifyDefaultValuationStructure(valuations) : null}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <PrintOfferForm isArchive={isArchive} valuations={valuations} field={field} onDeleteClick={remove} onPrintingHouseChange={onPrintingHouseChange}/>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Dodaj
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>


    </div>
  );
}

PrintProjectOffersView.propTypes = {

};

export default PrintProjectOffersView;

