import React, {Component} from 'react';
import {Card} from "antd";
import PrintProjectsSettings from "../../../components/PrintProjectsSettings";
import PrintProjectTable from "../../../components/PrintProjectTable";
import PrintNewProjectModal from "../../../components/PrintNewProjectModal";
import PrintEditProjectModal from "../../../components/PrintEditProjectModal";
import {ProjectsDrukSelector} from "../../../store/internal_api/Projects/ProjectsDrukReducer";
import {GetAllProjectsDrukAction} from "../../../actions/internal_api/Projects/GetAllProjectDrukAction";
import {AddNewProjectDrukAction} from "../../../actions/internal_api/Projects/AddNewProjectDrukAction";
import {connect} from "react-redux";
import {ModifyProjectDrukAction} from "../../../actions/internal_api/Projects/ModifyProjectDrukAction";
import {GetAllClientsDrukAction} from "../../../actions/internal_api/clients/GetAllClientsDrukAction"
import {GetAllPrintingHouseAction} from "../../../actions/internal_api/printingHouse/GetAllPrintingHouseAction"
import {LoggedInUserSelector} from "../../../store/internal_api/LoginUser";


function mapStateToProps(state){
  return({
    projects: ProjectsDrukSelector(state),
    loggedInUser: LoggedInUserSelector(state)
  })
}

function mapDispatchToProps(dispatch){
  return ({
    getProjects: () => dispatch(GetAllProjectsDrukAction()),
    saveNewProject: (projectData) => dispatch(AddNewProjectDrukAction(projectData)),
    editProject: (internalId, projectData) => dispatch(ModifyProjectDrukAction(internalId, projectData)),
    getClients: () => dispatch(GetAllClientsDrukAction()),
    getPrintingHouses: () => dispatch(GetAllPrintingHouseAction())
  })
}


class PrintProjectView extends Component {
    constructor() {
      super();
      this.state = {
        newProjectVisibility: false,
        editProjectVisibility: false,
        filterInput: "",
        newProjectData: null,
        typesData: {
            printBook: null,
            printOther: null,
        },
        editProjectData: null,
        editTypesData:{
          printBook: null,
          printOther: null,
        },
        editedIndex: 0,
        monoColorFilterOption: 0,

      }
    }

  componentDidMount() {
    this.props.getProjects();
    this.props.getClients();
    this.props.getPrintingHouses();
  }

  loggedInUserIsNotWorker = () =>{
    return this.props.loggedInUser.user.permission.name.toLowerCase() !== "pracownik"
  }

  setMOnoColorFilterType = (value) =>{
      this.setState({monoColorFilterOption: value})
  }


  /**
   * Zmienia stan wyświetlania modala nowego projektu na bazie value
   * @param value
   */
  changeProjectVisibility = (value) =>{
      this.setState({newProjectVisibility: value, newProjectData: {}})
    };
  /**
   * Przechwytuje ogólne dane projektu
   * @param data
   */
  newProjectAddData = (data) =>{
    this.props.saveNewProject(data).then(()=>{
      this.props.getProjects();
    });
    this.setState({newProjectVisibility: false, editProjectData: null});
  };

  editProjectAddData = (data) =>{
    this.props.editProject(data.id, data).then(()=>{
      this.props.getProjects();
    });
    this.setState({editProjectVisibility: false, editProjectData: null});
  };


  /**
   * Funkcja wykonuje się na anulowanie nowego projektu
   */
  handleNewProjectCancel = () =>{
        this.setState({newProjectVisibility: false});
  };

  /**
   * Funkcja wykonuje się kiedy podczas edycji zostanie kliknięty przycisk cancel
   */
  onEditCancel = () =>{
    this.setState({editProjectVisibility: false, editedIndex: 0})
  };


  /**
   * funkcja wykonuje się po kliknięciu edycji danego projektu
   * @param index
   */
  editProject = (id) =>{
    this.setState(
      {
        editedIndex: this.dataProvider(this.props.projects).findIndex(element => element.id === id),
        editProjectData:this.dataProvider(this.props.projects)[this.state.editedIndex],
        editProjectVisibility: true})
  };

  /**
   * funkcja filtruje projekty pod kątem wartośc z filtra tekstowego
   * @param unfilteredData
   * @returns {*}
   */
  dataProvider = (unfilteredData) =>{

    unfilteredData = unfilteredData.filter(element => {
      let notArchived = false
      for (let i = 0; i < element.types.length; i++){
        if(element.types[i].isArchived === false){
          notArchived = true;
        }
      }
      return notArchived
    })

    let {filterInput} = this.state;
    let filtered;
    unfilteredData = this.monoColorFilter(unfilteredData);
    if (filterInput !== ""){
      filtered = unfilteredData.filter((data) =>{return data.projectName.toLowerCase().includes(filterInput.toLowerCase())});
      return filtered
    }
    else{
      return unfilteredData
    }
  };

  monoColorFilter = (data) =>{
    switch(this.state.monoColorFilterOption) {
      case 0:
        return data;
      case 1:
        let newDataMono = data.filter((element) => element.isPrintBookEnabled === true);
        newDataMono = newDataMono.filter((element) => element.types.some((type) => type.config.monoNumberOf || type.config.monoPaper || type.config.monoOverprint || type.config.monoFinish || type.config.monoDescription));
        return newDataMono;
      case 2:
        let newDataColor = data.filter((element) => element.isPrintBookEnabled === true);
        newDataColor = newDataColor.filter((element) => element.types.some((type) => type.config.colorNumberOf || type.config.colorPaper || type.config.colorOverprint || type.config.colorFinish || type.config.colorDescription));
        return newDataColor;
      default:
        return data;
    }
  }

  onFilterChange = (e) =>{
    const {value} = e.target;
    this.setState({filterInput: value})
  };

    render() {
        return (
            <div>
                {/*<Card size={"small"} style={{ width: 400 }}>
                    <ExchangeRatesView exchangeData={ExchangeData}/>
        </Card>*/}
                <Card>
                    <PrintProjectsSettings
                      onFilterChange={this.onFilterChange}
                      getMonoColorValue={this.setMOnoColorFilterType}
                      onNewProjectClick={()=>{this.changeProjectVisibility(true)}}
                      userIsNotWorker={this.loggedInUserIsNotWorker()}/>
                    <PrintProjectTable data={this.dataProvider(this.props.projects)} editActions={{onClick: this.editProject}}/>
                </Card>
              {this.state.newProjectVisibility
                ?
                  <PrintNewProjectModal
                    visibility={ this.state.newProjectVisibility }
                    onCancel={ this.handleNewProjectCancel }
                    setData={ this.newProjectAddData }
                  />
                :
                null
              }
              { this.state.editProjectVisibility
                ?
                  <PrintEditProjectModal
                    onCancel={ this.onEditCancel }
                    defaultValues={ this.dataProvider(this.props.projects)[this.state.editedIndex] }
                    visibility={ this.state.editProjectVisibility }
                    //onOk={ this.onEditOk }
                    setData={ this.editProjectAddData }
                    hookTypesData={ this.editHookTypesData }/>
                :
                null
              }
            </div>
        );
    }
}

PrintProjectView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (PrintProjectView);
