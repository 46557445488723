import React from 'react';
import {Button, Col, Input, Row} from "antd";
import {RightToLeft} from "./styled/Layout";

const PrintingHouseOptions = ({onNewPrintingHouseClick, onFilterChange}) => {
  return (
    <Row gutter={[24,24]}>
      <Col span={8}>

      </Col>
      <Col span={8}>
        <Input
          onChange={onFilterChange}
          placeholder={"Wpisz nazwę drukarni aby ją wyszukać"}
        />
      </Col>
      <Col span={8}>
        <RightToLeft>
          <Button type={"primary"} onClick={onNewPrintingHouseClick}>Nowa Drukarnia</Button>
        </RightToLeft>
      </Col>
    </Row>
  );
};

PrintingHouseOptions.propTypes = {

};

export default PrintingHouseOptions;
