

export const AudiobookStatuses = [
  {
    text: "Brak plików",
    color: "red"
  },
  {
    text: "Layout – realizacja",
    color: "red"
  },
  {
    text: "Layout wysłany do zatwierdzenia",
    color: "orange"
  },
  {
    text: "Kodowanie – realizacja",
    color: "red"
  },
  {
    text: "Skład – realizacja",
    color: "red"
  },
  {
    text: "K1 – realizacja",
    color: "red"
  },
  {
    text: "K2 – realizacja",
    color: "red"
  },
  {
    text: "K3 – realizacja",
    color: "red"
  },
  {
    text: "K – realizacja",
    color: "red"
  },
  {
    text: "Wysłane do K1",
    color: "orange"
  },
  {
    text: "Wysłane do K2",
    color: "orange"
  },
  {
    text: "Wysłane do K3",
    color: "orange"
  },
  {
    text: "Wysłane do K4",
    color: "orange"
  },
  {
    text: "Wysłane do K5",
    color: "orange"
  },
  {
    text: "Wysłane do druku",
    color: "green"
  },
  {
    text: "Projekt zafakturowany",
    color: "green"
  },
  {
    text: "Anulowany",
    color: "green"
  },
];

export const BookDTPStatuses = [
  {
    text: "Brak plików",
    color: "red"
  },
  {
    text: "Layout – realizacja",
    color: "red"
  },
  {
    text: "Layout wysłany do zatwierdzenia",
    color: "orange"
  },
  {
    text: "Kodowanie – realizacja",
    color: "red"
  },
  {
    text: "Skład – realizacja",
    color: "red"
  },
  {
    text: "K1 – realizacja",
    color: "red"
  },
  {
    text: "K2 – realizacja",
    color: "red"
  },
  {
    text: "K3 – realizacja",
    color: "red"
  },
  {
    text: "K – realizacja",
    color: "red"
  },
  {
    text: "Wysłane do K1",
    color: "orange"
  },
  {
    text: "Wysłane do K2",
    color: "orange"
  },
  {
    text: "Wysłane do K3",
    color: "orange"
  },
  {
    text: "Wysłane do K4",
    color: "orange"
  },
  {
    text: "Wysłane do K5",
    color: "orange"
  },
  {
    text: "Zatwierdzone do druku",
    color: "green"
  },
  {
    text: "Wysłane do druku",
    color: "green"
  },
  {
    text: "Projekt zafakturowany",
    color: "green"
  },
  {
    text: "Anulowany",
    color: "green"
  },
];

export const CoverStatuses = [
  {
    text: "Brak plików",
    color: "red"
  },
  {
    text: "Layout – realizacja",
    color: "red"
  },
  {
    text: "Layout wysłany do zatwierdzenia",
    color: "orange"
  },
  {
    text: "Kodowanie – realizacja",
    color: "red"
  },
  {
    text: "Skład – realizacja",
    color: "red"
  },
  {
    text: "K1 – realizacja",
    color: "red"
  },
  {
    text: "K2 – realizacja",
    color: "red"
  },
  {
    text: "K3 – realizacja",
    color: "red"
  },
  {
    text: "K – realizacja",
    color: "red"
  },
  {
    text: "Wysłane do K1",
    color: "orange"
  },
  {
    text: "Wysłane do K2",
    color: "orange"
  },
  {
    text: "Wysłane do K3",
    color: "orange"
  },
  {
    text: "Wysłane do K4",
    color: "orange"
  },
  {
    text: "Wysłane do K5",
    color: "orange"
  },
  {
    text: "Zatwierdzone do druku",
    color: "green"
  },
  {
    text: "Wysłane do druku",
    color: "green"
  },
  {
    text: "Projekt zafakturowany",
    color: "green"
  },
  {
    text: "Anulowany",
    color: "green"
  },
];

export const EpubDTPStatuses = [
  {
    text: "Brak plików",
    color: "red"
  },
  {
    text: "ePub – realizacja",
    color: "red"
  },
  {
    text: "ePub wysłany do zatwierdzenia",
    color: "green"
  },
  {
    text: "K – realizacja",
    color: "red"
  },
  {
    text: "Projekt zafakturowany",
    color: "green"
  },
  {
    text: "Anulowany",
    color: "green"
  },
];

export const OtherDTPStates = [
  {
    text: "Brak plików",
    color: "red"
  },
  {
    text: "Realizacja",
    color: "red"
  },
  {
    text: "Wysłane do zatwierdzenia",
    color: "orange"
  },
  {
    text: "Korekta – realizacja",
    color: "red"
  },
  {
    text: "Wysłane do druku",
    color: "green"
  },
  {
    text: "Projekt zafakturowany",
    color: "green"
  },
  {
    text: "Anulowany",
    color: "green"
  },
];

export const PrintStatuses = [];

export const AccessiblePdfStatusses = [
  {
    text: "Brak plików",
    color: "red"
  },
  {
    text: "Etap 1 i 2",
    color: "red"
  },
  {
    text: "Etap 3",
    color: "red"
  },
  {
    text: "Etap 4",
    color: "red"
  },
  {
    text: "5",
    color: "red"
  },
  {
    text: "6",
    color: "red"
  },
  {
    text: "Wysłany do klienta",
    color: "green"
  },
  {
    text: "Projekt zafakturowany",
    color: "green"
  },
  {
    text: "Anulowany",
    color: "green"
  },
];

export const WorkersData = [
  {
    name: "Mateusz Dohojda",
    group: "IT",
    login: "mdohojda",
    email: "mdohojda@haveabook.pl",
    permissions: "Koordynator",
    isActive: true,
    freeHoursInYear: 0,
    freeHoursInLastYear: 0
  },
  {
    name: "Tomasz Mnich",
    group: "IT",
    login: "mdohojda",
    email: "mdohojda@haveabook.pl",
    permissions: "Koordynator",
    isActive: true,
    freeHoursInYear: 27,
    freeHoursInLastYear: 4
  },
  {
    name: "aaa",
    group: "Druk",
    login: "mdohojda",
    email: "mdohojda@haveabook.pl",
    permissions: "Koordynator",
    isActive: true,
    freeHoursInYear: 21,
    freeHoursInLastYear: 0
  },
  {
    name: "bbb",
    group: "DTP",
    login: "mdohojda",
    email: "mdohojda@haveabook.pl",
    permissions: "Koordynator",
    isActive: true,
    freeHoursInYear: 27,
    freeHoursInLastYear: 0
  }
]


export const ExchangeData = [
  {
    currency: "EUR",
    rate: "4,4729",
    date: "18-11-2020"
  },
  {
    currency: "NOK",
    rate: "0,4170",
    date: "18-11-2020"
  }
];

export const UserData = {
  name: "Mateusz Dohojda",
  id: 123322,
  email: "mateusz.dohojda@haveabook.pl",
  projects: [
    {
      id: 74478346,
      name: "CRM",
      client:{
        name: "Have a Book"
      },
      tasks:[
        {
          id: 84368476,
          name: "CRM - backend",
        },
        {
          id: 7438462,
          name: "CRM - frontend"
        }
      ]
    }
  ]
};

export const TaskData = [
  {
    taskName: "CRM - widok zadań",
    taskDate: "17.11.2020",
    taskDescription: "Ladny opis zadania, taki trochę jak lorem ipsum, ale nie koniecznie",
    hoursInTask: "8,5",
    executor: "Sławek Malitka"

  },
  {
    taskName: "CRM - widok zadań",
    taskDate: "17.11.2020",
    taskDescription: "Ladny opis zadania, taki trochę jak lorem ipsum, ale nie koniecznie",
    hoursInTask: "8,5",
    executor: "Sławek Malitka"
  },
  {
    taskName: "CRM - widok zadań",
    taskDate: "17.11.2020",
    taskDescription: "Ladny opis zadania, taki trochę jak lorem ipsum, ale nie koniecznie",
    hoursInTask: "8,5",
    executor: "Sławek Malitka"
  },
];


export const ProjectData = [{
  key:1,
  type: 1,
  archivedSrc: "[ścieżka_do_plików]",
  style: {backgroundColor: "#333333"},
  projectName: 'Senit 1',
  beginDate: '18-01-2020',
  endDate: "01.01.10",
  types:[
    {
      key:1,
      projectType: "Okładka",
      projectTypePhase: {
        text: "Layout – realizacja",
        color: "red"
      },
      isbn: '978-3-16-148410-0',
      contactPerson: "Widar Trondsen",
      currentTaskExecutor: "Sławek Malitka",
      projectWatcher: "Monika Wojna-Dzikowska",
      tasks: TaskData,
      offers: [],
    },
    {
      key:2,
      projectType: "Książka",
      projectTypePhase: {
        text: "Wysłane do K3",
        color: "orange"
      },
      isbn: '978-3-16-148410-0',
      contactPerson: "Widar Trondsen",
      projectWatcher: "Monika Wojna-Dzikowska",
      tasks: [],
      offers: [],
    }
  ],
  internalId: "PR0023",
  status: {
    text: "W trakcie",
    color: "orange"
  },
  client: 'Kolofon',
  clientId: "KN",
  mockup: "Dette vet vi om",
  projectClosed: "Nie",
},
  {
    key:2,
    type: 1,
    projectName: 'Senit 1 - ePub',
    beginDate: '20-01-2020',
    endDate: null,
    types:[
      {
        key:1,
        projectType: "Epub",
        projectTypePhase: {
          text: "Projekt zafakturowany\n",
          color: "green"
        },
        isbn: '978-3-16-148410-0',
        contactPerson: "Widar Trondsen",
        projectWatcher: "Izabela  Krzyżanowska",
        currentTaskExecutor: "Sławek Malitka",
        tasks: TaskData,
        offers: [],
      },
    ],
    internalId: "PR0028",
    status: {
      text: "Wstrzymane",
      color: "red"
    },
    client: 'Kolofon',
    clientId: "KN",
    mockup: "17 x 24 NOASP",
    projectWatcher: "Maciej Stara",
    projectClosed: "Nie",
  },
  {
    key:3,
    projectName: 'CRM',
    beginDate: '20-03-2020',
    endDate: null,
    types:[
      {
        key:1,
        projectType: "Okładka",
        projectTypePhase: {
          text: "Projekt zafakturowany\n",
          color: "green"
        },
        isbn: '978-3-16-148410-0',
        contactPerson: "Widar Trondsen",
        tasks: TaskData,
        offers: [],
      },
    ],
    internalId: "PR0300",
    status: {
      text: "W trakcie",
      color: "orange"
    },
    client: 'Have a Book',
    clientId: "HaB",
    mockup: "",
    projectWatcher: "Maciej Stara",
    projectClosed: "Nie",
  },
  {
    key:4,
    projectName: 'coś',
    beginDate: '20-09-2020',
    endDate: null,
    types:[
      {
        key:1,
        projectType: "Okładka",
        projectTypePhase: {
          text: "Projekt zafakturowany\n",
          color: "green"
        },
        isbn: '978-3-16-148410-0',
        contactPerson: "Widar Trondsen",
        tasks: TaskData,
        offers: [],
      },
    ],
    internalId: "PR0666",
    status: {
      text: "W trakcie",
      color: "orange"
    },
    client: 'Omida',
    clientId: "OA",
    mockup: "",
    projectWatcher: "Maciej Stara",
    projectClosed: "Tak",
  },
  {
    key:5,
    projectName: 'Windows',
    beginDate: '20-03-2021',
    endDate: "1-04-2021",
    types:[
      {
        key:1,
        projectType: "Okładka",
        projectTypePhase: {
          text: "Projekt zafakturowany\n",
          color: "green"
        },
        isbn: '978-3-16-148410-0',
        contactPerson: "Widar Trondsen",
        tasks: TaskData,
        offers: [],
      },
    ],
    internalId: "PR0001",
    status: {
      text: "Zakończone",
      color: "green"
    },
    client: 'Microsoft',
    clientId: "MS",
    mockup: "14,8 8x21, 4+0",
    projectWatcher: "Maciej Stara",
    projectClosed: "Tak",
  }
];

export const PrintData = [
  {
    projectName: "Le livre",
    client: "Ludwik XVI",
    projectStatus:
        {
          text: "W trakcie",
          color: "orange"
        },
    stepStatus: {
      text: "Sprawdzanie",
      color: "red"
    },
    statusDate: "12-03-2000",
    isFileAcceptRequired: "23-06-2010",
    fileAccept: "23-06-2010",
    dateRequiredDelivery: "23-06-2010",
    sendDate: "01.01.2001",
    paymentDate: "06.06.2006",
    transferDate: "tak",
    valuation:[{
      type: "Offset",
      circulation: "50",
      currency: "PLN",
      pricePerItemPrinter: "3,5",
      pricePerItemClient: "4,1",
      transportValue: "0",
      otherCosts: "0",
      isChoosed: {
        text: "tak",
        color: "green"
      },
      clientValue: "80",
      printingHouse: "Skleniarz"
    },
      {
        type: "Cyfra",
        circulation: "50",
        currency: "PLN",
        pricePerItemPrinter: "3,5",
        pricePerItemClient: "4,1",
        transportValue: "0",
        otherCosts: "0",
        isChoosed: {
          text: "nie",
          color: "red"
        },
        clientValue: "80",
        printingHouse: "Skleniarz"
      },
      {
        type: "Offset",
        circulation: "50",
        currency: "PLN",
        pricePerItemPrinter: "3,5",
        pricePerItemClient: "4,1",
        transportValue: "0",
        otherCosts: "0",
        isChoosed: {
          text: "nie",
          color: "red"
        },
        clientValue: "80",
        printingHouse: "Skleniarz"
      }],
    arturSettlementDate: "06.06.2006"
  }
];

export const ClientData = [
  {
    key: "1",
    clientName: "Kolofon",
    clientInternalId: "KOL",
    postCode:"14-500",
    city: "Braniewo",
    email: 'test@text.com',
    invoiceName: "invoiceName",
    id: '10',
    contactPerson: [{
      key: 1,
      nameSurname: "John Smith",
    },
      {
        key: 2,
        nameSurname: "Maya Smith",
      }
    ],
    country: "Norwegia",
    adress: "",
    phoneNumber: "+48 123 456 789",
    nip: "",
  },
  {
    key: "2",
    clientName: "Have a book",
    clientInternalId: "HAB",
    postCode: "50-140",
    city: "Oslo",
    email: "test@test.com",
    invoiceName: "invoiceName",
    id: "254",
    contactPerson: [{
      key: 1,
      nameSurname: "Jan Kowalski",
    }],
    country: "Polska",
    adress: "Wierzbowa 24 81-558 Gdynia",
    phoneNumber: "+48 58 718 88 98",
    nip: "PL 5832560930",
  }
];
