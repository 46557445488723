import {GET_DAYS_OFF_PLAN_SUCCESS} from "../../../consts/actions"

const initialState = {
  daysOffPlan: []
};

export const DaysOffPlanReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_DAYS_OFF_PLAN_SUCCESS:
      state = {
        ...state,
        daysOffPlan: action.data
      };
      return state;
    default:
      return state;
  }
};


export const DaysOffPlanSelector = (state) => state.DaysOffPlanReducer.daysOffPlan;