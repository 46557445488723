import axios from "axios";
import {excelBackend, mockupBackend} from "../../consts/urls";


export function CreateKolofonReportAction(){
    return (dispatch) =>{
        let CREATE_KOLOFON_REPORT = "CREATE_KOLOFON_REPORT";
        dispatch({
            type: CREATE_KOLOFON_REPORT
        });
        return axios({
            method: "GET",
            url: `${excelBackend}statistics/kolofon`
        }).then(response =>{
            let CREATE_KOLOFON_REPORT_SUCCESS = "CREATE_KOLOFON_REPORT_SUCCESS";
            console.log(response);
            dispatch({
                type: CREATE_KOLOFON_REPORT_SUCCESS,
                data: response.data,
            })
            /*const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'file.xlsx'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();*/
        }).catch(error =>{
            let CREATE_KOLOFON_REPORT_FAIL  = "CREATE_KOLOFON_REPORT_FAIL";
            dispatch({
                type: CREATE_KOLOFON_REPORT_FAIL,
                error
            })
        })
    }
}