import axios from "axios"
import {GENERATE_REPORT, GENERATE_REPORT_SUCCESS, GENERATE_REPORT_FAIL
} from "../../consts/actions";
import {excelBackend} from "../../consts/urls";


export function CreateReportAction(reportConfig){
  return (dispatch) =>{
    dispatch({
      type: GENERATE_REPORT
    });
    return axios({
      method: "POST",
      data: reportConfig,
      url: `${excelBackend}report/new`,
      responseType: 'blob'
    }).then(response =>{
      dispatch({
        type: GENERATE_REPORT_SUCCESS,
        data: response.data,
      })
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'file.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch(error =>{
      dispatch({
        type: GENERATE_REPORT_FAIL,
        error
      })
    })
  }
}
