import axios from "axios"
import {DELETE_TASK_DTP, DELETE_TASK_DTP_SUCCESS, DELETE_TASK_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function DeleteTaskDtpAction(taskId){
    return (dispatch) =>{
        dispatch({
            type: DELETE_TASK_DTP
        });
        return axios({
            method: "DELETE",
            url: `${mockupBackend}projectdtp/task/delete/${taskId}`
        }).then(response =>{
            dispatch({
                type: DELETE_TASK_DTP_SUCCESS,
                data: response.data,
            })
        }).catch(error =>{
            dispatch({
                type: DELETE_TASK_DTP_FAIL,
                error
            })
        })
    }
}