import React, {Component} from 'react';
import {Card, Col, Row} from "antd";
import WorkingHoursViewDataOfDay from "../../../components/WorkingHoursViewDataOfDay";
import WorkingHoursOptions from "../../../components/WorkingHoursOptions";
import WorkingHoursUserHours from "../../../components/WorkingHoursUserHours";
import WorkingHoursNewRecordModal from "../../../components/WorkingHoursNewRecordModal";
import {DaysOffSelector} from "../../../store/internal_api/daysOff/DaysOffReducer";
import {GetAllDaysOffAction} from "../../../actions/internal_api/daysOff/GetAllDaysOffAction";
import {connect} from "react-redux";
import WorkingHoursEditRecordModal from "../../../components/WorkingHoursEditRecordModal";
import { LoggedInUserSelector } from '../../../store/internal_api/LoginUser';
import {DeleteDayOffAction} from "../../../actions/internal_api/daysOff/DeleteDayOffAction";


function mapStateToProps(state) {
  return {
    daysOff: DaysOffSelector(state),
    loggedInUser: LoggedInUserSelector(state)
  }
}

function mapDispatchToProps(dispatch){
  return{
    getDaysOff: () => dispatch(GetAllDaysOffAction()),
    deleteDayOff: (id) => dispatch(DeleteDayOffAction(id))
  }
}

class WorkingHoursView extends Component {

  constructor() {
    super();
    this.state = {
      showNewWorkingHour: false,
      showEditWorkingHoursModal: false,
      editedIndex: -1,
      rangeValue: ["", ""]
    }
  }

  componentDidMount() {
    this.props.getDaysOff();
  }
  loggedInUserIsNotWorker = () =>{
    return this.props.loggedInUser.user.permission.name.toLowerCase() !== "pracownik"
  }

  onEditClick = (index) =>{
    this.setState({showEditWorkingHoursModal: true,
      editedIndex: index})
  }

  getRecordByIndex = () =>{
    //return this.filterViewData(this.props.daysOff)[this.state.editedIndex]
    return this.filterViewData(this.props.daysOff).find(element => element.id === this.state.editedIndex)
  }

  getDateRangeValue = (value) =>{
    this.setState({rangeValue: value})
  }

  userIsAbleToSeeExtendedView = (user) =>{
    return user.permission.showExtendedDaysOffPanel;
  }

  filterViewData = (data) =>{

    if(!this.userIsAbleToSeeExtendedView(this.props.loggedInUser.user)){
      data = data.filter(element => element.worker.id === this.props.loggedInUser.user.id)
    }


    if(this.state.rangeValue[0] === "" && this.state.rangeValue[1] === ""){
      return data
    }

    let filteredData = data.filter(
      (element)=> new Date(element.dayStart).getTime() >= new Date(this.state.rangeValue[0]).getTime() && new Date(element.dayStart).getTime() <=  new Date(this.state.rangeValue[1]).getTime()
      );

    return filteredData;
  }

  deleteDayOff = (dayOffId) =>{
    this.props.deleteDayOff(dayOffId).then(()=>{
      this.props.getDaysOff();
    })
  }

  render() {
    return (
      <div>
        {this.props.loggedInUser.user.permission.name !== "admin" // admin nie ma urlopów
          ?
            <WorkingHoursUserHours/>
          :
          null
        }
        <Card>
          <Row>
            <Col span={24}>
              <WorkingHoursOptions showAddVacationDay={this.userIsAbleToSeeExtendedView(this.props.loggedInUser.user)} setFilterData={this.getDateRangeValue} onNewWorkingHourClick={()=>this.setState({showNewWorkingHour: true})}/>
            </Col>
          </Row>
          <WorkingHoursViewDataOfDay userIsNotWorker={this.loggedInUserIsNotWorker()} onEditCLick={this.onEditClick} onDeleteClick={this.deleteDayOff} data={this.filterViewData(this.props.daysOff)}/>
        </Card>
        { this.state.showNewWorkingHour
          ?
          <WorkingHoursNewRecordModal visibility={this.state.showNewWorkingHour}
                                      onCancel={() => this.setState({showNewWorkingHour: false})}/>
          :
            null
        }
        {
          this.state.showEditWorkingHoursModal
            ?
              <WorkingHoursEditRecordModal
                  onCancel={() => this.setState({showEditWorkingHoursModal: false, editedIndex: -1})}
                  visibility={this.state.showEditWorkingHoursModal}
                  defaultValues={this.getRecordByIndex()}
                  index={this.state.editedIndex}
              />
              :
              null
        }
      </div>
    );
  }
}

WorkingHoursView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (WorkingHoursView);
