import axios from "axios"
import {MODIFY_PROJECT_DTP, MODIFY_PROJECT_DTP_SUCCESS, MODIFY_PROJECT_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function ModifyProjectDtpAction(projectId, data){
  return (dispatch) =>{
    dispatch({
      type: MODIFY_PROJECT_DTP
    });
    return axios({
      method: "POST",
      data: data,
      url: `${mockupBackend}projectdtp/modify/${projectId}`
    }).then(response =>{
      dispatch({
        type: MODIFY_PROJECT_DTP_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: MODIFY_PROJECT_DTP_FAIL,
        error
      })
    })
  }
}