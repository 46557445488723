import React, {Fragment} from 'react';
import {Col, Divider, Row, Input, Form} from "antd";

import PrintNewProjectArchiveSection from "./PrintNewProjectArchiveSection";

const {TextArea} = Input;

const PrintOtherProjectType = ({form, defaultValues, isArchive}) => {


  return (
    <Fragment>
      <Divider orientation={"left"}>Inne</Divider>
      <Form form={form}>
      <PrintNewProjectArchiveSection druk defaultValues={defaultValues}/>
      <Divider>Dane</Divider>
            <Row gutter={[24,24]}>
              <Col span={8}>
                Dostawa:
              </Col>
              <Col span={8}>
                Pakowanie:
              </Col>
              <Col span={8}>
                Inne informacje:
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={8}>
                  <Form.Item name={"supply"} initialValue={defaultValues !== undefined?defaultValues[0].config.supply: null}>
                <TextArea disabled={isArchive} rows={5}/>
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item name={"packing"} initialValue={defaultValues !== undefined?defaultValues[0].config.packing: null}>
                    <TextArea disabled={isArchive} rows={5}/>
                  </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item name={"otherInfo"} initialValue={defaultValues !== undefined?defaultValues[0].config.otherInfo: null}>
                    <TextArea disabled={isArchive} rows={5}/>
                  </Form.Item>
              </Col>
            </Row>
        </Form>
    </Fragment>
  );
};

PrintOtherProjectType.propTypes = {

};

export default PrintOtherProjectType;
