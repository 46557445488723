import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  GET_PRINTING_HOUSES,
  GET_PRINTING_HOUSES_SUCCESS,
  GET_PRINTING_HOUSES_FAIL,
} from "../../../consts/actions"

export function GetAllPrintingHouseAction(){
  return (dispatch) =>{
    dispatch({
      type: GET_PRINTING_HOUSES
    });
    return axios({
      method: "GET",
      url: `${mockupBackend}printinghouse/all`
    }).then(response =>{
      dispatch({
        type: GET_PRINTING_HOUSES_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: GET_PRINTING_HOUSES_FAIL,
        error
      })
    })
  }
}