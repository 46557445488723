export const TaskNames = [
    "Rydding/koding",
    "Layout",
    "Figurer og illustrasjoner",
    "Bildebehandling/repro",
    "Sats",
    "W: sprawdzanie po składzie przed wysłaniem przed 1.korr",
    "1. korr",
    "2. korr",
    "3. korr",
    "4. korr",
    "5. korr",
    "6. korr",
    "W: sprawdzanie wprowadzania korekty",
    "W: sprawdzanie przed wysłaniem do druku",
    "Omslag",
    "Epub",
    "Sprawdzanie epuba",
    "Indeks",
    "Kolofon og lavoppløselige filer",
    "Wordkonvertering",
    "Konvertering av matematiske formler",
    "2. OPPLAG",
    "3. OPPLAG",
    "4. OPPLAG",
    "W: archiwizacja",
    "W: kadry i administracja",
    "W: problemy techniczne",
    "W: zadania sprzedażowe i przetargi",
    "W: komunikacja z klientem",
    "W: brak obłożenia",
    "W: szkolenia i spotkania",
    "W: klient wewnętrzny",
    "Etap 1/2",
    "Etap 3",
    "Etap 4",
    "Etap 5",
    "Etap 6",
]