import React, {Component} from 'react';
import {Button, Card, Radio, Select} from "antd";
import {Centered} from "../../../components/styled/Layout";
import {CreateReportAction} from "../../../actions/report_service/CreateReportAction";
import {connect} from "react-redux";
import {CreateKolofonReportAction} from "../../../actions/report_service/CreateKolofonReportAction";
import {excelBackend} from "../../../consts/urls";
import {ProjectsDrukSelector} from "../../../store/internal_api/Projects/ProjectsDrukReducer";
import {GetAllProjectsDrukAction} from "../../../actions/internal_api/Projects/GetAllProjectDrukAction";
import {CreateReportValuation} from "../../../actions/report_service/CreatePrintValuation";

function mapStateToProps(state) {
    return {
        printProjects: ProjectsDrukSelector(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getReport: (config) => dispatch(CreateReportAction(config)),
        getPrintProjects: () => dispatch(GetAllProjectsDrukAction()),
        getKolofonReport: () => dispatch(CreateKolofonReportAction()),
        getValuation: (projectId) => dispatch(CreateReportValuation(projectId))
    }
}


class ReportView extends Component {
    constructor() {
        super()
        this.state = {
            dataForReportGenerator: {
                isDtp: false,
                isPrint: false,
                reportProjectsPerClientDtp: false,
                reportProjectsHoursDtp: false,
                reportProjectsPrint: false
            },
            choosedPrintProject: null,
            printProjects: []
        }
    }

    componentDidMount() {
        this.props.getPrintProjects().then(() => {

            let printProjects = this.props.printProjects.filter(project => {
                  return project.valuations.some((valuation) => {
                    if (valuation.isChoosed) {
                        return true
                    }
                })
            })
            this.setState({printProjects: printProjects})

        })
    }

    onSelectPrintProject = (value) => {
        this.setState({
            choosedPrintProject: value
        })
    }

    generateValuation = () => {
        this.props.getValuation(this.state.choosedPrintProject)
    }

    onGroupChange = (value) => {
        let actualState = this.state.dataForReportGenerator;
        switch (value) {
            case "dtp":
                actualState.isDtp = true;
                actualState.isPrint = false;
                actualState.reportProjectsPerClientDtp = false;
                actualState.reportProjectsPrint = false;
                actualState.reportProjectsHoursDtp = false;
                this.setState({dataForReportGenerator: actualState});
                break;
            case "print":
                actualState.isDtp = false;
                actualState.isPrint = true;
                actualState.reportProjectsPerClientDtp = false;
                actualState.reportProjectsPrint = false;
                actualState.reportProjectsHoursDtp = false;
                this.setState({dataForReportGenerator: actualState});
                break;
            default:
                actualState.isDtp = true;
                actualState.isPrint = false;
                actualState.reportProjectsPerClientDtp = false;
                actualState.reportProjectsPrint = false;
                actualState.reportProjectsHoursDtp = false;
                this.setState({dataForReportGenerator: actualState});
                break;
        }
    }

    onTypeChange = (value) => {
        let actualState = this.state.dataForReportGenerator;
        switch (value) {
            case "reportProjectsPerClientDtp":
                actualState.reportProjectsPerClientDtp = true;
                actualState.reportProjectsPrint = false;
                actualState.reportProjectsHoursDtp = false;
                this.setState({dataForReportGenerator: actualState});
                break;
            case "reportProjectsHoursDtp":
                actualState.reportProjectsPerClientDtp = false;
                actualState.reportProjectsPrint = false;
                actualState.reportProjectsHoursDtp = true;
                this.setState({dataForReportGenerator: actualState});
                break;
            case "reportProjectsPrint":
                actualState.reportProjectsPerClientDtp = false;
                actualState.reportProjectsPrint = true;
                actualState.reportProjectsHoursDtp = false;
                this.setState({dataForReportGenerator: actualState});
                break;
            default:
                actualState.reportProjectsPerClientDtp = true;
                actualState.reportProjectsPrint = false;
                actualState.reportProjectsHoursDtp = false;
                this.setState({dataForReportGenerator: actualState});
                break;
        }
    }

    getReportDocument = () => {
        this.props.getReport(this.state.dataForReportGenerator)
    }

    generateKolofonReport = () => {
        this.props.getKolofonReport();
    }

    render() {

        return (
            <div>
                <Card>
                    <Radio.Group onChange={(e) => this.onGroupChange(e.target.value)}>
                        <Radio key={"dtp"} value={"dtp"}>DTP</Radio>
                        <Radio disabled key={"print"} value={"print"}>Druk</Radio>
                    </Radio.Group>
                    <Button /*onClick={()=> this.generateKolofonReport()}*/><a
                        href={`${excelBackend}statistics/kolofon`} target="_blank">Generuj raport kolofon</a></Button>
                </Card>
                {this.state.dataForReportGenerator.isDtp
                    ?
                    <Card>
                        <Radio.Group defaultValue={null} onChange={(e) => this.onTypeChange(e.target.value)}>
                            <Radio key={"reportProjectsPerClientDtp"} value={"reportProjectsPerClientDtp"}>Projekty do
                                faktury</Radio>
                            <Radio key={"reportProjectsHoursDtp"} value={"reportProjectsHoursDtp"}>Godziny poświęcone
                                zadaniom</Radio>
                        </Radio.Group>
                    </Card>
                    :
                    null
                }
                {this.state.dataForReportGenerator.isPrint
                    ?
                    <Card>
                        <Radio.Group defaultValue={null} onChange={(e) => this.onTypeChange(e.target.value)}>
                            <Radio key={"reportProjectsPrint"} value={"reportProjectsPrint"}>Raport Projektów</Radio>
                        </Radio.Group>
                    </Card>
                    :
                    null
                }
                {(this.state.dataForReportGenerator.isPrint === true || this.state.dataForReportGenerator.isDtp === true)
                && (this.state.dataForReportGenerator.reportProjectsHoursDtp === true || this.state.dataForReportGenerator.reportProjectsPrint === true
                    || this.state.dataForReportGenerator.reportProjectsPerClientDtp === true)
                    ?
                    <Card>
                        <Centered>
                            <Button onClick={this.getReportDocument}>Generuj dokument</Button>
                        </Centered>
                    </Card>
                    :
                    null
                }
                <Card>
                    <Centered>
                        <div>
                            <Select placeholder="Wybierz projekt" onChange={this.onSelectPrintProject}
                                    options={this.state.printProjects.map(value => {
                                        return {value: value.id, label: value.projectName}

                                    })}/>

                            <Button disabled={this.state.choosedPrintProject === null}
                                    onClick={() => this.generateValuation()}>Generuj wycenę dla druku</Button>
                        </div>
                    </Centered>
                </Card>
            </div>
        );
    }
}

ReportView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReportView);
