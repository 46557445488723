import axios from "axios"
import {MODIFY_TASK_DTP, MODIFY_TASK_DTP_SUCCESS, MODIFY_TASK_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function ModifyTaskDtpAction(taskId, data){
  return (dispatch) =>{
    dispatch({
      type: MODIFY_TASK_DTP
    });
    return axios({
      method: "POST",
      data: data,
      url: `${mockupBackend}projectdtp/task/modify/${taskId}`
    }).then(response =>{
      dispatch({
        type: MODIFY_TASK_DTP_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: MODIFY_TASK_DTP_FAIL,
        error
      })
    })
  }
}