import axios from "axios"
import {MODIFY_PROJECT_WATCHER_AND_PHASE, MODIFY_PROJECT_WATCHER_AND_PHASE_SUCCESS, MODIFY_PROJECT_WATCHER_AND_PHASE_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function ModifyProjectWatcherAndPhaseAction(id, data){
  return (dispatch) =>{
    dispatch({
      type: MODIFY_PROJECT_WATCHER_AND_PHASE
    });
    return axios({
      method: "POST",
      data: data,
      url: `${mockupBackend}projectdtp/modifytypephase/${id}`
    }).then(response =>{
      dispatch({
        type: MODIFY_PROJECT_WATCHER_AND_PHASE_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: MODIFY_PROJECT_WATCHER_AND_PHASE_FAIL,
        error
      })
    })
  }
}