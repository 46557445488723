import React, {Fragment} from 'react';
import { Col, Input, Radio, Row, Select} from "antd";
import AddonBefore from "./Layout/AddonBefore";

const {Option} = Select;

const PrintArchiveProjectSettings = ({onFilterChange, getMonoColorValue}) => {
  const projectsVisibility = [
    {
      label: "Tylko moje projekty",
      value: false
    },
    {
      label: "Wszystkie zadania",
      value: true
    },

  ];


  return (
      <Fragment>
        <Row gutter={[24,24]}>
          <Col span={8}>
            <Radio.Group optionType="button" options={projectsVisibility} value={false}/>

          </Col>
          <Col span={8}>
            <Input
                onChange={onFilterChange}
                placeholder={"Wpisz nazwę projektu aby wyszukać projekty "}
            />
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={5}>
            <AddonBefore additionText={"Papier"}>
              <Select onChange={(value)=> getMonoColorValue(value)} defaultValue={0} style={{ width: '100%' }} >
                <Option key={0} value={0}>Brak filtrowania</Option>
                <Option key={1} value={1}>Mono</Option>
                <Option key={2} value={2}>Kolor</Option>
              </Select>
            </AddonBefore>
          </Col>
        </Row>
      </Fragment>
  );
};

PrintArchiveProjectSettings.propTypes = {

};

export default PrintArchiveProjectSettings;