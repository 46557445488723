import {GET_GROUPS_SUCCESS} from "../../../consts/actions";

const initialState = {
    groups: []
};

export const GroupsReducer = (state = initialState, action) =>{
    switch(action.type){
        case GET_GROUPS_SUCCESS:
            state = {
                ...state,
                groups: action.data
            };
            return state;
        default:
            return state;
    }
};

export const GroupsSelector = (state) => state.GroupsReducer.groups;
