import React from 'react';
import {Col, Row} from "antd";
import ContacPersonForm from "./ContacPersonForm";

const NewContactPersonSection = ({remove, field, values, companySection, isEditing}) => {

  return (
    <div>
      <Row gutter={[26,24]}>
        <Col span={3}>
          Imię i nazwisko
        </Col>
        <Col span={2}>
          Stanowisko
        </Col>
        <Col span={3}>
          Kraj
        </Col>
        <Col span={3}>
          Tel kom
        </Col>
        <Col span={3}>
          Tel stacjonarny
        </Col>
        <Col span={3}>
          Notatki
        </Col>
        <Col span={4}>
          e-mail
        </Col>
        <Col span={2}>
          Usuń pozycję
        </Col>
      </Row>
      <ContacPersonForm field={field} onDeleteClick={remove} values={values} companySection={companySection} isEditing={isEditing}/>
    </div>
  );
};

NewContactPersonSection.propTypes = {

};

export default NewContactPersonSection;