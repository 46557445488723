import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {applyMiddleware, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {PersistGate} from 'redux-persist/integration/react'
import reducer from "./store/reducer"
import storage from 'redux-persist/lib/storage'
import LoginPage from "./views/LoginPage/LoginPage";
import {createLogger} from "redux-logger/src";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import MainPanel from "./views/MainPanel/MainPanel";
import Goodnight from "./components/Goodnight";
import * as Sentry from "@sentry/react";
import wsmiddleware from "./middleware/wsmiddleware";
import WebsocketConnection from "./hocs/WebsocketConnection";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const persistConfig = {
  key: 'root',
  storage: storage,
  storageReconciler: autoMergeLevel2,
  whitelist: ["LoggedInUserReducer", "UserProjectTaskReducer", "UsersReducer"]
};

const pReducer = persistReducer(persistConfig, reducer);

const logger = createLogger({
  collapsed: true,
});


let middleware;
if (process.env.NODE_ENV === 'production'){
  middleware = applyMiddleware(thunk, wsmiddleware)
} else {
  middleware = applyMiddleware(thunk, wsmiddleware, logger)
}

const store = createStore(pReducer, middleware);

const persistor = persistStore(store);

class App extends Component{

  render() {
    return(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer position="bottom-center"
                          autoClose={10000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover/>
          <Sentry.ErrorBoundary fallback={"An error has occurred"} showDialog>
          <Router>
            <Switch>
              <Route exact path={"/"} component={LoginPage}/>
              <WebsocketConnection>
                <Route path={"/app"} component={MainPanel}/>
              </WebsocketConnection>
              <Route path={"/*"} component={Goodnight}/>
            </Switch>
          </Router>
          </Sentry.ErrorBoundary>
        </PersistGate>
      </Provider>
    )
  }
}

export default App;
