import React, {Component, Fragment} from 'react';
import {Layout, Menu} from 'antd';
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  CopyOutlined
} from '@ant-design/icons';
import {Link, Route, Switch, Redirect} from "react-router-dom";
import YourDataView from "../Subviews/YourDataView/YourDataView";
import WorkingHoursView from "../Subviews/WorkingHoursView/WorkingHoursView";
import DTPArchive from "../Subviews/Archive/DTPArchive";
import ClientListView from "../Subviews/ClientListView/ClientListView";
import Goodnight from "../../components/Goodnight";
import UserManagementView from "../Subviews/UserManagementView/UserManagementView";
import ProjectsView from "../Subviews/ProjectsView/ProjectsView";
import HeaderPanel from "../../components/HeaderPanel";
import TaskView from "../Subviews/TaskView/TaskView";
import {MaxHeight} from "../../components/styled/Layout";
import PrintProjectView from "../Subviews/PrintProjectView/PrintProjectView";
import PrintingHouseView from "../Subviews/PrintingHouseView/PrintingHouseView";
import PrintArchive from "../Subviews/PrintArchive/PrintArchive";
import YearWorkSettingView from "../Subviews/YearWorkSerringsView/YearWorkSettingView";
import PrintClientListView from "../Subviews/PrintClientListView/PrintClientListView";
import {LoggedInUserSelector} from "../../store/internal_api/LoginUser";
import {connect} from "react-redux";
import {LogoutAction} from "../../actions/internal_api/users/LogoutUser";
import ReportView from "../Subviews/ReportView/ReportView";
import {GetAllProjectsDtpAction} from "../../actions/internal_api/Projects/GetAllProjectsDtpAction";
import {GetAllUsersAction} from "../../actions/internal_api/users/GetAllUsers";
import OtherUsersVacationDays from "../Subviews/OtherUsersVacationDays/OtherUsersVacationDays";
import {GetAllProjectsDrukAction} from "../../actions/internal_api/Projects/GetAllProjectDrukAction";


const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

function mapStateToProps(state){
  return{
    loggedInUser: LoggedInUserSelector(state)
  }
}

function mapDispatchToProps(dispatch){
  return{
    logoutUser: (token) => dispatch(LogoutAction(token)),
    getAllProjects: () => dispatch(GetAllProjectsDtpAction()),
    getPrintProjects: () => dispatch(GetAllProjectsDrukAction()),
    getAllUsers: () => dispatch(GetAllUsersAction())

  }
}

class MainPanel extends Component {

  constructor() {
    super();
    this.state = {
      collapsed: false,
    }
  }

  componentDidMount() {
    this.props.getAllProjects()
    this.props.getAllUsers()
    this.props.getPrintProjects()
  }



  userIsAbleToShowDTP = () =>{
    return this.props.loggedInUser.user.group.showDtpResources || this.props.loggedInUser.user.permission.showDtpResources;
  }

  userIsAbleToShowDruk = () =>{
    return this.props.loggedInUser.user.group.showDrukResources || this.props.loggedInUser.user.permission.showDrukResources;
  }

  userIsAbleToShowAdmin = () =>{
    return this.props.loggedInUser.user.permission.showAdministrationPanel;
  }

  userIsAbleToShowCoordinator = () =>{
    return this.props.loggedInUser.user.permission.showExtendedDaysOffPanel;
  }

  userIsAbleToShowReports = () => {
    return this.props.loggedInUser.user.permission.name.toLowerCase() !== "pracownik";
  }

  userIsAbleToShowClients = () => {
    return this.props.loggedInUser.user.permission.name.toLowerCase() !== "pracownik";
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    }, ()=>{
      this.forceUpdate()
    });
  };

  getSelectedMenuElement(){
    switch(this.props.history.location.pathname){
      case "/app/projects":
        return ["1"]
      case "/app/archivedtp":
        return ["2"]
      case "/app/projectsprint":
        return ["3"]
      case "/app/archiveprint":
        return ["4"]
      case "/app/yourdata":
        return ["5"]
      case "/app/workinghours":
        return ["6"]
      case "/app/clientlistdtp":
        return ["7"]
      case "/app/clientlistdruk":
        return ["8"]
      case "/app/printinghouselist":
        return ["9"]
      case "/app/workyearly":
        return ["10"]
      case "/app/othervacationdays":
        return ["11"]
      case "/app/usermanagement":
        return ["12"]
      default:
        return ["1"]
    }
  }

  getExpandedMenuElement(){
    switch(this.props.history.location.pathname){
      case "/app/projects":
        return ["sub1"]
      case "/app/archivedtp":
        return ["sub1"]
      case "/app/projectsprint":
        return ["sub1"]
      case "/app/archiveprint":
        return ["sub1"]
      case "/app/yourdata":
        return ["sub1"]
      case "/app/workinghours":
        return ["sub2"]
      case "/app/clientlistdtp":
        return ["sub3"]
      case "/app/clientlistdruk":
        return ["sub3"]
      case "/app/printinghouselist":
        return ["sub3"]
      case "/app/workyearly":
        return ["sub4"]
      case "/app/usermanagement":
        return ["sub4"]
      case "/app/othervacationdays":
        return ["sub4"]
      default:
        return ["sub1"]
    }
  }

  onLogoutClick = () =>{
    this.props.logoutUser(this.props.loggedInUser.sessionToken).then(()=>{
      this.props.history.push("/")
    })
  }

  render() {
    let {url} = this.props.match;
    const { collapsed } = this.state;

    if(this.props.loggedInUser.id === undefined ){
      return <Redirect to={"/"}/>
    }

    return (
      <Layout style={{height: "100%"}}>
        <Sider
          width={200}
          collapsible
          collapsed={collapsed}
          onCollapse={this.toggleCollapsed}
          className="site-layout-background"
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
            left: 0,
            position: "fixed"
          }}

        >
          <Menu
              mode="inline"
            defaultSelectedKeys={this.getSelectedMenuElement()}
            defaultOpenKeys={this.getExpandedMenuElement()}
            style={{ height: '100%', borderRight: 0 }}
          >
            <SubMenu key="sub1" icon={<LaptopOutlined />} title="Projekty">
              {this.userIsAbleToShowDTP()
                  ?
                  <Fragment>
                    <Menu.Item key="1"><Link to={"/app/projects"}>Projekty {this.userIsAbleToShowAdmin() ?  "(DTP)" : null}</Link></Menu.Item>
                    <Menu.Item key="2"><Link to={"/app/archivedtp"}>Archiwum {this.userIsAbleToShowAdmin() ? "(DTP)" : null}</Link></Menu.Item>

                  </Fragment>
                  :
                  null
              }
              {this.userIsAbleToShowDruk()
                  ?
                  <Fragment>
                    <Menu.Item key="3"><Link to={"/app/projectsprint"}>Projekty {this.userIsAbleToShowAdmin()? "(DRUK)": null}</Link></Menu.Item>
                    <Menu.Item key="4"><Link to={"/app/archiveprint"}>Archiwum {this.userIsAbleToShowAdmin()? "(DRUK)": null}</Link></Menu.Item>
                  </Fragment>
                  :
                  null
              }
            </SubMenu>
            <SubMenu key="sub2" icon={<UserOutlined />} title="Użytkownik">
              <Menu.Item key="5"><Link to={"/app/yourdata"}>Twoje dane</Link></Menu.Item>
              <Menu.Item key="6"><Link to={"/app/workinghours"}>Urlopy</Link></Menu.Item>
            </SubMenu>
            { this.userIsAbleToShowClients()
                ?
                <SubMenu key="sub3" icon={<NotificationOutlined/>} title={"Klienci"}>
                  {this.userIsAbleToShowDTP()
                      ?
                      <Menu.Item key="7"><Link to={"/app/clientlistdtp"}>Lista klientów DTP/IT</Link></Menu.Item>
                      :
                      null
                  }
                  {this.userIsAbleToShowDruk()
                      ?
                      <Fragment>
                        <Menu.Item key="8"><Link to={"/app/clientlistdruk"}>Lista klientów DRUK</Link></Menu.Item>
                        <Menu.Item key="9"><Link to={"/app/printinghouselist"}>Lista drukarni</Link></Menu.Item>
                      </Fragment>
                      :
                      null
                  }
                </SubMenu>
                :
                null
            }
            { this.userIsAbleToShowReports()
              ?
              <SubMenu key={ "sub5" } icon={ <CopyOutlined/> } title={ "Raporty" }>
                <Menu.Item key={ "13" }><Link to={ "/app/report" }>Generuj raport</Link></Menu.Item>
              </SubMenu>
              :
              null
            }
            { this.userIsAbleToShowCoordinator() || this.userIsAbleToShowAdmin()
              ?
                <SubMenu key="sub4" icon={<NotificationOutlined/>} title="Administracja">
                  {this.userIsAbleToShowCoordinator()
                    ?
                      <Menu.Item key={"10"}><Link to={`/app/workyearly`}>Plan urlopowy</Link></Menu.Item>
                    :
                    null
                  }
                  {this.userIsAbleToShowCoordinator() || this.userIsAbleToShowAdmin()
                      ?
                      <Menu.Item key={"11"}><Link to={'/app/othervacationdays'}>Urlopy użytkowników</Link></Menu.Item>
                      :
                      null

                  }
                  {this.userIsAbleToShowAdmin()
                    ?
                    <Menu.Item key="12"><Link to={"/app/usermanagement"}>Zarządzanie użytkownikami</Link></Menu.Item>
                    :
                    null
                  }
                </SubMenu>
              :
              null
            }
            

          </Menu>
        </Sider>
          <Layout style={{marginLeft: "200px"}}>
            <Header className="header">
              <HeaderPanel userName={this.props.loggedInUser.user.name} onLogoutClick={this.onLogoutClick}/>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                minHeight: 280,
              }}
            >
              <MaxHeight>
                <Switch>
                  <Route exact path={`${url}/printinghouselist`} component={PrintingHouseView}/>
                  <Route exact path={`${url}/tasks`} component={TaskView}/>
                  <Route exact path={`${url}/projects`} component={ProjectsView}/>
                  <Route exact path={`${url}/projectsprint`} component={PrintProjectView}/>
                  <Route exact path={`${url}/yourdata`} component={YourDataView}/>
                  <Route exact path={`${url}/workinghours`} component={WorkingHoursView}/>
                  <Route exact path={`${url}/archivedtp`} component={DTPArchive}/>
                  <Route exact path={`${url}/archiveprint`} component={PrintArchive}/>
                  <Route exact path={`${url}/clientlistdtp`} component={ClientListView}/>
                  <Route exact path={`${url}/clientlistdruk`} component={PrintClientListView}/>
                  <Route exact path={`${url}/usermanagement`} component={UserManagementView}/>
                  <Route exact path={`${url}/workyearly`} component={YearWorkSettingView}/>
                  <Route exact path={`${url}/report`} component={ReportView}/>
                  <Route exact path={`${url}/othervacationdays`} component={OtherUsersVacationDays}/>
                  <Route exact path={`${url}*`} component={Goodnight}/>
                </Switch>
              </MaxHeight>
            </Content>
          </Layout>
        </Layout>
    );
  }
}

MainPanel.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (MainPanel);
