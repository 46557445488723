import * as actions from "../../consts/actions"

const initialState = {
  loggedInUser: {}
};

export const LoggedInUserReducer = (state = initialState, action) => {
  switch(action.type){
    case actions.LOGIN_USER_SUCCESS:
      state = {
        ...state,
        loggedInUser: action.data
      };
      return state;
    case actions.LOGIN_USER:
      state = {
        ...state,
        loggedInUser: initialState.loggedInUser
      }
      return state
    case actions.LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        loggedInUser: initialState.loggedInUser
      }
      return state
    default:
      return state;
  }
};

export const LoggedInUserSelector = (state) => state.LoggedInUserReducer.loggedInUser;