import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
    DELETE_DAY_OFF,
    DELETE_DAY_OFF_SUCCESS,
    DELETE_DAY_OFF_FAIL,
} from "../../../consts/actions";


export function DeleteDayOffAction(dayOffId){
    return (dispatch) =>{
        dispatch({
            type: DELETE_DAY_OFF
        });
        return axios({
            method: "DELETE",
            url: `${mockupBackend}daysoff/delete/${dayOffId}`
        }).then(response =>{
            dispatch({
                type: DELETE_DAY_OFF_SUCCESS,
                data: response.data,
            })
        }).catch(error =>{
            dispatch({
                type: DELETE_DAY_OFF_FAIL,
                error
            })
        })
    }
}