import {GET_CLIENTS_DTP_SUCCESS} from "../../../consts/actions"

const initialState = {
  clients: []
};

export const ClientsDtpReducer = (state = initialState, action) =>{
  switch(action.type){
    case GET_CLIENTS_DTP_SUCCESS:
      state = {
        ...state,
        clients: action.data
      };
      return state;
    default:
      return state;
  }
};


export const ClientsDtpSelector = (state) => state.ClientsDtpReducer.clients;