import React from 'react';
import {Card, Col, Row} from "antd";
import { useSelector } from 'react-redux';
import { UsersSelector } from '../store/internal_api/users/usersReducer';
import { DaysOffSelector } from '../store/internal_api/daysOff/DaysOffReducer';
import {LoggedInUserSelector} from "../store/internal_api/LoginUser";


const WorkingHoursUserHours = () => {
  const users = useSelector(UsersSelector);
  const daysFree = useSelector(DaysOffSelector);
  const loggedInUser = useSelector(LoggedInUserSelector);

  const currentDate = new Date()

  const currentUser = users.find(element => element.id === loggedInUser.user.id);
  const currentUserDaysOffLeave = daysFree
    .filter(element => new Date(element.dayStart).getFullYear() === currentDate.getFullYear())
    .filter(element =>element.worker.id === loggedInUser.user.id)
    .filter(element => element.typeOfDayOff === "Urlop");



  let currentDays = currentUser.freeHoursInYear || 0;
  let lastYearDays = currentUser.freeHoursInLastYear || 0

  const subtractAvalibleDaysFromLastYear = (value) =>{
    let rest = 0
    lastYearDays = lastYearDays - value
    if(lastYearDays < 0){
      rest = Math.abs(lastYearDays);
      lastYearDays = 0
    }
    return rest;
  }


  currentUserDaysOffLeave.forEach(element =>{
      currentDays = currentDays - subtractAvalibleDaysFromLastYear(element.howMuchDays)
  })
  


  return (
      <Card title={"Pozostałe dni urlopowe"} size="small">
         <Row gutter={[16,16]}>
           <Col span={4}>
             <Card title={"Ten rok"} size="small">
               <h2>
                 {currentDays} dni
               </h2>
             </Card>
           </Col>
           <Col span={4}>
             <Card title={"Poprzedni rok"} size="small">
               <h2>
                 {lastYearDays} dni
               </h2>
             </Card>
           </Col>
         </Row>
      </Card>
  );
};

WorkingHoursUserHours.propTypes = {
  
};

export default WorkingHoursUserHours;
