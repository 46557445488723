import React, {useState} from 'react';
import {Form, Input, Popconfirm, Table, Typography} from "antd";
import {useDispatch} from "react-redux";
import {EditDaysOffPlanAction} from "../actions/internal_api/daysOffPlan/EditDaysOffPlan";
import { GetAllUsersAction } from '../actions/internal_api/users/GetAllUsers';


const WorkersWorkTable = ({originData}) => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');



  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      //const newData = [...originData];
      const index = originData.findIndex((item) => key === item.key);


      dispatch(EditDaysOffPlanAction(originData[index].id, row)).then(()=>{
        dispatch(GetAllUsersAction()).then(()=>{
          setEditingKey('');
        });

      })

    } catch (errInfo) {
    }
  };


  let columns = [
    {
      title: "Imię i nazwisko",
      dataIndex: "name",
      key: "name",
      sorter: (a,b) => ('' + a.name).localeCompare(b.name)
    },
    {
      title: "Grupa",
      dataIndex: "group",
      key: "group",
      render: (text) => text.name

    },
    {
      title: "Ilość dni w tym roku",
      dataIndex: "freeHoursInYear",
      key: "freeHoursInYear",
      //render: (text, data, index) => <Form.Item name={`freeHoursInYear`}><Input/></Form.Item>
      editable: true,
    },
    {
      title: "Ilość dni w poprzednim roku",
      dataIndex: "freeHoursInLastYear",
      key: "freeHoursInLastYear",
      editable: true,
      //render: (text, data, index) => <Form.Item name={`freeHoursInLastYear`}><Input/></Form.Item>
    },
    {
      title: 'Akcje',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="#none"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Zapisz
            </a>
            <Popconfirm title="Na pewno anulować?" onConfirm={cancel} cancelText={"Nie"} okText={"Tak"}>
              <a href="#none">Anuluj</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edytuj
          </Typography.Link>
        );
      },
    },
  ];

  const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                        }) => {
    //const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Proszę wprowadzić ${title}!`,
              },
            ]}
          >
            <Input/>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };


  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });


  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={originData}
        columns={mergedColumns}
        rowClassName="editable-row"
        /*pagination={{
          onChange: cancel,
        }}*/
        pagination={false}
        scroll={{
          //y: "57vh",
          x: "86vw"
        }}
      />
    </Form>
  );
};

WorkersWorkTable.propTypes = {

};

export default WorkersWorkTable;
