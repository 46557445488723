import axios from "axios"
import {GENERATE_VALUATION, GENERATE_VALUATION_SUCCESS, GENERATE_VALUATION_FAIL
} from "../../consts/actions";
import {excelBackend} from "../../consts/urls";


export function CreateReportValuation(projectId){
    return (dispatch) =>{
        dispatch({
            type: GENERATE_VALUATION
        });
        return axios({
            method: "GET",
            url: `${excelBackend}offer/new/${projectId}`,
            responseType: 'blob'
        }).then(response =>{
            dispatch({
                type: GENERATE_VALUATION_SUCCESS,
                data: response.data,
            })
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'file.xlsx'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error =>{
            dispatch({
                type: GENERATE_VALUATION_FAIL,
                error
            })
        })
    }
}
