import axios from "axios";
import {GET_GROUPS, GET_GROUPS_FAIL, GET_GROUPS_SUCCESS} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function GetAllGroupsAction(){
    return (dispatch) =>{
        dispatch({
            type: GET_GROUPS
        });
        return axios({
            method: 'get',
            url: `${mockupBackend}groups/all`,
        }).then(response =>{
            dispatch({
                type: GET_GROUPS_SUCCESS,
                data: response.data
            })
        }).catch(error =>{
            dispatch({
                type: GET_GROUPS_FAIL,
                error
            })
        })
    }
}