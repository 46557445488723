import React from 'react';
import {Button, Col, Form, Modal, Row, Select} from "antd";
import AddonBefore from "./Layout/AddonBefore";
import {useDispatch, useSelector} from "react-redux";
import {GetAllUsersAction} from "../actions/internal_api/users/GetAllUsers";
import {EditUserAction} from "../actions/internal_api/users/EditUserAction";
import {GroupsSelector} from "../store/internal_api/groups/groupsReducer"
import {PermissionsSelector} from "../store/internal_api/permissions/permissionsReducer"

const {Option} = Select

const EditUserModal = ({visibility, onCancel, defaultValues}) => {

    const [mainForm] = Form.useForm();
    const dispatch = useDispatch();
    const groupData = useSelector(GroupsSelector)
    const permissionData = useSelector(PermissionsSelector)

    const groupsTypes = groupData.map(element =>{
        return <Option key={element.id} value={element.id}>{element.name}</Option>
    })

    const permissionsTypes = permissionData.map(element =>{
        return <Option key={element.id} value={element.id}>{element.name}</Option>
    })

    const tempOnOk = () =>{
        mainForm.validateFields().then((values) =>{
            /*let newData = {
                ...defaultValues,
                ...values
            }*/
            dispatch(EditUserAction(defaultValues.id, values)).then(()=>{
                dispatch(GetAllUsersAction())
                onCancel()
            })
        })
    }

    return (
        <Modal
            title={"Edycja"}
            visible={visibility}
            maskClosable={false}
            onCancel={onCancel}
            width={"90%"}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Anuluj
                </Button>,
                <Button key="submit" type="primary"  onClick={tempOnOk}>
                    Zapisz
                </Button>,
            ]}
            style={{top: 10}}
        >
            <Form form={mainForm} /*initialValues={defaultValues}*/>

                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Grupa"}>
                            <Form.Item name={"group"} initialValue={defaultValues.group.id} noStyle>
                                <Select style={{width: "90%"}}>
                                    {groupsTypes}
                                </Select>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Grupa uprawnień"}>
                            <Form.Item name={"permission"} initialValue={defaultValues.permission.id} noStyle>
                                <Select style={{width: "90%"}}>
                                    {permissionsTypes}
                                </Select>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

EditUserModal.propTypes = {

};

export default EditUserModal;