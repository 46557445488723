import React, {Fragment} from 'react';
import {getTableFilterDataClient, getTableFilterDataFromStatusObject} from "../assets/scripts/uiHelpers";
import {Table, Tag} from "antd";
import ProjectValuationTable from "./ProjectValuationTable";
import * as moment from "moment";

const PrintProjectTable = ({data, editActions}) => {
    let columns = [
        {
            title: 'Akcje',
            key: 'actions',
            render: (text, record) => { return(
                <Fragment>
                    <a href="#none" style={{margin:3}} onClick={()=>editActions.onClick(record.id)}>Edytuj</a>
                    
                </Fragment>
            )},
            width: "150px"
        },
        {
            title: "Tytuł",
            dataIndex: "projectName",
            key: "projectName",
            sorter: (a,b) => ('' + a.projectName).localeCompare(b.projectName),
            width: "200px"
        },
        {
            title: "Klient",
            dataIndex: "client",
            key: "client",
            filters: getTableFilterDataClient(data, "client"),
            onFilter: (value, record) => record.client.clientName === value,
            sorter: (a,b) => ('' + a.client.clientName).localeCompare(b.client.clientName),
            render: (element) => element.clientName,
            width: "150px"
        },
        {
            title: "Status projektu",
            dataIndex: "projectStatus",
            key: "projectStatus",
            filters: getTableFilterDataFromStatusObject(data, "projectStatus"),
            onFilter: (value, record) => record.projectStatus === value,
            width: "150px"
        },
        {
            title: "Status realizacji",
            dataIndex: "stepStatus",
            key: "stepStatus",
            filters: getTableFilterDataFromStatusObject(data, "stepStatus"),
            onFilter: (value, record) => record.stepStatus === value,
            render: (text) => <Tag color={"orange"}>{text}</Tag>,
            width: "250px"
        },
        {
            title: "Data zapytania",
            dataIndex: "requestDate",
            key: "requestDate",
            // eslint-disable-next-line
            render: (text) => text != undefined ? moment(text).format("DD-MM-YYYY") : "-",
            sorter: (a,b) => ('' + a.requestDate).localeCompare(b.requestDate),
            width: "150px"
        },
        {
            title: "Wymagana akceptacja plików",
            dataIndex:'requiredFileAcceptDate',
            key: "requiredFileAcceptDate",
            // eslint-disable-next-line
            render: (text) => text != undefined ?  moment(text).format("DD-MM-YYYY") : "-",
            width: "150px"
        },
        {
            title: "Akceptacja plików",
            dataIndex: "fileAcceptedDate",
            key: "fileAcceptedDate",
            sorter: (a,b) => ('' + a.fileAcceptedDate).localeCompare(b.fileAcceptedDate),
            // eslint-disable-next-line
            render: (text) => text != undefined ?  moment(text).format("DD-MM-YYYY") : "-",
            width: "150px"
        },
        {
            title: "Data wymagana dostawy",
            dataIndex: "requiredSupplyDate",
            key: "requiredSupplyDate",
            sorter: (a,b) => ('' + a.requiredSupplyDate).localeCompare(b.requiredSupplyDate),
            // eslint-disable-next-line
            render: (text) => text != undefined ?  moment(text).format("DD-MM-YYYY") : "-",
            width: "150px"
        },
        {
            title: "Data wysyłki",
            dataIndex: "fileSendedDate",
            key: "fileSendedDate",
            sorter: (a,b) => ('' + a.fileSendedDate).localeCompare(b.fileSendedDate),
            // eslint-disable-next-line
            render: (text) => text != undefined ?  moment(text).format("DD-MM-YYYY") : "-",
            width: "150px"
        },
        {
            title: "Data płatności",
            dataIndex: "paymentDate",
            key: "paymentDate",
            sorter: (a,b) => ('' + a.paymentDate).localeCompare(b.paymentDate),
            // eslint-disable-next-line
            render: (text) => text != undefined ?  moment(text).format("DD-MM-YYYY") : "-",
            width: "150px"
        },
        {
            title: "przelew",
            dataIndex: "isTransfer",
            key: "isTransfer",
            render: (text) => text === true? "Tak": "Nie",
            width: "150px"
        },
        {
            title: "Rozliczenie wysłane do Artura",
            dataIndex: "settlementSendedToArtur",
            key: "settlementSendedToArtur",
            // eslint-disable-next-line
            render: (text) => text != undefined ?  moment(text).format("DD-MM-YYYY") : "-",
            width: "150px"
        }


    ];


    return (
        <div>
            <Table
              size="small"
                expandable={{
                    expandedRowRender: record => <ProjectValuationTable record={record} recordData={record.valuations}/>,
                    rowExpandable: record => true,
                }}
                columns={columns}
                rowKey={"id"}
                dataSource={data}
                pagination={false}
                scroll={{
                   // y: "50vh",
                    x: "86vw"
                }}
            />
        </div>
    );
};

PrintProjectTable.propTypes = {

};

export default PrintProjectTable;
