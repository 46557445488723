import React from 'react';
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import AddonBefore from "./Layout/AddonBefore";
import {useDispatch, useSelector} from "react-redux";
import {AddNewUserAction} from "../actions/internal_api/users/AddNewUser";
import {GetAllUsersAction} from "../actions/internal_api/users/GetAllUsers";
import {GroupsSelector} from "../store/internal_api/groups/groupsReducer"
import {PermissionsSelector} from "../store/internal_api/permissions/permissionsReducer"

const {Option} = Select

const NewUserModal = ({visibility, onCancel}) => {

    const [mainForm] = Form.useForm();
    const dispatch = useDispatch();
    const groupData = useSelector(GroupsSelector)
    const permissionData = useSelector(PermissionsSelector)

    const groupsTypes = groupData.map(element =>{
        return <Option key={element.id} value={element.id}>{element.name}</Option>
    })

    const permissionsTypes = permissionData.map(element =>{
        return <Option key={element.id} value={element.id}>{element.name}</Option>
    })
    

    const tempOnOk = () =>{
        mainForm.validateFields().then((values) =>{
            values.isActive = true;
            dispatch(AddNewUserAction(values)).then(()=>{
                dispatch(GetAllUsersAction())
                onCancel()
            })
        })
    }

    return (
        <Modal
            title={"Nowy uzytkownik"}
            visible={visibility}
            maskClosable={false}
            onCancel={onCancel}
            width={"90%"}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Anuluj
                </Button>,
                <Button key="submit" type="primary"  onClick={tempOnOk}>
                    Utwórz
                </Button>,
            ]}
            style={{top: 10}}
        >
            <Form form={mainForm}>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Email"}>
                            <Form.Item name={"email"}>
                                <Input/>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Login"}>
                            <Form.Item name={"login"}>
                                <Input/>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Hasło"}>
                            <Form.Item name={"password"}>
                                <Input/>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Imię i nazwisko"}>
                            <Form.Item name={"name"}>
                                <Input/>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Grupa"}>
                            <Form.Item name={"group"} noStyle>
                                <Select style={{width: "90%"}}>
                                    {groupsTypes}
                                </Select>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
                <Row gutter={[24,24]}>
                    <Col span={12}>
                        <AddonBefore additionText={"Grupa uprawnień"}>
                            <Form.Item name={"permission"} noStyle>
                                <Select style={{width: "90%"}}>
                                    {permissionsTypes}
                                </Select>
                            </Form.Item>
                        </AddonBefore>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

NewUserModal.propTypes = {

};

export default NewUserModal;