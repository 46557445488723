import axios from 'axios'
import {mockupBackend} from "../../../consts/urls";
import {
  EDIT_PRINTINGHOUSE,
  EDIT_PRINTINGHOUSE_SUCCESS,
  EDIT_PRINTINGHOUSE_FAIL,
} from "../../../consts/actions"

export function ModifyPrintingHouseAction(printingHouseId, printingHouseData){
  return (dispatch) =>{
    dispatch({
      type: EDIT_PRINTINGHOUSE
    });
    return axios({
      method: "POST",
      data: printingHouseData,
      url: `${mockupBackend}printinghouse/modify/${printingHouseId}`
    }).then(response =>{
      dispatch({
        type: EDIT_PRINTINGHOUSE_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: EDIT_PRINTINGHOUSE_FAIL,
        error
      })
    })
  }
}