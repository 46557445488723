import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, TimePicker} from "antd";
import {ScrollableY} from "./styled/Layout";
import AddonBefore from "./Layout/AddonBefore";
import ProjectTypesStatus from "./ProjectTypes/ProjectTypesStatus";
import {
  AccessiblePdfStatusses,
  AudiobookStatuses,
  BookDTPStatuses,
  CoverStatuses,
  EpubDTPStatuses, OtherDTPStates
} from "../assets/mockup/data";
import {TaskNames} from "../assets/data/taskNames";
import { useSelector } from "react-redux";
import { UsersSelector } from '../store/internal_api/users/usersReducer';
import moment from "moment";

const {TextArea} = Input;
const {Option} = Select;

const AddTaskModal = ({visibility, addTask, onCancel, typeId, projectPartDefaultValues, projectId, projectType, defaultTaskWatcherId}) => {

  const [taskForm] = Form.useForm();
  const [projectStatusForm] = Form.useForm();

  const getDataFromForm = () =>{

    let newTaskData = {
      projectId: projectId,
      type: projectType,
      recordType: "work"
    };

    Promise.all([taskForm.validateFields(), projectStatusForm.validateFields()]).then((values)=>{
      newTaskData = {
        ...newTaskData,
        ...values[0]
      };
      
      
      let updatedConfig = projectPartDefaultValues.config;
      updatedConfig.projectTypePhase = values[1].projectTypePhase;

      let phaseResponse = {
        projectTypePhase: values[1].projectTypePhase,
        config: updatedConfig,
      }
      addTask(typeId, newTaskData, phaseResponse);
      onCancel();
    }).catch(()=>{
    })
  }

  const chooseProperTypeStatuses = () =>{
    switch(projectType){
      case "accessiblepdf" :
        return AccessiblePdfStatusses;
      case "audiobook":
        return AudiobookStatuses;
      case "book":
        return BookDTPStatuses;
      case "cover":
        return CoverStatuses;
      case "epub":
        return EpubDTPStatuses;
      case "other":
        return OtherDTPStates;
      default:
        return OtherDTPStates;
    }
  };

  const mapTaskNamesToOptions = (data) =>{
    return data.map((element)=>{
      return <Option key={element}  value={element}>{element}</Option>
    })
  }


  const workers = useSelector(UsersSelector).map(element =>{
    return <Option key={element.id} value={element.id}>{element.name}</Option>
  })
  
  return (
    <Modal
      title="Nowe zadanie"
      visible={visibility}
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={getDataFromForm}>
          Utwórz
        </Button>,
      ]}
      width={"90%"}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={taskForm}>
          <Row gutter={[24,24]}>
            <Col span={16}>
              <AddonBefore additionText={"Nazwa zadania"}>
                <Form.Item name={"taskName"} rules={[{ required: true, message: 'Wybierz nazwę zadania!' }]}>
                  <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Wybierz nazwę zadania"
                  >
                    {mapTaskNamesToOptions(TaskNames)}
                  </Select>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={16}>
              <AddonBefore additionText={"Data"}>
                <Form.Item name={"taskDate"} initialValue={moment()} rules={[{ required: true, message: 'Proszę wybrać datę zadania!' }]}>
                  <DatePicker style={{width:"80%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={16}>
              <AddonBefore additionText={"Opis zadania"}>
                <Form.Item name={"taskDescription"} noStyle>
                  <TextArea rows={4}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={16}>
              <AddonBefore additionText={"Suma godzin"}>
                <Form.Item name={"hoursOfWork"} rules={[{ required: true, message: 'Proszę wybrać ilość godzin dla zadania!' }]}>
                  <TimePicker format={'HH:mm'} minuteStep={15} />
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col>
              <AddonBefore additionText={"Osoba realizująca zadanie:"}>
                <Form.Item name={"taskWatcher"} initialValue={defaultTaskWatcherId !== undefined ? defaultTaskWatcherId : null} rules={[{ required: true, message: 'Proszę wybrać osobę realizującą zadanie!' }]}>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Wybierz osobę realizującą"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {workers}
                  </Select>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
        </Form>
        <Form form={projectStatusForm} initialValues={projectPartDefaultValues}>
          {<ProjectTypesStatus typesVariants={chooseProperTypeStatuses()}/>}
        </Form>
      </ScrollableY>
    </Modal>
  );
};

AddTaskModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  borrowData: PropTypes.func.isRequired,
  projectPartDefaultValues: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
};

export default AddTaskModal;
