export const DELETE_DAY_OFF = "DELETE_DAY_OFF";
export const DELETE_DAY_OFF_SUCCESS = "DELETE_DAY_OFF_SUCCESS";
export const DELETE_DAY_OFF_FAIL = "DELETE_DAY_OFF_FAIL";

export let DELETE_TYPE_DTP = "DELETE_TYPE_DTP";
export let DELETE_TYPE_DTP_SUCCESS = "DELETE_TYPE_DTP_SUCCESS";
export let DELETE_TYPE_DTP_FAIL = "DELETE_TYPE_DTP_FAIL";

export let DELETE_TASK_DTP = "DELETE_TASK_DTP";
export let DELETE_TASK_DTP_SUCCESS = "DELETE_TASK_DTP_SUCCESS";
export let DELETE_TASK_DTP_FAIL = "DELETE_TASK_DTP_FAIL";
export let DELETE_PROJECT_DTP = "DELETE_PROJECT_DTP";
export let DELETE_PROJECT_DTP_SUCCESS = "DELETE_PROJECT_DTP_SUCCESS";
export let DELETE_PROJECT_DTP_FAIL = "DELETE_PROJECT_DTP_FAIL";
//locals

export const SET_EXTEND_ALL_PROJECTS = "SET_EXTEND_ALL_PROJECTS"
export const SET_EXPANDED_PROJECTS_IDS = "SET_EXPANDED_PROJECTS_IDS"

///locals

export let GET_PAGED_ARCHIVE_PROJECTS_DTP = "GET_PAGED_ARCHIVE_PROJECTS_DTP";
export let GET_PAGED_ARCHIVE_PROJECTS_DTP_SUCCESS = "GET_PAGED_ARCHIVE_PROJECTS_DTP_SUCCESS";
export let GET_PAGED_ARCHIVE_PROJECTS_DTP_FAIL = "GET_PAGED_ARCHIVE_PROJECTS_DTP_FAIL";
//WS
export const WS_CONNECTED = "WS_CONNECTED"
export const WS_DISCONNECTED = "WS_DISCONNECTED"
export const WS_RECIVED_UPDATED_DTP_PROJECT = "WS_RECIVED_UPDATED_DTP_PROJECT"

export const PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK = "PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK";
export const PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_SUCCESS = "PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_SUCCESS";
export const PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_FAIL = "PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_FAIL"
export const PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_RESET = "PROJECT_INTERNAL_ID_INDYWIDUALITY_CHECK_RESET"

export let GET_TASKS_OF_USER_FAIL = "GET_TASKS_OF_USER_FAIL";
export let GET_TASKS_OF_USER_SUCCESS = "GET_TASKS_OF_USER_SUCCESS";
export let GET_TASKS_OF_USER = "GET_TASKS_OF_USER";
export const GENERATE_REPORT = "GENERATE_REPORT";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_FAIL = "GENERATE_REPORT_FAIL";

export let LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export let LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";
export let LOGOUT_USER = "LOGOUT_USER";

export const DISABLE_PRINTINGHOUSE = "DISABLE_PRINTINGHOUSE"
export const DISABLE_PRINTINGHOUSE_SUCCESS = "DISABLE_PRINTINGHOUSE_SUCCESS"
export const DISABLE_PRINTINGHOUSE_FAIL = "DISABLE_PRINTINGHOUSE_FAIL"

export const DELETE_CONTACT_PERSON_DRUK = "DELETE_CONTACT_PERSON_DRUK";
export const DELETE_CONTACT_PERSON_DRUK_SUCCESS = "DELETE_CONTACT_PERSON_DRUK_SUCCESS";
export const DELETE_CONTACT_PERSON_DRUK_FAIL = "DELETE_CONTACT_PERSON_DRUK_FAIL";

export const DELETE_CONTACT_PERSON_DTP = "DELETE_CONTACT_PERSON_DTP";
export const DELETE_CONTACT_PERSON_DTP_SUCCESS = "DELETE_CONTACT_PERSON_DTP_SUCCESS";
export const DELETE_CONTACT_PERSON_DTP_FAIL = "DELETE_CONTACT_PERSON_DTP_FAIL";


export const DELETE_VALUATION_DRUK = "DELETE_VALUATION_DRUK";
export const DELETE_VALUATION_DRUK_SUCCESS = "DELETE_VALUATION_DRUK_SUCCESS";
export const DELETE_VALUATION_DRUK_FAIL = "DELETE_VALUATION_DRUK_FAIL";

export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";
export const EDIT_USERS_SUCCESS = "EDIT_USERS_SUCCESS";
export const EDIT_USERS_FAIL = "EDIT_USERS_FAIL";
export const EDIT_USERS = "EDIT_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS = "GET_USERS";
export const NEW_USER_SUCCESS = "NEW_USER_SUCCESS";
export const NEW_USER_FAIL = "NEW_USER_FAIL";
export const NEW_USER = "NEW_USER";
export const EDIT_DAYS_OFF_FAIL = "EDIT_DAYS_OFF_FAIL";
export const EDIT_DAYS_OFF_SUCCESS = "EDIT_DAYS_OFF_SUCCESS";
export const EDIT_DAYS_OFF = "EDIT_DAYS_OFF";
export const ADD_NEW_DAY_OFF_FAIL = "ADD_NEW_DAY_OFF_FAIL";
export const ADD_NEW_DAY_OFF_SUCCESS = "ADD_NEW_DAY_OFF_SUCCESS";
export const ADD_NEW_DAY_OFF = "ADD_NEW_DAY_OFF ";
export const GET_DAYS_OFF_FAIL = "GET_DAYS_OFF_FAIL";
export const GET_DAYS_OFF_SUCCESS = "GET_DAYS_OFF_SUCCESS";
export const GET_DAYS_OFF = "GET_DAYS_OFF";
export const EDIT_DAYS_OFF_PLAN_FAIL = "EDIT_DAYS_OFF_PLAN_FAIL";
export const EDIT_DAYS_OFF_PLAN_SUCCESS = "EDIT_DAYS_OFF_PLAN_SUCCESS";
export const EDIT_DAYS_OFF_PLAN = "EDIT_DAYS_OFF_PLAN";
export const GET_DAYS_OFF_PLAN_FAIL = "GET_DAYS_OFF_PLAN_FAIL";
export const GET_DAYS_OFF_PLAN_SUCCESS = "GET_DAYS_OFF_PLAN_SUCCESS";
export const GET_DAYS_OFF_PLAN = "GET_DAYS_OFF_PLAN";
export const EDIT_PRINTINGHOUSE_FAIL = "EDIT_PRINTINGHOUSE_FAIL";
export const EDIT_PRINTINGHOUSE_SUCCESS = "EDIT_PRINTINGHOUSE_SUCCESS";
export const EDIT_PRINTINGHOUSE = "EDIT_PRINTINGHOUSE";
export const NEW_PRINTINGHOUSE_SUCCESS = "NEW_PRINTINGHOUSE_SUCCESS";
export const NEW_PRINTINGHOUSE_FAIL = "NEW_PRINTINGHOUSE_FAIL";
export const NEW_PRINTINGHOUSE = "NEW_PRINTINGHOUSE";
export const GET_PRINTING_HOUSES_FAIL = "GET_PRINTING_HOUSES_FAIL";
export const GET_PRINTING_HOUSES_SUCCESS = "GET_PRINTING_HOUSES_SUCCESS";
export const GET_PRINTING_HOUSES = "GET_PRINTING_HOUSES";

export const GET_CLIENTS_DTP_FAIL = "GET_CLIENTS_DTP_FAIL";
export const GET_CLIENTS_DTP_SUCCESS = "GET_CLIENTS_DTP_SUCCESS";
export const GET_CLIENTS_DTP = "GET_CLIENTS_DTP";
export const EDIT_CLIENT_DTP_FAIL = "EDIT_CLIENT_DTP_FAIL";
export const EDIT_CLIENT_DTP_SUCCESS = "EDIT_CLIENT_DTP_SUCCESS";
export const EDIT_CLIENT_DTP = "EDIT_CLIENT_DTP";
export const NEW_CLIENT_DTP_FAIL = "NEW_CLIENT_DTP_FAIL";
export const NEW_CLIENT_DTP_SUCCESS = "NEW_CLIENT_DTP_SUCCESS";
export const NEW_CLIENT_DTP = "NEW_CLIENT_DTP";

export const GET_CLIENTS_DRUK_FAIL = "GET_CLIENTS_DRUK_FAIL";
export const GET_CLIENTS_DRUK_SUCCESS = "GET_CLIENTS_DRUK_SUCCESS";
export const GET_CLIENTS_DRUK = "GET_CLIENTS_DRUK";
export const EDIT_CLIENT_DRUK_FAIL = "EDIT_CLIENT_DRUK_FAIL";
export const EDIT_CLIENT_DRUK_SUCCESS = "EDIT_CLIENT_DRUK_SUCCESS";
export const EDIT_CLIENT_DRUK = "EDIT_CLIENT_DRUK";
export const NEW_CLIENT_DRUK_FAIL = "NEW_CLIENT_DRUK_FAIL";
export const NEW_CLIENT_DRUK_SUCCESS = "NEW_CLIENT_DRUK_SUCCESS";
export const NEW_CLIENT_DRUK = "NEW_CLIENT_DRUK";


export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAIL = "ADD_TASK_FAIL";
export const MODIFY_TASK_DTP = "MODIFY_TASK_DTP";
export const MODIFY_TASK_DTP_SUCCESS = "MODIFY_TASK_DTP_SUCCESS";
export const MODIFY_TASK_DTP_FAIL = "MODIFY_TASK_DTP_FAIL";
export const MODIFY_PROJECT_WATCHER_AND_PHASE = "MODIFY_PROJECT_WATCHER_AND_PHASE";
export const MODIFY_PROJECT_WATCHER_AND_PHASE_SUCCESS = "MODIFY_PROJECT_WATCHER_AND_PHASE_SUCCESS";
export const MODIFY_PROJECT_WATCHER_AND_PHASE_FAIL = "MODIFY_PROJECT_WATCHER_AND_PHASE_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const GET_ALL_PROJECTS_DTP = "GET_ALL_PROJECTS_DTP";
export const GET_ALL_PROJECTS_DTP_SUCCESS = "GET_ALL_PROJECTS_DTP_SUCCESS";
export const GET_ALL_PROJECTS_DTP_FAIL = "GET_ALL_PROJECTS_DTP_FAIL";
export const NEW_PROJECT_DTP = "NEW_PROJECT_DTP";
export const NEW_PROJECT_DTP_SUCCESS = "NEW_PROJECT_DTP_SUCCESS";
export const NEW_PROJECT_DTP_FAIL = "NEW_PROJECT_DTP_FAIL";
export const MODIFY_PROJECT_DTP = "MODIFY_PROJECT_DTP";
export const MODIFY_PROJECT_DTP_SUCCESS = "MODIFY_PROJECT_DTP_SUCCESS";
export const MODIFY_PROJECT_DTP_FAIL = "MODIFY_PROJECT_DTP_FAIL";

export const GET_ALL_PROJECTS_DRUK = "GET_ALL_PROJECTS_DRUK";
export const GET_ALL_PROJECTS_DRUK_SUCCESS = "GET_ALL_PROJECTS_DRUK_SUCCESS";
export const GET_ALL_PROJECTS_DRUK_FAIL = "GET_ALL_PROJECTS_DRUK_FAIL";
export const NEW_PROJECT_DRUK = "NEW_PROJECT_DRUK";
export const NEW_PROJECT_DRUK_SUCCESS = "NEW_PROJECT_DRUK_SUCCESS";
export const NEW_PROJECT_DRUK_FAIL = "NEW_PROJECT_DRUK_FAIL";
export const MODIFY_PROJECT_DRUK = "MODIFY_PROJECT_DRUK";
export const MODIFY_PROJECT_DRUK_SUCCESS = "MODIFY_PROJECT_DRUK_SUCCESS";
export const MODIFY_PROJECT_DRUK_FAIL = "MODIFY_PROJECT_DRUK_FAIL";

export const GET_PAGED_PROJECTS_DTP = "GET_PAGED_PROJECTS_DTP";
export const GET_PAGED_PROJECTS_DTP_SUCCESS = "GET_PAGED_PROJECTS_DTP_SUCCESS";
export const GET_PAGED_PROJECTS_DTP_FAIL = "GET_PAGED_PROJECTS_DTP_FAIL"

export const GENERATE_VALUATION = "GENERATE_VALUATION";
export const GENERATE_VALUATION_SUCCESS = "GENERATE_VALUATION_SUCCESS";
export const GENERATE_VALUATION_FAIL = "GENERATE_VALUATION_FAIL";