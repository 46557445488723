import axios from "axios"
import {GET_ALL_PROJECTS_DRUK, GET_ALL_PROJECTS_DRUK_SUCCESS, GET_ALL_PROJECTS_DRUK_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function GetAllProjectsDrukAction(){
  return (dispatch) =>{
    dispatch({
      type: GET_ALL_PROJECTS_DRUK
    });
    return axios({
      method: "GET",
      url: `${mockupBackend}projectdruk/all`
    }).then(response =>{
      dispatch({
        type: GET_ALL_PROJECTS_DRUK_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: GET_ALL_PROJECTS_DRUK_FAIL,
        error
      })
    })
  }
}