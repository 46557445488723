//SORTER VALUES

export const INTERNALID_ASCENDING = "INTERNALID_ASCENDING"
export const INTERNALID_DESCENDING = "INTERNALID_DESCENDING"
export const PROJECTNAME_ASCENDING = "PROJECTNAME_ASCENDING"
export const PROJECTNAME_DESCENDING = "PROJECTNAME_DESCENDING"
export const ISBN_ASCENDING = "ISBN_ASCENDING"
export const ISBN_DESCENDING = "ISBN_DESCENDING"
export const BEGINDATE_ASCENDING = "BEGINDATE_ASCENDING"
export const BEGINDATE_DESCENDING = "BEGINDATE_DESCENDING"
export const ID_ASCENDING = "ID_ASCENDING"
export const ID_DESCENDING = "ID_DESCENDING"
export const CLIENT_ASCENDING = "CLIENT_ASCENDING"
export const CLIENT_DESCENDING = "CLIENT_DESCENDING"
