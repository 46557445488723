import React, {Component} from 'react';
import {Button, Card, Col, Divider, Input, Row} from "antd";
import {RightToLeft} from "../../../components/styled/Layout";
import {ClientsDrukSelector} from "../../../store/internal_api/clients/clientsDrukReducer";
import {GetAllClientsDrukAction} from "../../../actions/internal_api/clients/GetAllClientsDrukAction";
import {connect} from "react-redux";
import PrintNewClientModal from "../../../components/PrintNewClientModal";
import PrintEditClientModal from "../../../components/PrintEditClientModal";
import PrintClientTable from "../../../components/PrintClientTable";

const DZIAL = "print";

function mapStateToProps(state) {
  return {
    clients: ClientsDrukSelector(state)
  }
}

function mapDispatchToProps(dispatch){
  return {
    getClients: ()=> dispatch(GetAllClientsDrukAction())
  }
}

class PrintClientListView extends Component {
  constructor() {
    super();
    this.state = {
      newClientVisibility: false,
      editClientVisibility: false,
      editedRecord: null
    }
  }

  componentDidMount() {
    this.props.getClients()
  }

  changeNewClientVisibility = (value) =>{
    this.setState({newClientVisibility: value})
  };

  changeEditClientVisibility = (rowData) =>{
    this.setState({editClientVisibility: true, editedRecord: rowData})
  };

  closeEditClient = () =>{
    this.setState({editClientVisibility: false, editedRecord: null})
  }

  onClick = () =>{
    alert("edit")
  };

  getOnlyOwnClients = (data) =>{
    return data.filter((element)=> element.clientType === DZIAL)
  };

  render() {
    return (
      <div>
        <Card>
          <Row gutter={[24,24]}>
            <Col span={8}>

            </Col>
            <Col span={8}>
              <Input placeholder={"Wpisz nazwę klienta"}/>
            </Col>
            <Col span={8}>
              <RightToLeft>
                <Button type={"primary"} onClick={()=> this.changeNewClientVisibility(true)}>Nowy klient</Button>
              </RightToLeft>
            </Col>
          </Row>
          <Divider/>
          <PrintClientTable data={this.props.clients} onEditClick={this.changeEditClientVisibility}/>
        </Card>
        { this.state.newClientVisibility
          ?
          <PrintNewClientModal clientType={ DZIAL } companySection="druk" visibility={ this.state.newClientVisibility }
                          onCancel={ () => this.changeNewClientVisibility(false) }/>
          :
          null
        }
        { this.state.editClientVisibility
          ?
          <PrintEditClientModal clientType={ DZIAL } companySection="druk" visibility={ this.state.editClientVisibility }
                           onCancel={ this.closeEditClient} defaultValues={this.state.editedRecord}/>
          :
          null
        }
      </div>
    );
  }
}

PrintClientListView.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps) (PrintClientListView);
