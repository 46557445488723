import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Select} from "antd";
import {Centered, ColoredBackground, ScrollableY} from "./styled/Layout";
import AddonBefore from "./Layout/AddonBefore";
import PrintBookProjectType from "./ProjectTypes/PrintBookProjectType";
import PrintOtherProjectType from "./ProjectTypes/PrintOtherProjectType";
import PrintProjectOffersView from "./PrintProjectOffersView";
import {useSelector} from "react-redux"
import {ClientsDrukSelector} from "../store/internal_api/clients/clientsDrukReducer"
import { GetMomentDateOrElseUndefined } from '../scripts/momentHelper';
import {PrintinghouseSelector} from "../store/internal_api/printingHouses/printingHouseReducer";

const {Option} = Select;

const PrintEditProjectModal = ({visibility, onOk, onCancel, setData, hookTypesData, defaultValues, isArchive}) => {

  const [exchangeRateValidation, setExchangeRateValidation] = useState({
    validateStatus: 'success',
    errorMsg: null,})

  const [mainForm] = Form.useForm();
  const [bookForm] = Form.useForm();
  const [otherForm] = Form.useForm();

  const printingHouses = useSelector(PrintinghouseSelector)

  const onPrintingHouseChange = (value, key) =>{
    const fields = mainForm.getFieldsValue();
    const {valuations} = fields;

    Object.assign(valuations[key], {valuationType: printingHouses.find(element => element.id === value).type})
    mainForm.setFieldsValue({valuations})
  }

  let [bookCheckbox, setBookCheckbox] = useState(!!defaultValues.isPrintBookEnabled);
  let [otherCheckbox, setOtherCheckbox] = useState(!!defaultValues.isPrintOtherEnabled);

  React.useEffect(()=>{
    mainForm.validateFields();
  });

  const isOneOfTypesTrue = () =>{
    return bookCheckbox || otherCheckbox ;
  };

  const clientsList = useSelector(ClientsDrukSelector).map(element =>{
    return <Option key={element.id} value={element.id}>{element.clientName}</Option>
  })


  const statusList = [
      <Option key={"nieprzesłane pliki"} value={"nieprzesłane pliki"}>nieprzesłane pliki</Option>,
    <Option key={"niezaakceptowane pliki"} value={"niezaakceptowane pliki"}>niezaakceptowane pliki</Option>
  ]

  const statusChooser = (mainFormData) =>{
    let statuses = {
      projectStatus: "-",
        
      stepStatus: mainFormData.stepStatus
    };

    /*
    IF : DATA PEZESLANIE PLIKOW /AKCEPTACJA PLIKOW == NULL -> "NIEPRZESLANE PLIKI/NIEZAAKCEPTOWANE PLIKKI"
    ELSE : -> "NIEWYSLANE"
      -> IF: DATA WYSYLKI != NULL -> "NIEZAPLACONE"
          -> IF: ROZLICZENIE WYSLANE DO ARTURA -> "ZAKONCZONE"

     */

    // eslint-disable-next-line
    let preSequelStep = mainFormData.fileAcceptedDate != undefined
    // eslint-disable-next-line
    let stepTwo = mainFormData.fileSendedDate != undefined
    let stepThree = mainFormData.isTransfer// != undefined


    if(preSequelStep) {
      statuses.stepStatus = "NIEWYSLANE"
      if (stepTwo) {
        statuses.stepStatus = "NIEZAPLACONE"
        if (stepThree) {
          statuses.stepStatus = "ZAPLACONE"
        }
      }
    }


    /* PROJECT STATUS */
    if(mainFormData.valuations === undefined || mainFormData.valuations.length === 0){
      statuses.projectStatus = "zapytanie"
        
    }
    else if(mainFormData.valuations.findIndex((element)=> element.isChoosed === true) !== -1){
      statuses.projectStatus = "zamówienie"
        
    }
    else{
      statuses.projectStatus = "oferta"
        
    }

    return statuses
  };

  const tempOnOk = () =>{
    let newProjectData = {
      dzial: "druk",
      id: defaultValues.id,
      types:[],
      projectStatus: {
        text: "W trakcie",
        color: "orange"
      },
      stepStatus: {
        text: "[potrzebuje_nazwy]",
        color: "red"
      },
    };

    Promise.all([
      mainForm.validateFields(),
      bookForm.validateFields(),
      otherForm.validateFields()
    ]).then((values)=>{

      newProjectData = {
        ...newProjectData,
        ...values[0],
        key: values[0].projectNumber,
        internalId: values[0].projectNumber,
        ...statusChooser(values[0])
      };

      if(values[0].isPrintBookEnabled){
        newProjectData.types.push(values[1])
      }
      if(values[0].isPrintOtherEnabled){
        newProjectData.types.push(values[2])
      }

      newProjectData.types[0].id = defaultValues.types[0].id


    }).then(()=>{
      setData(newProjectData)
    })
  };

  const validateExchangeRate = (event) =>{
    if(/^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(event.target.value)){
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
    return {
      validateStatus: 'error',
      errorMsg: 'Liczba powinna składać się tylko z znaków 0 do 9 oraz opcjonalnie "." (kropka) jako separator reszty',
    };
  }

  const onExchangeRateChange = (value) =>{
    setExchangeRateValidation(validateExchangeRate(value))
  }

  return (
    <Modal
      title={"Nowy projekt"}
      visible={visibility}
      maskClosable={false}
      onCancel={onCancel}
      width={"90%"}
      footer={[
        <Button key="back" onClick={onCancel}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={tempOnOk}>
          Zapisz
        </Button>,
      ]}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={mainForm} /*initialValues={datesToMoment(defaultValues)}*/>
        <ColoredBackground color={"#dddddd"}>
          <Row gutter={[24,24]}>
            <Col span={24}>
              <Form.Item name={"projectName"} initialValue={defaultValues.projectName} rules={[{ required: true, message: 'Proszę wpisać nazwę projektu!' }]}>
              <Input disabled={isArchive} addonBefore={"Nazwa projektu"}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col>
              <Form.Item name={"isPrintBookEnabled"} initialValue={defaultValues.isPrintBookEnabled} valuePropName="checked" rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (isOneOfTypesTrue()) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                  },
                }),
              ]}>
              <Checkbox disabled={isArchive} onChange={(e)=> setBookCheckbox(e.target.checked).then(()=> mainForm.validateFields())}>Książka</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"isPrintOtherEnabled"} initialValue={defaultValues.isPrintOtherEnabled} valuePropName="checked" rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (isOneOfTypesTrue()) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                  },
                }),
              ]}>
              <Checkbox disabled={isArchive} onChange={(e)=> setOtherCheckbox(e.target.checked)} >Inne</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={8}>
              <Form.Item name={"projectNumber"} initialValue={defaultValues.projectNumber}>
                <Input disabled={isArchive} addonBefore={"Nr projektu"}/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Klient:"}>
                <Form.Item name={"client"} initialValue={defaultValues.client.id} rules={[{ required: true, message: 'Proszę zaznaczyć klienta!' }]}>
                <Select
                  showSearch
                  disabled={isArchive}
                  style={{ width: '100%' }}
                  placeholder="Wybierz klienta"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {clientsList}
                </Select>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Status:"}>
                <Form.Item  name={"stepStatus"} initialValue={defaultValues.stepStatus} rules={[{ required: true, message: 'Proszę zaznaczyć status!' }]}>
                  <Select
                      disabled={isArchive}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Wybierz początkowy status"
                  >
                    {statusList}
                  </Select>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
        </ColoredBackground>
        <ColoredBackground color={"#adeecf"}>
          <Divider>Status</Divider>
          <Row gutter={[24,24]}>
            <Col span={8}>
              <AddonBefore additionText={"Data zapytania:"}>
                <Form.Item name={"requestDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.requestDate)}>
                <DatePicker disabled={isArchive}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Data oferty do klienta:"}>
                <Form.Item name={"offerToClientDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.offerToClientDate)}>
                <DatePicker disabled={isArchive}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Data zamówienia:"}>
                <Form.Item name={"orderDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.orderDate)}>
                <DatePicker disabled={isArchive}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Divider>Harmonogram</Divider>
          <Row gutter={[24,24]}>
            <Col span={8}>
              <AddonBefore additionText={"Wymagane przesłanie plików:"}>
                <Form.Item noStyle={true} name={"requiredSendFileDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.requiredSendFileDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Wymagana akceptacja plików:"}>
                <Form.Item noStyle={true} name={"requiredFileAcceptDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.requiredFileAcceptDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Wymagana data wysyłki:"}>
                <Form.Item noStyle={true} name={"requiredSendDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.requiredSendDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={8}>
              <AddonBefore additionText={"Data wymagana dostawy:"}>
                <Form.Item noStyle={true} name={"requiredSupplyDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.requiredSupplyDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Data płatności:"}>
                <Form.Item noStyle={true} name={"paymentDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.paymentDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Data przesłanie plików:"}>
                <Form.Item noStyle={true} name={"sendFileDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.sendFileDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={8}>
              <AddonBefore additionText={"Akceptacja plików:"}>
                <Form.Item noStyle={true} name={"fileAcceptedDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.fileAcceptedDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Data wysyłki:"}>
                <Form.Item noStyle={true} name={"fileSendedDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.fileSendedDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
            <Col span={8}>
              <AddonBefore additionText={"Data dostarczenia:"}>
                <Form.Item noStyle={true} name={"arrivalDate"} initialValue={GetMomentDateOrElseUndefined(defaultValues.arrivalDate)}>
                  <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={8}>
              <Centered>
                <AddonBefore additionText={"Rozliczenie wysłane do Artura:"}>
                  <Form.Item noStyle={true} name={"settlementSendedToArtur"} initialValue={GetMomentDateOrElseUndefined(defaultValues.settlementSendedToArtur)}>
                    <DatePicker disabled={isArchive} style={{width: "100%"}}/>
                  </Form.Item>
                </AddonBefore>
              </Centered>
            </Col>
            <Col span={8}>

            </Col>
            <Col span={8}>
              <Centered>
                <Form.Item name={"isTransfer"} initialValue={defaultValues.isTransfer} valuePropName="checked">
                  <Checkbox>Przelew</Checkbox>
                </Form.Item>
              </Centered>
            </Col>
          </Row>
        </ColoredBackground>
        <Divider>Typy</Divider>
        {!bookCheckbox && !otherCheckbox
          ?
          <Centered>
            Nie wybrano typu
          </Centered>
          :
          null
        }
        {bookCheckbox
        ?
          <ColoredBackground color={"#aee6e6"}>
            <PrintBookProjectType isArchive={isArchive} form={bookForm} defaultValues={defaultValues.types}/>
          </ColoredBackground>
        :
        null}
        {otherCheckbox
        ?
          <ColoredBackground color={"#ffd5cd"}>
            <PrintOtherProjectType isArchive={isArchive} form={otherForm} defaultValues={defaultValues.types}/>
          </ColoredBackground>
        :
        null}
        <ColoredBackground color={'#fff3b2'}>
          <Divider>Szczegóły zamówienia</Divider>
          <Row gutter={[24,24]}>
            <Col span={4}>
              Numer faktury
            </Col>
            <Col span={20}>
              <Form.Item name={"invoiceName"} initialValue={defaultValues.invoiceName}>
              <Input disabled={isArchive}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={4}>
              Kwota faktury
            </Col>
            <Col span={20}>
              <Form.Item name={"invoiceCost"} initialValue={defaultValues.invoiceCost}>
              <Input disabled={isArchive}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={4}>
              Kurs waluty
            </Col>
            <Col span={20}>
              <Form.Item name={"exchangeRate"} initialValue={defaultValues.exchangeRate} validateStatus={exchangeRateValidation.validateStatus} help={exchangeRateValidation.errorMsg}>
                <Input onChange={onExchangeRateChange} />
              </Form.Item>
            </Col>
          </Row>
        </ColoredBackground>
        <ColoredBackground color={"#a7c5eb"}>
          <Divider>Oferty</Divider>
          <PrintProjectOffersView isArchive={isArchive} valuations={defaultValues.valuations} onPrintingHouseChange={onPrintingHouseChange}/>
        </ColoredBackground>
        </Form>
      </ScrollableY>
    </Modal>
  );
};

PrintEditProjectModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired
};

export default PrintEditProjectModal;
