import React from 'react';
import {Table, Tag} from "antd";
import ProjectTypeTable from "./styled/ProjectTypeTable";

const DTPArchiveTable = ({data, editActions, isLoading, currentItems, expandRows}) => {

  const getMockupOfProject = (record) =>{
    let bookTypeIndex = record.types.findIndex((element) => element.config.internalType === "book" );
    if(bookTypeIndex !== -1){
      return record.types[bookTypeIndex].config.mockup
    }
    else{
      return "-"
    }

  };

  let columns = [
    {
      title: 'Akcje',
      key: 'actions',
      render: ( _, row) => <a href={"#none"} onClick={()=>editActions.onClick(row.id)}>Edytuj</a>,
    },
    {
      title: "ID Projektu",
      dataIndex: "internalId",
      key: "internalId",
    },
    {
      title: `Nazwa projektu (${currentItems})`,
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: () => <Tag color={"green"}>{"Zakończono"}</Tag>
    },
    {
      title: "Klient",
      dataIndex: "client",
      key: "client",
      render: (element) => element.clientName
    },
    {
      title: "ISBN",
      dataIndex:'isbn',
      key: "isbn",
    },
    {
      title: "Data rozpoczęcia",
      dataIndex: "beginDate",
      key: "beginDate",
    },
    {
      title: "Data zakończenia",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Makieta",
      dataIndex: "mockup",
      key: "mockup",
      render: (text, record) => getMockupOfProject(record)

    },
    {
      title: "Zarchiwizowano pliki",
      dataIndex: "filesAreArchived",
      key: "filesAreArchived",
    }

  ];

  const paintRow = (record) =>{
    let archivedSrc = true;
    for(let i = 0; i < record.types.length; i++){
        if(record.types[i].pathToArchivedFiles === ""){
          archivedSrc = false
        }
    }
    if (!archivedSrc) {
      return `row-color-red`;
    }
    else{
      return null;
    }
  };


  return (
    <Table
      rowClassName={paintRow}
      key={String(expandRows)}
      expandable={{
        expandedRowRender: (record, index) => <ProjectTypeTable fullData={record} contactPerson={record.contactPerson}  projectId={record.id} projectIndex={index} recordData={record.types}/>,
        rowExpandable: record => true,
        defaultExpandedRowKeys: expandRows
      }}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="id"
      scroll={{
        //y: "60vh",
        x: "86vw"
      }
      }
      size="small"
      loading={isLoading}
    />
  )
};

DTPArchiveTable.propTypes = {

};

export default DTPArchiveTable;
