import React from 'react';
import {useSelector} from "react-redux";
import {LoggedInUserSelector} from "../store/internal_api/LoginUser";

import moment from "moment"
import {AllProjectsDtpSelector} from "../store/internal_api/Projects/AllDtpProjectsReducer";

const TodayHoursCounterDtp = props => {

    const loggedInUser = useSelector(LoggedInUserSelector)
    const dtpProjects = useSelector(AllProjectsDtpSelector)

    const todayTasks = []
    let todayHours = moment().set('hour', 0).set('minute', 0).set('second', 0);

    dtpProjects.forEach(project => {
        project.types.forEach(type => {
            type.tasks.forEach(task => {
                if (task.taskWatcher.id === loggedInUser.user.id && moment(task.taskDate).isSame(moment(), 'day')) {
                    todayTasks.push(task)
                }
            })
        })
    })
    todayTasks.forEach(task => {
        todayHours.add(moment(task.hoursOfWork).hour(), "hours")
        todayHours.add(moment(task.hoursOfWork).minute(), "minutes")
    })



    return (
        <div style={{color: "white"}}>
            Ilośc dodanych dzisiaj zadań: {todayTasks.length}. Łączna wartość godzin: {todayHours.format("HH:mm")}
        </div>
    );
};

TodayHoursCounterDtp.propTypes = {

};

export default TodayHoursCounterDtp;
