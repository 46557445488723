import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://60bbd9a88acd4f92980eaadfd1058b22@o818244.ingest.sentry.io/5807766",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}


ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

/*const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
)*/


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
