import React, {useState} from 'react';
import {Input} from "antd";
import Modal from "antd/es/modal/Modal";
import {useSelector} from "react-redux";
import {LoggedInUserSelector} from "../store/internal_api/LoginUser";

const ModalWithInputVerification = ({children, onAccept, onCancel, trueValue, blackList = []}) => {



    const [showModal, setShowModal] = useState(false)
    const [inputValue, setInputValue] = useState("")

    /*const onClickSetModalVisibility = () =>{
        setShowModal((prevState => !prevState))
    }*/

    const loggedInUser = useSelector(LoggedInUserSelector)

    //children.onClick = onClickSetModalVisibility

    const handleAccept = () =>{
        onAccept()
        setShowModal(false)
    }

    const handleCancel = () =>{
        onCancel()
        setShowModal(false)
    }


    return (
        <div>
            <div onClick={()=> setShowModal(true)}>

                {!blackList.includes(loggedInUser.user.permission.name) ? children : null}
            </div>
            <Modal maskClosable={false} title="Usunięcie elementu" visible={showModal} onOk={handleAccept} onCancel={handleCancel} okButtonProps={{disabled: inputValue !== trueValue}}>
                <p>
                    Ta operacja spowoduje <strong style={{color: "red"}}>nieodwracalne usunięcie rekordu</strong>,
                    aby kontynuować tą operację wpisz podaną frazę pod spodem:
                    <p><strong>{trueValue}</strong></p>
                </p>
                <Input onChange={(event)=> setInputValue(event.target.value)}/>
            </Modal>
        </div>
    );
};

ModalWithInputVerification.propTypes = {

};

export default ModalWithInputVerification;
