import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Popover, Row, Select} from "antd";
import BookDtpProjectType from "./ProjectTypes/BookDTPProjectType";
import CoverProjectType from "./ProjectTypes/CoverProjectType";
import EpubDtpProjectType from "./ProjectTypes/EpubDTPProjectType";
import OtherDtpProjectType from "./ProjectTypes/OtherDTPProjectType";
import AddonBefore from "./Layout/AddonBefore";
import {Centered, ColoredBackground, ScrollableY} from "./styled/Layout";
import AccessiblePdfType from "./ProjectTypes/AccessiblePDFType";
import AudiobookDtpProjectType from "./ProjectTypes/AudiobookDTPProjectType";
import {useDispatch, useSelector} from 'react-redux';
import { ClientsDtpSelector } from '../store/internal_api/clients/clientsDtpReducer';
import moment from "moment";
import {CheckIfProjectWithGivenClientAndInternalIdExist} from "../actions/internal_api/Projects/CheckIfProjectWithGivenClientAndInternalIdExist";
import {ProjectInternalIdIndividualityCheckSelector} from "../store/internal_api/Projects/ProjectInternalIdIndividualityCheckReducer";
import {ResetProjectInternalIdChecker} from "../actions/store_utils/ResetProjectInternalIdChecker"

const {Option} = Select;

const NewProjectModal = ({visibility, onOk, onCancel}) => {

  const dispatch = useDispatch();

  const [mainForm] = Form.useForm();
  const [accessiblePDFForm] = Form.useForm();
  const [audiobookForm] = Form.useForm();
  const [bookForm] = Form.useForm();
  const [coverForm] = Form.useForm();
  const [epubForm] = Form.useForm();
  const [otherForm] = Form.useForm();


  let internalIdExist = useSelector(ProjectInternalIdIndividualityCheckSelector)

  React.useEffect(()=>{
    mainForm.validateFields();
    accessiblePDFForm.validateFields();
    audiobookForm.validateFields();
    coverForm.validateFields();
    epubForm.validateFields();
    bookForm.validateFields();
    otherForm.validateFields();
  });

  let [coverCheckboxState, setCoverCheckboxState] = useState(false);
  let [bookCheckboxState, setBookCheckboxState] = useState(false);
  let [audiobookCheckboxState, setAudiobookCheckboxState] = useState(false);
  let [otherCheckboxState, setOtherCheckboxState] = useState(false);
  let [epubCheckboxState, setEpubCheckboxState] = useState(false);
  let [accessibleCheckboxState, setAccessibleCheckboxState] = useState(false);
  let [selectedClient, setSelectedClient] = useState(null);
  //let [selectedClientLastId, setSelectedClientLastId] = useState("");

  const isOneOfTypesTrue = () =>{
    return coverCheckboxState || bookCheckboxState || audiobookCheckboxState || otherCheckboxState || epubCheckboxState || accessibleCheckboxState;
  }

  const rawClients = useSelector(ClientsDtpSelector);

  const clientsList = rawClients.map(element =>{
    return <Option key={element.id} value={element.id}>{element.clientName}</Option>
  })
  /*const clientsList = [
    <Option key={"Kolofon"} value={"Kolofon"}>Kolofon</Option>,
    <Option key={"Have a Book"} value={"Have a Book"}>Have a Book</Option>,
    <Option key={"Microsoft"} value={"Microsoft"}>Microsoft</Option>,
    <Option key={"Gylendal"} value={"Gylendal"}>Gyldendal</Option>
  ];*/

  const contactPerson = selectedClient !== null
    ?
    rawClients.find(element => element.id === selectedClient).contactPersons.map(contact =>{
      return <Option key={contact.id} value={contact.id}>{contact.nameSurname}</Option>
    })
    :
    []

  /*const contactPerson = [
    <Option key={"el"+1} value={"Widar Trondsen"}>Widar Trondsen</Option>,
    <Option key={"el"+2} vale={"Jan Dohojda"}>Jan Dohojda</Option>,
    <Option key={"el"+3} value={"Cliff Bleszynski"}>Cliff Bleszynski</Option>,
    <Option key={"el"+4} value={"Gabe Newell"}>Gabe Newell</Option>
  ];*/

  /*const clientsID = {
    Kolofon: "KFN",
    HaveABook: "HAB",
    Microsoft: "MS",
    Gyldendal: "GL",
  };*/

  const handleClientChange = (value)=> {
    setSelectedClient(value);
    //setSelectedClientLastId(String(Math.floor(Math.random() * (400 - 1)) + 1));
  };




  /*const getSelectedClientId = () =>{
    return clientsID[selectedClient];
  };*/

 const tempOnOk = () =>{
   let newProjectData = {
     types: [],
     dzial: "dtp/it",
     status: "W trakcie"
   };

   Promise.all([
     mainForm.validateFields(),
     accessiblePDFForm.validateFields(),
     audiobookForm.validateFields(),
     bookForm.validateFields(),
     coverForm.validateFields(),
     epubForm.validateFields(),
     otherForm.validateFields()
   ]).then(values =>{

     newProjectData = {
       ...newProjectData,
       ...values[0],
       key: values[0].internalId,
     }

     if (values[0].isAccessiblePdfType) {
       values[1].internalType = "accessiblepdf";
       values[1].key = "accessiblepdf";
       values[1].tasks = [];
       newProjectData.types.push(values[1]);
     }

     if (values[0].isAudiobookType) {
       values[2].internalType = "audiobook";
       values[2].key = "audiobook";
       values[2].tasks = [];
       newProjectData.types.push(values[2]);
     }

     if (values[0].isBookType) {
       values[3].internalType = "book";
       values[3].key = "book";
       values[3].tasks = [];
       newProjectData.types.push(values[3]);
     }

     if (values[0].isCoverType) {
       values[4].internalType = "cover";
       values[4].key = "cover";
       values[4].tasks = [];
       newProjectData.types.push(values[4]);
     }

     if (values[0].isEpubType) {
       values[5].internalType = "epub";
       values[5].key = "epub";
       values[5].tasks = [];
       newProjectData.types.push(values[5]);
     }

     if (values[0].isOtherType) {
       values[6].internalType = "other";
       values[6].key = "other";
       values[6].tasks = [];
       newProjectData.types.push(values[6]);
     }
     onOk(newProjectData);
     resetProjectInternalIdChecker();
   }).catch((error)=>{
   })
 };

  const checkIfIdExist = (value) => {
    dispatch(CheckIfProjectWithGivenClientAndInternalIdExist(selectedClient, value));
    return undefined;
  }

  const resetProjectInternalIdChecker = () => {
    dispatch(ResetProjectInternalIdChecker())
  }

  const onCloseModal = () =>{
    onCancel();
    resetProjectInternalIdChecker();
  }

  return (
    <Modal
      title="Nowy projekt"
      visible={visibility}
      maskClosable={false}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={tempOnOk}>
          Utwórz
        </Button>,
      ]}
      width={"90%"}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={mainForm}>
          <ColoredBackground>
            <Row gutter={[24,24]}>
              <Col span={24}>
                <Form.Item name={"projectName"} rules={[{ required: true, message: 'Proszę wpisać nazwę projektu!' }]}>
                  <Input addonBefore={"Nazwa projektu:"}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24,24]}>
              <Col span={2}>
                <Form.Item name={"isCoverType"} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setCoverCheckboxState(e.target.checked)} disabled={epubCheckboxState || accessibleCheckboxState} >Okładka</Checkbox>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={"isBookType"} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setBookCheckboxState(e.target.checked)} disabled={epubCheckboxState || accessibleCheckboxState}>Książka</Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={"isAudiobookType"} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setAudiobookCheckboxState(e.target.checked)} disabled={epubCheckboxState || accessibleCheckboxState}>Audiobook</Checkbox>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={"isOtherType"} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox  onChange={(e)=> setOtherCheckboxState(e.target.checked)}>Inne</Checkbox>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name={"isEpubType"} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setEpubCheckboxState(e.target.checked)} disabled={coverCheckboxState || bookCheckboxState || audiobookCheckboxState}>Epub</Checkbox>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={"isAccessiblePdfType"} valuePropName="checked" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (isOneOfTypesTrue()) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Proszę zaznaczyć jedną z opcji!');
                    },
                  }),
                ]}>
                  <Checkbox onChange={(e)=> setAccessibleCheckboxState(e.target.checked)} disabled={coverCheckboxState || bookCheckboxState || audiobookCheckboxState}>Accessible pdf</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </ColoredBackground>
          <Divider>Klient</Divider>
          <ColoredBackground>
            <Row gutter={24}>
              <Col span={12}>
                <AddonBefore additionText={"Wybierz klienta:"} >
                  <Form.Item name={"client"} rules={[{ required: true, message: 'Proszę wybrać klienta!' }]}>
                    <Select
                      showSearch
                      onChange={handleClientChange}
                      style={{ width: '100%' }}
                      placeholder="Wybierz klienta"
                      optionFilterProp="children"

                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {clientsList}
                    </Select>
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={12}>
                <AddonBefore additionText={"Wybierz osobę kontaktową:"}>
                  <Form.Item name={"contactPerson"} rules={[{ required: true, message: 'Proszę wybrać osobę kontaktową!' }]}>
                    <Select
                      disabled={selectedClient === "null"}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Wybierz osobę kontaktową"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {contactPerson}
                    </Select>
                  </Form.Item>
                </AddonBefore>
              </Col>
            </Row>
          </ColoredBackground>
          <Divider>Ogólne dane</Divider>
          <ColoredBackground color={"#a3f7bf"}>
            <Row gutter={24}>
              <Col span={8}>
                <AddonBefore additionText={"ID projektu:"}>
                  <Popover
                    content={"Podany identyfikator dla wybranego klienta już istnieje"}
                    visible={internalIdExist}
                    >
                    <Form.Item name={"internalId"} rules={[{ required: true, message: 'Proszę wpisać ID projektu!' }]}>
                      <Input disabled={selectedClient === null} onChange={(event)=>checkIfIdExist(event.target.value)} />
                    </Form.Item>
                  </Popover>
                </AddonBefore>
              </Col>
              <Col span={8}>
                <Form.Item name={"orderNumber"}>
                  <Input addonBefore={"Nr zamówienia:"}/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={"isbn"}>
                  <Input addonBefore={"ISBN:"}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <AddonBefore additionText={"Data rozpoczęcia:"}>
                  <Form.Item nostyle name={"beginDate"} initialValue={moment(undefined)}>
                    <DatePicker style={{width:"100%"}}  />
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={8}>
                <AddonBefore additionText={"Data zakończenia:"}>
                  <Form.Item nostyle name={"endDate"}>
                    <DatePicker style={{width:"100%"}} />
                  </Form.Item>
                </AddonBefore>
              </Col>
              <Col span={8}>
                <Form.Item name={"author"}>
                  <Input addonBefore={"Autor:"}/>
                </Form.Item>
              </Col>
            </Row>
          </ColoredBackground>
        </Form>
          <Divider>Typy</Divider>
          { bookCheckboxState
            ?
            <ColoredBackground color={"#e0fffb"}>
              <BookDtpProjectType form={bookForm}/>
            </ColoredBackground>
            :
            null
          }
          { coverCheckboxState
            ?
            <ColoredBackground color={"#e4e5db"}>
              <CoverProjectType form={coverForm}/>
            </ColoredBackground>
            :
            null
          }
          {audiobookCheckboxState
          ?
            <ColoredBackground color={"#e0cffb"}>
              <AudiobookDtpProjectType form={audiobookForm}/>
            </ColoredBackground>
          :
          null}
          { epubCheckboxState
            ?
            <ColoredBackground color={"#fff6f6"}>
              <EpubDtpProjectType form={epubForm}/>
            </ColoredBackground>
            :
            null
          }
          { otherCheckboxState
            ?
            <ColoredBackground color={"#c3f584"}>
              <OtherDtpProjectType form={otherForm}/>
            </ColoredBackground>
            :
            null
          }
          { accessibleCheckboxState
            ?
            <ColoredBackground color={"#f3d1f4"}>
              <AccessiblePdfType form={accessiblePDFForm}/>
            </ColoredBackground>
            :
            null
          }
          {!accessibleCheckboxState && !otherCheckboxState && !audiobookCheckboxState && !epubCheckboxState && !coverCheckboxState && !bookCheckboxState
            ?
            <Row gutter={[24,24]}>
              <Col span={24}>
                <Centered>
                  Nie wybrano żadnego typu
                </Centered>
              </Col>
            </Row>
            :
            null
          }

      </ScrollableY>
    </Modal>
  );
};

NewProjectModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewProjectModal;
