import axios from "axios"
import {GET_PAGED_ARCHIVE_PROJECTS_DTP, GET_PAGED_ARCHIVE_PROJECTS_DTP_SUCCESS, GET_PAGED_ARCHIVE_PROJECTS_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";
import {ID_DESCENDING} from "../../../consts/apiConsts"

export function GetPagedArchiveProjectDtpAction(page = 0, size = 10, filterValue = "", containingBook = false,
                                         containingCover = false, containingOther = false,
                                         containingAudiobook = false, containingEpub = false, containingAPDF = false,
                                         sorter = ID_DESCENDING, clientFilter = 0, projectWatcher = 0,
                                         mockupFilter= "", typePhase= ""){
  let url = `${mockupBackend}projectdtp/paged?page=${page}&size=${size}&showOnlyArchived=true`;
  if(filterValue !== ""){
    url = url + `&filterValue=${filterValue}`;
  }
  if(containingCover){
    url = url + `&showCoverType=true`;
  }
  if(containingBook){
    url = url + `&showBookType=true`;
  }
  if(containingAudiobook){
    url = url + `&showAudiobookType=true`;
  }
  if(containingOther){
    url = url + `&showOtherType=true`;
  }
  if(containingEpub){
    url = url + `&showEpubType=true`;
  }
  if (containingAPDF){
    url = url + `&showAPDFType=true`
  }

  if(sorter !== ID_DESCENDING){
    url = url + `&sortType=${sorter}`
  }

  if(clientFilter !== 0){
    url = url + `&clientFilter=${clientFilter}`
  }
  if(projectWatcher !==0){
    url = url + `&watcherId=${projectWatcher}`
  }
  if(mockupFilter !== ""){
    url = url + `&mockupValue=${mockupFilter}`
  }
  if(typePhase !== ""){
    url = url + `&typePhaseValue=${typePhase}`
  }

  return (dispatch) =>{
    dispatch({
      type: GET_PAGED_ARCHIVE_PROJECTS_DTP
    });
    return axios({
      method: "GET",
      url: url
    }).then(response =>{
      dispatch({
        type: GET_PAGED_ARCHIVE_PROJECTS_DTP_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: GET_PAGED_ARCHIVE_PROJECTS_DTP_FAIL,
        error
      })
    })
  }
}
