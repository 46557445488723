import axios from "axios"
import {NEW_PROJECT_DTP, NEW_PROJECT_DTP_SUCCESS, NEW_PROJECT_DTP_FAIL} from "../../../consts/actions";
import {mockupBackend} from "../../../consts/urls";

export function AddNewProjectDtpAction(projectData){
  return (dispatch) =>{
    dispatch({
      type: NEW_PROJECT_DTP
    });
    return axios({
      method: "PUT",
      data: projectData,
      url: `${mockupBackend}projectdtp/new`
    }).then(response =>{
      dispatch({
        type: NEW_PROJECT_DTP_SUCCESS,
        data: response.data,
      })
    }).catch(error =>{
      dispatch({
        type: NEW_PROJECT_DTP_FAIL,
        error
      })
    })
  }
}