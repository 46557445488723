import React, {Component} from 'react';
import TaskTable from "../../../components/TaskTable";

//ToDo WTF
class TaskView extends Component {
    render() {
        return (
            <div>
                <TaskTable/>
            </div>
        );
    }
}

TaskView.propTypes = {};

export default TaskView;
