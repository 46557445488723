import React, {Component} from 'react';
import {DaysOffSelector} from "../../../store/internal_api/daysOff/DaysOffReducer";
import {LoggedInUserSelector} from "../../../store/internal_api/LoginUser";
import {GetAllDaysOffAction} from "../../../actions/internal_api/daysOff/GetAllDaysOffAction";
import {connect} from "react-redux";
import _ from "lodash"
import {Card, Col, Row, Tabs} from "antd";
import WorkingHoursViewDataOfDay from "../../../components/WorkingHoursViewDataOfDay";
import WorkingHoursUserHours from "../../../components/WorkingHoursUserHours";
import {UsersSelector} from "../../../store/internal_api/users/usersReducer";
import {DeleteDayOffAction} from "../../../actions/internal_api/daysOff/DeleteDayOffAction";

const {TabPane} = Tabs

function mapStateToProps(state) {
    return {
        daysOff: DaysOffSelector(state),
        loggedInUser: LoggedInUserSelector(state),
        appUsers: UsersSelector(state)
    }
}

function mapDispatchToProps(dispatch){
    return{
        getDaysOff: () => dispatch(GetAllDaysOffAction()),
        deleteDayOff: (id) => dispatch(DeleteDayOffAction(id))
    }
}

class OtherUsersVacationDays extends Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        this.props.getDaysOff().then(()=>{
            this.prepareUserData()
        });
    }


    prepareUserData = () =>{
        let dataByUsers = [];
        let uniqueUsers = _.uniqBy(this.props.daysOff, "worker.id").map(element => element.worker.name);
        const currentDate = new Date()


        uniqueUsers.forEach(element =>{
            let newElemData = this.props.daysOff.filter((el) => el.worker.name === element )

            const currentUserDaysOffLeave = newElemData
                .filter(element => new Date(element.dayStart).getFullYear() === currentDate.getFullYear())
                .filter(felement =>felement.worker.name === element)
                .filter(element => element.typeOfDayOff === "Urlop");

            let currentDays = this.props.appUsers.find(userElement => userElement.name === element).freeHoursInYear;
            let lastYearDays = this.props.appUsers.find(userElement => userElement.name === element).freeHoursInLastYear

            const subtractAvalibleDaysFromLastYear = (value) =>{
                let rest = 0
                lastYearDays = lastYearDays - value
                if(lastYearDays < 0){
                    rest = Math.abs(lastYearDays);
                    lastYearDays = 0
                }
                return rest;
            }

            currentUserDaysOffLeave.forEach(element =>{
                currentDays = currentDays - subtractAvalibleDaysFromLastYear(element.howMuchDays)
            })

            let newElem = {
                name: element,
                data: newElemData,
                currentDays: currentDays,
                lastYearDays: lastYearDays,
            }
            dataByUsers.push(newElem)
        })

        return dataByUsers
    }

    deleteDayOff = (dayOffId) =>{
        this.props.deleteDayOff(dayOffId).then(()=>{
            this.props.getDaysOff();
        })
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="1">
                {
                    this.prepareUserData().map(element =>{
                        return(
                            <TabPane tab={element.name} key={element.name}>
                                {this.props.loggedInUser.user.permission.name !== "admin" // admin nie ma urlopów
                                    ?
                                    <Card title={"Pozostałe dni urlopowe"} size="small">
                                        <Row gutter={[16,16]}>
                                            <Col span={4}>
                                                <Card title={"Ten rok"} size="small">
                                                    <h2>
                                                        {element.currentDays} dni
                                                    </h2>
                                                </Card>
                                            </Col>
                                            <Col span={4}>
                                                <Card title={"Poprzedni rok"} size="small">
                                                    <h2>
                                                        {element.lastYearDays} dni
                                                    </h2>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card>
                                    :
                                    null
                                }
                                <WorkingHoursViewDataOfDay data={element.data} onEditCLick={()=>alert("edit")} onDeleteClick={this.deleteDayOff} userIsNotWorker={true}/>
                            </TabPane>
                        )
                    })
                }
                </Tabs>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (OtherUsersVacationDays);