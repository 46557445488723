import React, {Fragment, useState} from 'react';
import {Button, Col, Row, Table, Tag} from "antd";
import AddAdditionalValuationModal from "./AddAdditionalValuationModal";


const ProjectValuationTable = ({recordData, record}) => {

    const [additionalValuationModal, setAdditionalValuationModal] = useState(false);

    const changeAdditionalValuationVisibility = (value) =>{
        setAdditionalValuationModal(value);
    };

    const columns = [
        {
            title: "Typ",
            dataIndex: "valuationType",
            key: "valuationType",
        },
        {
            title: "Drukarnia",
            dataIndex: "printingHouse",
            key: "printingHouse",
            render: (text) => text.name
        },
        {
            title: "Nakład",
            dataIndex: "circulation",
            key: "circulation",
        },
        {
            title: "Waluta wyceny",
            dataIndex: "currency",
            key: "currency",
        },
        {
            title: "Cena sztuka DRUKARNIA",
            dataIndex: "pricePerItemPrinter",
            key: "pricePerItemPrinter",
        },
        {
            title: "Cena sztuka klient",
            dataIndex: "pricePerItemClient",
            key: "pricePerItemClient",
        },
        {
            title: "Transport wartość",
            dataIndex: "transportValue",
            key: "transportValue",

        },
        {
            title: "Inne koszty warość",
            dataIndex: "otherCosts",
            key: "otherCosts",

        },
        {
            title: "Wycena do zamówienia",
            dataIndex: "isChoosed",
            key: "isChoosed",
            render: (text) => <Tag color={text === true? "green": "red"}>{text === true? "Tak" : "Nie"}</Tag>

        },
        {
            title: "Wartość klient",
            dataIndex: "clientValue",
            key: "clientValue",
        },
        {
            title: " ",
            width: 700
        },
    ];

    return (
        <Fragment>
            <Row>
                <Col span={24}>

                        <Button type={"primary"} onClick={()=> changeAdditionalValuationVisibility(true)}>Dodaj lub modyfikuj wyceny</Button>

                </Col>
            </Row>
            <Table
                size="small"
                columns={columns}
                dataSource={recordData}
                pagination={false}
            />
            {additionalValuationModal
              ?
                <AddAdditionalValuationModal record={record} defaultValues={ recordData } visibility={ additionalValuationModal }
                                             onCancel={ () => changeAdditionalValuationVisibility(false) }/>
              :
              null
            }
        </Fragment>
    );
};

ProjectValuationTable.propTypes = {

};

export default ProjectValuationTable;
