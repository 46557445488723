import React, {Fragment} from 'react';
import AddonBefore from "../Layout/AddonBefore";
import {Col, Divider, Form, Input, InputNumber, Row, Select} from "antd";
import PrintNewProjectArchiveSection from "./PrintNewProjectArchiveSection";

const {TextArea} = Input;
const {Option} = Select;

const PrintBookProjectType = ({defaultValues, form, isArchive}) => {

  const layoutTypes = [
    <Option key={"portret"} value={"portret"}>Portret</Option>,
    <Option key={"landscape"} value={"landscape"}>Landscape</Option>
  ]


  return (
    <Fragment>
      <Divider orientation={"left"}>Książka</Divider>
      <Form form={form} >
      <PrintNewProjectArchiveSection defaultValues={defaultValues}/>
      <Divider>Dane</Divider>
        <Row gutter={[24,24]}>
          <Col span={6}>
            <AddonBefore additionText={"Układ"}>
              <Form.Item  name={"layout"} noStyle initialValue={defaultValues !== undefined?defaultValues[0].config.layout: null}>
                <Select disabled={isArchive}>
                  {layoutTypes}
                </Select>
              </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={4}>
          <AddonBefore additionText="Podstawa:">
            <Form.Item name={"basis"} initialValue={defaultValues !== undefined?defaultValues[0].config.basis: null}>
            <InputNumber disabled={isArchive} />
            </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={4}>
          <AddonBefore additionText="Wysokość:">
            <Form.Item name={"height"} initialValue={defaultValues !== undefined?defaultValues[0].config.height: null}>
              <InputNumber disabled={isArchive} />
            </Form.Item>
            </AddonBefore>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
          </Col>
          <Col span={4}>
            Ilość
          </Col>
          <Col span={4}>
            Papier
          </Col>
          <Col span={4}>
            Zadruk
          </Col >
          <Col span={4}>
            Wykończenie
          </Col>
          <Col span={4}>
            Opis
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Mono
          </Col>
          <Col span={4}>
            <Form.Item  name={"monoNumberOf"} initialValue={defaultValues !== undefined?defaultValues[0].config.monoNumberOf: null}>
            <InputNumber disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"monoPaper"} initialValue={defaultValues !== undefined?defaultValues[0].config.monoPaper: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"monoOverprint"} initialValue={defaultValues !== undefined?defaultValues[0].config.monoOverprint: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col >
          <Col span={4}>
            <Form.Item name={"monoFinish"} initialValue={defaultValues !== undefined?defaultValues[0].config.monoFinish: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"monoDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.monoDescription: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Kolor
          </Col>
          <Col span={4}>
            <Form.Item name={"colorNumberOf"} initialValue={defaultValues !== undefined?defaultValues[0].config.colorNumberOf: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"colorPaper"} initialValue={defaultValues !== undefined?defaultValues[0].config.colorPaper: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"colorOverprint"} initialValue={defaultValues !== undefined?defaultValues[0].config.colorOverprint: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col >
          <Col span={4}>
            <Form.Item name={"colorFinish"} initialValue={defaultValues !== undefined?defaultValues[0].config.colorFinish: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"colorDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.colorDescription: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Wkładka
          </Col>
          <Col span={4}>
            <Form.Item name={"insertNumberOf"} initialValue={defaultValues !== undefined?defaultValues[0].config.insertNumberOf: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"insertPaper"} initialValue={defaultValues !== undefined?defaultValues[0].config.insertPaper: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"insertOverprint"} initialValue={defaultValues !== undefined?defaultValues[0].config.insertOverprint: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col >
          <Col span={4}>
            <Form.Item name={"insertFinish"} initialValue={defaultValues !== undefined?defaultValues[0].config.insertFinish: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"insertDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.insertDescription: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Wyklejka
          </Col>
          <Col span={4}>
            <Form.Item name={"insetNumberOf"} initialValue={defaultValues !== undefined?defaultValues[0].config.insetNumberOf: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"insetPaper"} initialValue={defaultValues !== undefined?defaultValues[0].config.insetPaper: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"insetOverprint"} initialValue={defaultValues !== undefined?defaultValues[0].config.insetOverprint: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col >
          <Col span={4}>
            <Form.Item name={"insetFinish"} initialValue={defaultValues !== undefined?defaultValues[0].config.insetFinish: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"insetDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.insetDescription: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Okładka
          </Col>
          <Col span={4}>

          </Col>
          <Col span={4}>
            <Form.Item name={"coverPaper"} initialValue={defaultValues !== undefined?defaultValues[0].config.coverPaper: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"coverOverprint"} initialValue={defaultValues !== undefined?defaultValues[0].config.coverOverprint: null}> 
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col >
          <Col span={4}>
            <Form.Item name={"coverFinish"} initialValue={defaultValues !== undefined?defaultValues[0].config.coverFinish: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"coverDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.coverDescription: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Obwoluta
          </Col>
          <Col span={4}>

          </Col>
          <Col span={4}>
            <Form.Item name={"wrapperPaper"} initialValue={defaultValues !== undefined?defaultValues[0].config.wrapperPaper: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"wrapperOverprint"} initialValue={defaultValues !== undefined?defaultValues[0].config.wrapperOverprint: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col >
          <Col span={4}>
            <Form.Item name={"wrapperFinish"} initialValue={defaultValues !== undefined?defaultValues[0].config.wrapperFinish: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={"wrapperDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.wrapperDescription: null}>
            <TextArea disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Oprawa
          </Col>
          <Col span={20}>
            <Form.Item name={"binding"} initialValue={defaultValues !== undefined?defaultValues[0].config.binding: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Tektura
          </Col>
          <Col span={20}>
            <Form.Item name={"cardboard"} initialValue={defaultValues !== undefined?defaultValues[0].config.cardboard: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Grzbiet
          </Col>
          <Col span={20}>
            <Form.Item name={"ridge"} initialValue={defaultValues !== undefined?defaultValues[0].config.ridge: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Kapitałka
          </Col>
          <Col span={20}>
            <Form.Item name={"headband"} initialValue={defaultValues !== undefined?defaultValues[0].config.headband: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Skrzydełka
          </Col>
          <Col span={20}>
            <Form.Item name={"wings"} initialValue={defaultValues !== undefined?defaultValues[0].config.wings: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Tasiemka
          </Col>
          <Col span={20}>
            <Form.Item name={"ribbon"} initialValue={defaultValues !== undefined?defaultValues[0].config.ribbon: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Suchy tłok
          </Col>
          <Col span={20}>
            <Form.Item name={"dryPlunger"} initialValue={defaultValues !== undefined?defaultValues[0].config.dryPlunger: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Folia
          </Col>
          <Col span={20}>
            <Form.Item name={"foil"} initialValue={defaultValues !== undefined?defaultValues[0].config.foil: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={4}>
            Opaska
          </Col>
          <Col span={20}>
            <Form.Item name={"band"} initialValue={defaultValues !== undefined?defaultValues[0].config.band: null}>
            <Input disabled={isArchive}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={11}>
            Dostawa:
          </Col>
          <Col span={11}>
            Pakowanie:
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={11}>
            <Form.Item name={"delivery"} initialValue={defaultValues !== undefined?defaultValues[0].config.delivery: null}>
            <TextArea disabled={isArchive} rows={5}/>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item name={"packing"} initialValue={defaultValues !== undefined?defaultValues[0].config.packing: null}>
            <TextArea disabled={isArchive} rows={5}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={8}>
            Inne informacje:
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          <Col span={24}>
            <Form.Item name={"otherDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.otherDescription: null}>
            <TextArea disabled={isArchive} rows={4}/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            Informacje wewnętrzne
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={"internalDescription"} initialValue={defaultValues !== undefined?defaultValues[0].config.internalDescription: null}>
            <TextArea disabled={isArchive} rows={3}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

PrintBookProjectType.propTypes = {

};

export default PrintBookProjectType;
