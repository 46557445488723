import React, {Fragment} from 'react';
import {Checkbox, Col, Divider, Form, Input, InputNumber, Row} from "antd";
import DtpProjectTypeCommonData from "./DtpProjectTypeCommonData";
import AddonBefore from "../Layout/AddonBefore";
import {Centered} from "../styled/Layout";
import NewProjectArchiveSection from "./NewProjectArchiveSection";
import {BookDTPStatuses} from "../../assets/mockup/data";
import ProjectTypesStatus from "./ProjectTypesStatus";

const {TextArea} = Input;

const BookDtpProjectType = ({defaultValues, form, archive}) => {
  let thisInitialValues = defaultValues !== undefined && defaultValues.length !== 0 ? defaultValues.filter((element)=>{return element.config.internalType === "book"}) : null;

  return (
    <Fragment>
      <Divider orientation={"left"}>Książka</Divider>
      <Form form={form} initialValues={thisInitialValues !== null && thisInitialValues.length !== 0 ? thisInitialValues[0].config : null} >
      <DtpProjectTypeCommonData archive={archive}/>
      <NewProjectArchiveSection />
        <ProjectTypesStatus archive={archive} typesVariants={BookDTPStatuses}/>
      <Divider>Dane</Divider>
        <Row gutter={[24, 24]} >
          <Col span={5}>
            <AddonBefore additionText="Nakład:">
            <Form.Item name={"circulation"}>
              <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={5}>
          <AddonBefore additionText="Cena startu:">
            <Form.Item name={"startPrice"}>
              <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={4}>
          <AddonBefore additionText="Ilość stron:">
            <Form.Item name={"numberOfPages"}>
              <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={4}>
          <AddonBefore additionText="Ilość kolumn">
            <Form.Item name={"numberOfColumns"}>
              <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={5}>
            <Form.Item name={"mockup"}>
              <Input disabled={archive} addonBefore={"Makieta:"}/>
            </Form.Item>
          </Col>
        </Row>
        <Row  gutter={[24, 24]}>
          <Col span={8}>
          <AddonBefore additionText="Ilość elementów graficznych:">
            <Form.Item name={"numberOfGraphicElements"}>
              <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
          <Col span={8}>
            <AddonBefore additionText={"Kolor:"}>
              <Form.Item noStyle name={"color"}>
                <Input disabled={archive}/>
              </Form.Item>
            </AddonBefore>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item name={"comment"}>
              <TextArea disabled={archive} placeholder={"Wpisz komentarz do typu zadania"} rows={4}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={6}>
          <AddonBefore additionText="Ilość przypisów">
            <Form.Item name={"numberOfFootnotes"}>
            <InputNumber disabled={archive} />
            </Form.Item>
            </AddonBefore>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Centered>
              <Form.Item name={"isMarginaliaChecked"} valuePropName="checked">
                <Checkbox disabled={archive} >Marginalia</Checkbox>
              </Form.Item>
            </Centered>
          </Col>
          <Col span={12}>
            <Centered>
              <Form.Item name={"isInteractivePDFChecked"} valuePropName="checked">
                <Checkbox disabled={archive}>Interaktywny pdf</Checkbox>
              </Form.Item>
            </Centered>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

BookDtpProjectType.propTypes = {
};

export default BookDtpProjectType;
