import React, {Fragment} from 'react';
import {Popconfirm, Table} from "antd";
import {useDispatch} from "react-redux";
import {GetAllPrintingHouseAction} from "../actions/internal_api/printingHouse/GetAllPrintingHouseAction";
import {DisablePrintingHouseAction} from "../actions/internal_api/printingHouse/DisablePrintingHouseAction";

const PrintingHouseTable = ({data, editRow}) => {

    const dispatch = useDispatch();

    const hidePrintingHouse = (data) =>{
        let newDataWithDisabled = {
            ...data,
            disabled: true
        }
        dispatch(DisablePrintingHouseAction(newDataWithDisabled.id)).then(()=>{
            dispatch(GetAllPrintingHouseAction())
        })
    }

    let columns = [
        {
            title: 'Akcje',
            render: (text, data, index)=> <Fragment>
                <a href="#none" onClick={()=>editRow(index)}>Edytuj</a>
                &nbsp;
                <Popconfirm title="Na pewno usunąć?" onConfirm={()=>hidePrintingHouse(data)} cancelText={"Nie"} okText={"Tak"}>
                    <a href="#none">Usuń</a>
                </Popconfirm>
            </Fragment>
        },
        {
            title: 'Nazwa drukarni',
            dataIndex: "name",
            key: 'printingHouseName',
        },
        {
            title: "Typ drukarni",
            dataIndex: "type",
            key: "printingHouseType",
            sorter: (a,b) => ('' + a.type).localeCompare(b.type),
        },
        {
            title: "NIP",
            dataIndex: "nip",
            key: "printingHouseNip",
        },
        {
            title: "Nazwa faktura",
            dataIndex: "invoiceName",
            key: "invoiceName",
        },
        {
            title: "Kod pocztowy",
            dataIndex: "postCode",
            key: "printingHousePostCode",
            sorter: (a,b) => ('' + a.postCode).localeCompare(b.postCode),
        },
        {
            title: "Miasto",
            dataIndex:'city',
            key: "printingHouseCity",
        },
        {
            title: "Adres",
            dataIndex: "address",
            key: "printingHouseAdress",
            sorter: (a,b) => ('' + a.address).localeCompare(b.address),
        },
        {
            title: "Telefon",
            dataIndex: "phone",
            key: "printinghousePhone",
            sorter: (a,b) => ('' + a.phone).localeCompare(b.phone),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "printingHouseEmail",
            sorter: (a,b) => ('' + a.email).localeCompare(b.email),
        },
    ];

    return (
        <Table
          size="small"
            columns={columns}
            dataSource={data}
            scroll={{
                x: "86vw"
            }}
            pagination={false}
        />
    );
};

PrintingHouseTable.propTypes = {

};

export default PrintingHouseTable;
