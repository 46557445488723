import React from 'react';
import PropTypes from 'prop-types';
import {Col, DatePicker, Input, Modal, Row, Radio, Select, InputNumber, Button, Form} from "antd";
import AddonBefore from "./Layout/AddonBefore";
import {ScrollableY} from "./styled/Layout";
import {useDispatch, useSelector} from "react-redux";
import {AddNewDaysOffAction} from "../actions/internal_api/daysOff/AddNewDayOffAction";
import {GetAllDaysOffAction} from "../actions/internal_api/daysOff/GetAllDaysOffAction";
import {UsersSelector} from "../store/internal_api/users/usersReducer";

const {Option} = Select;
const {TextArea} = Input;

const WorkingHoursNewRecordModal = ({visibility, onCancel}) => {

  let [mainForm] = Form.useForm();
  const dispatch = useDispatch();
  let users = useSelector(UsersSelector)


  const workersList = [

  ];

  users.forEach(element=>{
    workersList.push(<Option key={element.id} value={element.id}>{element.name}</Option>)
  })

  const tempOnOk = () =>{
    mainForm.validateFields().then((values)=>{
      dispatch(AddNewDaysOffAction(values)).then(()=>{
        dispatch(GetAllDaysOffAction()).then(()=>{
          onCancel();
        });
      });
    });
  }

  return (
    <Modal
      title={"Nowy urlop"}
      maskClosable={false}
      visible={visibility}
      footer={[
        <Button key="back" onClick={onCancel}>
          Anuluj
        </Button>,
        <Button key="submit" type="primary"  onClick={tempOnOk}>
          Utwórz
        </Button>,
      ]}
      onCancel={onCancel}
      width={"90%"}
      style={{top: 10}}
    >
      <ScrollableY maxHeight={"80vh"}>
        <Form form={mainForm}>
          <Row gutter={[24,24]}>
            <Col span={16}>
              <AddonBefore additionText={"Data rozpoczęcia"}>
                <Form.Item name={"dayStart"} rules={[{ required: true, message: 'Proszę wybrać dzień rozpoczęcia urlopu!' }]}>
                  <DatePicker/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col>
              <AddonBefore additionText={"Data zakończenia"}>
                <Form.Item name={"dayEnd"} rules={[{ required: true, message: 'Proszę wybrać dzień zakończenia urlopu!' }]}>
                  <DatePicker/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col>
              <AddonBefore additionText={"Rodzaj:"}/>
            </Col>
            <Col>
              <Form.Item name={"type"} rules={[{ required: true, message: 'Proszę wybrać rodzaj urlopu!' }]}>
                <Radio.Group >
                  <Radio  value={"Urlop"}>
                    Urlop
                  </Radio>
                  <Radio  value={"Choroba"}>
                    Choroba
                  </Radio>
                  <Radio  value={"Szkolenie"}>
                    Szkolenie
                  </Radio>
                  <Radio  value={"Delegacja"}>
                    Delegacja
                  </Radio>
                  <Radio  value={"Opieka nad dzieckiem"}>
                    Opieka nad dzieckiem
                  </Radio>
                  <Radio  value={"Urlop okolicznościowy"}>
                    Urlop okolicznościowy
                  </Radio>
                  <Radio  value={"Urlop bezpłatny"}>
                    Urlop bezpłatny
                  </Radio>
                  <Radio  value={"Inne"}>
                    Inne
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col>
              <AddonBefore additionText={"Ilość dni"}>
                <Form.Item name={"howMuchDays"} rules={[{ required: true, message: 'Proszę wpisań ilość dni urlopu!' }]}>
                  <InputNumber/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={15}>
              <AddonBefore additionText={"Komentarz"}>
                <Form.Item name={"description"} noStyle>
                  <TextArea rows={3}/>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={15}>
              <AddonBefore additionText={"Pracownik:"}>
                <Form.Item name={"worker"} rules={[{ required: true, message: 'Proszę wybrać pracownika!' }]}>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Wybierz pracownika"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {workersList}
                  </Select>
                </Form.Item>
              </AddonBefore>
            </Col>
          </Row>
        </Form>
      </ScrollableY>
    </Modal>
  );
};

WorkingHoursNewRecordModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WorkingHoursNewRecordModal;